export createCanvas = (width, height, debug = true)->
  canvas = document.createElement('canvas')
  if typeof debug is "string"
    canvas.id = debug
  canvas.width = width
  canvas.height = height
  if debug
    $('#debug_container').append(canvas)
  return canvas
_load_image = (src, img = null) ->
  if img is null
    img = new Image()
  await new Promise((resolve, reject) ->
                      img.onload = resolve
                      img.onerror = reject
                      img.src = src
                   )
  return img

export drawImageFromUrl = (canvas, url)->
  img = new Image()
  img.crossOrigin = "Anonymous"
  img = await _load_image(url, img)
  scale = Math.min(canvas.width / img.width, canvas.height / img.height)
  canvas.getContext('2d').drawImage(img, 0, 0, img.width * scale, img.height * scale)
