
export simplifyObject = (obj, depth, strlen = 200)=>
  if _.isNumber(obj) or obj is null or obj is undefined
    return obj
  if depth > 0
    if _.isArray(obj) or _.isArguments(obj)
      return (simplifyObject(k, depth - 1) for k,i in obj when i < 10)
    if _.isObject(obj)
      res = {}
      for own k,v of obj
        res[k] = simplifyObject(v, depth - 1)
      return res
  if _.isString(obj) and obj.length > strlen
    return obj.substr(0, Math.floor(strlen / 2)) + "…" + obj.substr(Math.floor(strlen / 2), 4)
  else if _.isFunction(obj)
    return "[func]"
  return (obj + "").substr(0, strlen)
