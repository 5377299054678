import ConfigGenerator from "exercises/config_generators/config_generator"
import PredefinedPositionGenerator from "exercises/config_generators/predefined"
import i18n_init from "i18n_init"
import rand from "utils/rand"

COLORS = {
  white: { default: "ffffff" },
  blue: { default: "0079D0", violet: "629BFF", black: "629BFF", green: "0976C3" }
  violet: { default: "6C009B", black: "9C34D4", }
  black: { default: "000000" }
  green: { default: "409B00", blue: "71EB1A", violet: "4BB500" },
}
getHtmlColor = (first, second) ->
  return "#" + (COLORS[first][second] ? COLORS[first].default)

export default class MarblesConfigGenerator extends ConfigGenerator
  ANIMATION_DURATION: 3000
  ELEMENT_SIZE: 0.18
  constructor: (level_conf, areas)->
    super(arguments...)
    generator_areas = PredefinedPositionGenerator.getAreas(areas, level_conf.marbles)
    @_position_generator = new PredefinedPositionGenerator(generator_areas, @ELEMENT_SIZE)
    @_texts =
      white: _t("colors.white")
      violet: _t("colors.violet")
      black: _t("colors.black")
      yellow: _t("colors.yellow")
      red: _t("colors.red")
      green: _t("colors.green")
      blue: _t("colors.blue")
      pink: _t("colors.pink")
      meaning: _t("marbles.meaning")
      background: _t("marbles.background_color")
      text: _t("marbles.text_color")

  generateScenario: (round_duration)->
    round_duration = round_duration * 1000
    min_element_duration = @ANIMATION_DURATION / 2 + Math.min(@MIN_REACTION_TIME, @_conf.timeout)
    type_probabilities =
      background: @_conf.color_instead_of_meaning_probability * @_conf.swap_background_and_text_colors_probability
      text: @_conf.color_instead_of_meaning_probability * (1 - @_conf.swap_background_and_text_colors_probability)
      meaning: (1 - @_conf.color_instead_of_meaning_probability)
    total_duration = 0
    while total_duration <= round_duration
      type = rand.weightedObj(type_probabilities)
      total_duration += min_element_duration
      @scenario.push @_prepareTrial(type)
      @_id = @scenario.length
    return @scenario

  _prepareTrial: (type)->
    positions = @_position_generator.getPositions(@_conf.marbles)
    target_color = rand.element(@_conf.colors)
    nontarget_colors = rand.elements(_.without(@_conf.colors, target_color),
      Math.min(@_conf.marbles, positions.length) - 1)
    question =
      type: type
      instruction: @_texts[type].toUpperCase()
    if type == 'meaning'
      question.text = @_texts[target_color]
      question.font_color = if @_conf.consistent then target_color else nontarget_colors[0]
    else if type == 'background'
      question.background_color = target_color
      question.font_color = @_getBackgroundColor(target_color)
    else if type == 'text'
      question.font_color = target_color
    question.text ?= @_texts[nontarget_colors[0]]
    question.background_color ?= @_getBackgroundColor(question.font_color)
    if @_conf.capitalize and rand.bool()
      question.text = question.text.toLocaleUpperCase()

    answers = for color,i in [target_color, nontarget_colors...]
      if not positions[i]?
        throw Error("Empty position!")
      else
        id: "Answer_#{@_id}_#{i}"
        color: color
        size: @ELEMENT_SIZE
        type: if color == target_color then "correct" else "incorrect"
        position: positions[i].mul(x: 1, y: -1).toObject()
    { font_color, background_color } = question
    question.background_color = getHtmlColor(background_color, font_color)
    question.font_color = getHtmlColor(font_color, background_color)
    for a in answers
      a.color =
        if a.color == background_color
          question.background_color
        else if a.color == font_color
          question.font_color
        else
          '#' + COLORS[a.color].default

    return {
      id: "Question_" + @_id
      question: question,
      answers: answers,
      timeout_after: @_conf.timeout,
      activated_after: if @_conf.timeout > 1000 then 200 else 0,
    }
  _getBackgroundColor: (text_color) ->
    if @_conf.white_background
      return 'white'
    else
      bg_colors = _.filter ["white", @_conf.colors...], (c)->c != text_color
      return rand.element(bg_colors)
