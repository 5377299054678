export default class NamespaceLoader {
  constructor(services, options) {
    this.type = "backend"
  }

  init(services, options) {
    this.services = services
    this.options = options
  }

  async read(language, namespace, callback) {
    const module = await import(
      /* webpackChunkName: "lang_pack"*/
      `../locale/${language}/resources`
    )
    if (module[namespace]) callback(null, module[namespace])
    else if (module.namespaces.keys().includes(`./${namespace}.json`))
      callback(null, module.namespaces(`./${namespace}.json`))

    callback(new Error("Unknown Namespace!"))
  }
}
NamespaceLoader.type = "backend"
