#@coffeescript2

export default class PerfMonitor
  constructor: (print_freq, max_len)->
    @_print_freq = print_freq ? 5000
    @_max_len = max_len ? 1000
    @clear()

  now: @now = -> performance.now()

  time: (label, now)->
    return @_starts[label] = now ? PerfMonitor.now()

  timeEnd: (label, start_label = null)->
    start = @_starts[start_label ? label]
    @_starts[label] = now = PerfMonitor.now()
    if not start?
      return
    duration = now - start
    @_addDuration(label, duration)
    return duration

  _addDuration: (label, duration) ->
    target = (@_durations[label] ?= [])
    if _.isArray(duration)
      target.push duration...
    else
      target.push duration
    while target.length > @_max_len
      target.shift()

  measure: (label, awaitable) ->
    @time(label)
    if _.isFunction(awaitable)
      awaitable = awaitable()
    if _.isFunction(awaitable.finally)
      awaitable.finally => @timeEnd(label)
    else
      @timeEnd(label)
    return awaitable

  addDurations: (durations) ->
    for k,v of durations
      @_addDuration(k, v)

  printStats: =>
    period = Date.now() - @_last_print
    @_last_print += period
    lasts = ""
    for key,values of @_durations
      if values.length == 0
        continue
      avg = (values.reduce((a, b)->a + b) / values.length)
      last = values[values.length - 1]
      fps = (values.length / period) * 1000
      lasts += "#{key}:#{avg.toFixed(2)} (last: #{last.toFixed(2)} fps:#{fps.toFixed(1)}) "
      @_durations[key] = []
    if lasts
      console.log "stats", lasts
  durations: ->
    durs = @_durations
    @_durations = {}
    return durs

  clear: ->
    @_starts = {}
    @_durations = {}
    @_last_print = 0
    clearInterval(@_int)
    if @_print_freq > 0
      @_int = setInterval(@printStats, @_print_freq)

  _calculateStats: (array)->
    array = array.sort((a, b) -> return a - b)
    sum = array.reduce(((previous, current) -> current += previous), 0)
    avg = sum / array.length
    return results = {
      timings: array
      avg: avg
      n: array.length
      1: @_percentile(array, 0.01)
      99: @_percentile(array, 0.99)
      std: (@_percentile(array, 0.50 + 0.341) - @_percentile(array, 0.50))
    }

  _percentile: (array, p) ->
    if p <= 0
      return array[0]
    if p >= 1
      return array[array.length - 1]
    index = array.length * p
    lower = Math.floor(index)
    upper = lower + 1
    weight = index % 1
    if upper >= array.length
      return array[lower]
    return array[lower] * (1 - weight) + array[upper] * weight

  getStats: ()->
    stats = {}
    for k,array of @_durations
      stats[k] = @_calculateStats(array)
    return stats

  _animationFrame: =>
    @timeEnd("fps")
    @time("fps")
    @_raf = requestAnimationFrame(@_animationFrame)

  measureFPS: ->
    @_raf = requestAnimationFrame(@_animationFrame)
  stopMeasureFPS: ->
    cancelAnimationFrame(@_raf)

  @time = ()->@instance.time(arguments...)

  @timeEnd = ()->@instance.timeEnd(arguments...)

  @durations = ()->@instance.durations()

  @measure = () ->@instance.measure(arguments...)

PerfMonitor.instance = new PerfMonitor(10000, 1000)
