import ExerciseDescriptor, { areaName, AREAS, areasFromName } from "../exercise_descriptor"
import BUTTERFLY_LEVELS from "exercises/descriptors/butterfly/butterfly_levels"
import ConfigGenerator from "../../config_generators/config_generator"
import rand from "utils/rand"
import {
  PathGenerator,
  RandomPathGenerator,
} from "exercises/descriptors/butterfly/random_path_generator"
import ButterflyMover from "./butterfly_mover"
import SVGReader from "helpers/svg_helper"
import butterfly_svg from "./butterfly.svg?inline"
import Path from "utils/path"
import Point from "utils/point"

const AREA_NAMES = {
  whole_screen: areaName([AREAS.bl, AREAS.br, AREAS.tl, AREAS.tr]),
  upper_left: AREAS.tl,
  upper_right: AREAS.tr,
  lower_left: AREAS.bl,
  lower_right: AREAS.br,
  left_side: areaName([AREAS.tl, AREAS.bl]),
  right_side: areaName([AREAS.tr, AREAS.br]),
  top: areaName([AREAS.tl, AREAS.tr]),
  top_left: areaName([AREAS.tl, AREAS.tr, AREAS.bl]),
  top_right: areaName([AREAS.tl, AREAS.tr, AREAS.br]),
}
const TO_AREA_NAME = _.invert(AREA_NAMES)

export class ButterflyPathGenerator extends PathGenerator {
  reset(round_duration) {
    this.butterflyMover = new ButterflyMover(this.config)
    this.butterflyMover.start(round_duration * 1000)
    this.mover = this.butterflyMover.mover
    this._wasWaiting = false
  }

  getNextPos(frame, path) {
    this.butterflyMover.advance(frame)
    const p = this._stretch(this.butterflyMover.position)
    if (this.butterflyMover.is_waiting && !this._wasWaiting && path?.length) {
      path.push({
        ts: path[path.length - 1].ts,
        ...p,
        active: this.butterflyMover.is_inside,
      })
    }
    this._wasWaiting = this.butterflyMover.is_waiting
    return {
      ...p,
      active: this.butterflyMover.is_inside,
    }
  }
}

export class MapleCopterGenerator extends PathGenerator {
  static BOUNDS = {
    left: [0.1, 0.5],
    right: [0.5, 0.9],
    both: [0.1, 0.9],
  }

  reset() {
    this.startTime = null
    this.isPaperPlane = true
  }

  _newPath(startTime) {
    const bounds = MapleCopterGenerator.BOUNDS[this.isPaperPlane ? "both" : this.config.side]
    const path = ButterflyMover.gen_whirlybird_paths(1, bounds[0], bounds[1])[0]

    this.duration = path.duration
    this.path = new Path(path.path)
    if (this.isPaperPlane)
      this.path.ppath = this.path.ppath.rotate(rand.bool(0.5) ? 90 : 270, { x: 0.5, y: 0.5 })
    this.startTime = startTime
  }

  getNextPos(frame, path) {
    if (this.startTime == null || this.startTime + this.duration < frame.time) {
      this._newPath(frame.time)
      if (path && path.length) {
        path.push({
          ts: path[path.length - 1].ts,
          ...this._stretch(this.path.pointAtPercent(0)),
        })
      }
    }
    return this._stretch(this.path.pointAtPercent((frame.time - this.startTime) / this.duration))
  }
}

export class DistractorPathGenerator extends RandomPathGenerator {
  constructor(config) {
    const position = config.position
    const scaled = { x: position.x * 800, y: position.y * 600 }
    super(_.merge({ follower: { position: scaled }, runner: { position: scaled } }))
    this.orgPos = new Point(position)
    const v = this.orgPos.sub({ x: 0.5, y: 0.5 })
    this._vec = v.normalized().mul(0.7 - v.length())
  }

  getNextPos(frame) {
    const DURATION = 1000
    if (frame.time < DURATION)
      return this._stretch(this.orgPos.add(this._vec.mul(1 - frame.time / DURATION)))
    else return super.getNextPos(frame)
  }
}

const insectConfig = (maxSpeed, followerSpeed, minSpeed) => ({
  useRunner: false,
  applyRotation: true,
  runner: {
    ...(minSpeed != null ? { minSpeed } : {}),
    maxSpeed: maxSpeed,
    remainsOnScreen: false,
    perlin: true,
    avoidEdges: false,
    random: true,
    randomRadius: 5000,
  },
  follower: {
    maxSpeed: followerSpeed,
  },
})
const START_DELAY = 5.0

export class ButterflyConfigGenerator extends ConfigGenerator {
  MIN_DISTANCE = 0.2
  SINGLE_STAIN_PROBABILITY = 0.75
  FINISH_DELAY = 0.5
  static CONFIG_DEFAULTS = {
    flight_logic: {
      speed: 20,
      maneuverability: 20,
      flyOutsMin: 0,
      flyOutsMax: 0,
      flyOutRandPos: false,
      flyOutWaitMin: 1000,
      flyOutWaitMax: 3000,
      flyOutInitialGrace: 8 * 1000,
      margin_x: 100,
      margin_y: 100,
    },
  }
  static DISTRACTORS = {
    Ladybird: insectConfig(2, 5),
    PaperPlane: {
      size: 0.1,
      paths: [],
      selectRandomPath: true,
    },
    MapleCopter2: {
      size: 0.1,
      paths: [],
      selectRandomPath: true,
    },
    Dandelion: insectConfig(2, 5),
    Fly: insectConfig(5, 8, 3),
    Feather: insectConfig(2, 5),
    Leaf: insectConfig(2, 5),
  }

  static levelOverrides(conf) {
    return _.merge(ButterflyConfigGenerator.CONFIG_DEFAULTS, {
      flight_logic: {
        speed: conf.speed,
        maneuverability: conf.maneuverability,
        flyOutsMin: conf.fly_out.min,
        flyOutsMax: conf.fly_out.max,
        flyOutRandPos: conf.fly_out.random_position,
        flyOutWaitMin: conf.fly_out.wait_min * 1000,
        flyOutWaitMax: conf.fly_out.wait_max * 1000,
      },
    })
  }

  constructor(level_conf, areas) {
    super(level_conf, areas)
  }

  generateScenario(roundDuration) {
    const aName = TO_AREA_NAME[areaName(Object.keys(this._areas))]
    const reader = new SVGReader(butterfly_svg)
    const areaConfig = {
      area: {
        data: reader.getNode("#" + aName).getAttribute("d"),
        width: 800,
        height: 600,
      },
      initial_point: reader.getPositions(`#${aName}_initial_point`)[0],
    }
    const butterflyPathGenerator = new ButterflyPathGenerator({
      ...ButterflyConfigGenerator.levelOverrides(this._conf),
      ...areaConfig,
    })
    butterflyPathGenerator.reset(roundDuration)
    const result = {
      startDelay: START_DELAY,
      butterfly: {
        typeName: "BUTTERFLY",
        size: 0.2,
        points: butterflyPathGenerator.generatePath(roundDuration, 0.2),
      },
      distractors: this._conf.distractors.map((typeName) =>
        this.getDistractorConfig(typeName, roundDuration, areaConfig)
      ),
    }
    return [result]
  }

  getDistractorConfig(typeName, roundDuration, areaConfig) {
    const conf = ButterflyConfigGenerator.DISTRACTORS[typeName]
    let generator
    if (conf.selectRandomPath) {
      const side = this.isOneSideSelected()
      generator = new MapleCopterGenerator({ side: side ? side : "both" })
    } else {
      const position = rand.pointInsidePath(new Path(areaConfig.area))
      generator = new DistractorPathGenerator(
        _.merge(
          {
            maxDelta: 500,
            position,
          },
          conf
        )
      )
    }
    return {
      typeName: typeName.toUpperCase(),
      size: conf.size,
      points: generator.generatePath(roundDuration, 0.2),
    }
  }

  generateRoundConfig(round_duration) {
    return {
      ...super.generateRoundConfig(round_duration),
      holdingPointsPerSecond: this._conf.holdingPointsPerSecond,
      notHoldingPointsPerSecond: this._conf.notHoldingPointsPerSecond,
      notHoldingDelay: this._conf.focus_delay / 1000,
    }
  }
}

const MIN_REACTION_TIME = 0.5
export default class ButterflyDesc extends ExerciseDescriptor {
  constructor(...args) {
    super(...args)
    this._config_cache = []
  }

  generateRoundConfig(run_config) {
    const cacheName = this._cacheName(run_config)
    if (this._config_cache[cacheName] === null) {
      const config = super.generateRoundConfig(run_config)
      const fixPoints = (obj) => ({
        ...obj,
        points: obj.points.map((p) => ({
          ...p,
          y: -p.y,
        })),
      })
      config.scenario[0] = {
        id: "Trial_1",
        butterfly: fixPoints(config.scenario[0].butterfly),
        distractors: config.scenario[0].distractors.map(fixPoints),
      }
      this._config_cache[cacheName] = config
      config.activeSum = config.scenario[0].butterfly.points.reduce(
        (last, p) => {
          if (p.active) last.sum += p.ts - last.ts
          last.ts = p.ts
          return last
        },
        { ts: 0, sum: 0 }
      ).sum
    } else this.updateTexts(this._config_cache[cacheName], run_config)
    return this._config_cache[cacheName]
  }

  _cacheName(run_config) {
    return `${run_config.level}_${run_config.duration}_${areaName(run_config.areas)}`
  }

  getExpectedPoints(run_config) {
    const activeSum = this.generateRoundConfig(run_config).activeSum
    const conf = this._getLevelConfig(run_config)
    const { level } = run_config
    const startupDuration =
      MIN_REACTION_TIME +
      (START_DELAY - MIN_REACTION_TIME) * (1 - level / this.LEVELS_CONFIG.length)
    const totalTime = activeSum - startupDuration
    const desiredHoldingTime = Math.min(conf.desiredHoldingRatio, 0.9) * totalTime
    return (
      desiredHoldingTime * conf.holdingPointsPerSecond +
      (totalTime - desiredHoldingTime) * conf.notHoldingPointsPerSecond
    )
  }

  getGoal(run_config) {
    this._config_cache[this._cacheName(run_config)] = null
    return super.getGoal(run_config)
  }
}

Object.assign(ButterflyDesc.prototype, {
  scene_name: "Butterfly",
  exercise_id: "butterfly",
  LEVELS_CONFIG: BUTTERFLY_LEVELS,
  GeneratorClass: ButterflyConfigGenerator,
  available_areas: Object.values(AREA_NAMES).map(areasFromName),
  instructions: [
    { video: { slides: "butterfly" } },
    { hint: _tnoop("hints:butterfly.instruction") },
  ],
})
