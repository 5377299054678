import end_round_html from "./end_round_screen.html"
import logo_png from "runner/images/logo.png"

TEMPLATE = _.template end_round_html

export default class EndRoundScreen
  constructor: (parent)->
    @_parent = parent
  show: (options) ->
    options = _.extend {}, options, round: options.round + 1
    data = ""
    for k,v of _.pick(options, 'level', "score", "expected_points","normalized_score", "level_change", "next_level", "next_goal")
      data += "<strong>#{k}</strong>: #{v}<br/>"
    texts =
      title: _t("end_round.title", options)
      rounds: _t("end_round.rounds", options)
      name: options.name ? "Exercise Name"
      image: options.image ? logo_png
      data: data
    @_content = $(TEMPLATE(texts))
    @_parent.append(@_content)
    showing = new Promise (resolve, reject) =>
      @_reject = reject
      @_content.one 'hidden.bs.modal', =>
        resolve()
        setTimeout((=>@_content.remove()), 500)
      @_content.modal(backdrop: true, keyboard: true)
      hide = =>
        if $('.hide_cursor').length
          @_content.modal('hide')
      setTimeout(hide, options.timeout ? 3000)

  hide: ->
    @_reject()
    @_content.modal('hide')

