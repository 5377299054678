define ['simplex-noise', './vector', './path', './math', './rand', 'paper'], (SimplexNoise, Vector, Path, math, rand, paper) ->


  mapValue = math.mapValue
  getRandomNumber = rand.int

  getFloat = (val, default_val = 0.0) ->
    val = parseFloat(val)
    return if isNaN(val) then parseFloat(default_val) else val

  getBool = (val, default_val = false) -> if val? then not not val else not not default_val

  getVector = (val, default_val) -> if val? then new Vector(val) else new Vector(default_val)

  class PhysicalObject
    RES_X:800
    RES_Y:600
    constructor: (@options) ->
      if not @options? then @options = {}

      @worldBoundaries = new Path(@options.worldBoundaries ? "M 0,0 0,#{@RES_Y} #{@RES_X},#{@RES_Y} #{@RES_X},0 z")
      @worldBoundaries.closePath()

      @width      = getFloat(@options.width, 20)
      @height     = getFloat(@options.height, 20)
      @mass       = getFloat(@options.mass, 10)
      @bounciness = getFloat(@options.bounciness, 0.75)
      @maxSpeed   = getFloat(@options.maxSpeed, 10)
      @minSpeed   = getFloat(@options.minSpeed, 0)

      @angle            = getFloat(@options.angle, 0)
      @pointToDirection = getBool(@options.pointToDirection, true)

      @position     = getVector(@options.position, { x: 0.5*@RES_X, y: 0.5*@RES_Y })
      @velocity     = getVector(@options.velocity)
      @acceleration = getVector(@options.acceleration)

      @checkEdges         = getBool(@options.checkEdges, true)
      @avoidEdges         = getBool(@options.avoidEdges, false)
      @avoidEdgesStrength = getFloat(@options.avoidEdgesStrength, 50)
      @maxSteeringForce   = getFloat(@options.maxSteeringForce, 10)
      @maxRotationSpeed = getFloat(@options.maxRotationSpeed, Math.PI)

      @seekTarget = @options.seekTarget or null
    applyForces: -> if @seekTarget? then @applyForce(@_seek(@seekTarget))

    applyForce: (force) -> @acceleration.add(force.div(@mass))

    step: (delta = 1.0) ->
      @applyForces(delta)
      @velocity.add(Vector.mul(@acceleration, delta))
      if not @velocity.isZero()
        @velocity.clampLength(@maxSpeed, @minSpeed)
      if @seekTarget
        @_rotateTowardsTarget(@seekTarget.position,delta / 40 * @maxRotationSpeed)
      @position.add(Vector.mul(@velocity, delta)) # multiply by delta?
      if @pointToDirection # object rotates toward direction
        if @velocity.length() > 0.1
          #@angle = Math.atan2(@velocity.y, @velocity.x)
          @angle = @velocity.angle()
      if @checkEdges
        @_checkWorldEdges()
      @acceleration.zero()

    _rotateTowardsTarget: (target, maxDiff)->
      angle = @velocity.angle()
      desired = Vector.sub(target, @position).angle()
      angleDiff = desired - angle
      if Math.abs(angleDiff) > Math.PI
        angleDiff = Math.sign(angleDiff) * (-2) * Math.PI + angleDiff
      rotationStep = _.clamp(angleDiff, -maxDiff, maxDiff)
      @velocity.rotate(rotationStep)

    _seek: (target) ->
      desiredVelocity = Vector.sub(target.position, @position)
      distanceToTarget = desiredVelocity.length()
      if distanceToTarget is 0
        return new Vector(0, 0)
      else
        desiredVelocity.normalize()
        if distanceToTarget < 0.5*@RES_X
          desiredVelocity.mul(mapValue(distanceToTarget, 0, 0.5*@RES_X, 0, @maxSpeed))
        else
          desiredVelocity.mul(@maxSpeed)
        desiredVelocity.sub(@velocity)
        desiredVelocity.clampLength(@maxSteeringForce)
        return desiredVelocity

    # determines if this object is outside the world bounds
    # returns true if the object is outside the world
    _checkWorldEdges: ->
      x = @position.x
      y = @position.y
      velocity = @velocity
      check = false
      # transform origin is at the center of the object
      pos = @position.copy()
      if not @worldBoundaries.isPointInside(pos)
        check = true
        loc = @worldBoundaries.ppath.getNearestLocation(pos) # nearest point on world boundaries
        pos_bound = new Vector(loc.point)
        N = new Vector(loc.normal)
        N.normalize()
        # small caps - scalars, big caps - vectors
        # V - velocity
        # N - surface normal
        # U - perpendicular component of velocity
        # W - parallel component of velocity
        # V = U + W
        # U = (V*N)N, where * is dot product
        # W = V - U
        # V' - new velocity
        # f - friction coefficient (=1)
        # r - resustitution coeficient (=@bounciness)
        # V' = fW - rU = W - rU
        U = Vector.mul(N, Vector.dot(@velocity, N))
        V_new = Vector.sub(@velocity, Vector.mul(U, 2))#1 + @bounciness))
        # console.log 'not inside', @worldBoundaries.len, @velocity.toString(), V_new.toString(), pos_bound.toString()
        @velocity = V_new
        @position = pos_bound
      return check

    # Checks if object is within range of a world edge. If so, steers the object
    # in the opposite direction.
    _checkAvoidEdges: ->
      loc = @worldBoundaries.ppath.getNearestLocation(@position) # nearest point on world boundaries
      if loc.distance < @avoidEdgesStrength
        # console.log 'too close', @worldBoundaries.len, @velocity.toString(), V_new.toString(), pos_bound.toString()
        repellingForce = Vector.sub(@position, loc.point).clampLength(@maxSteeringForce)
        if not @worldBoundaries.isPointInside(@position)
          repellingForce.negate()
        @applyForce(repellingForce)
    getPosition:->
      return @position.copy().scale(1/@RES_X,1/@RES_Y)

  class RandomWalker extends PhysicalObject
    SimplexNoise: new SimplexNoise()
    constructor: (options) ->
      super(options)
      @perlin = getBool(@options.perlin, true)
      @remainsOnScreen = getBool(@options.remainsOnScreen, false)

      @perlinSpeed     = getFloat(@options.perlinSpeed, 1)
      if Math.abs(@perlinSpeed * (max_perlin = 5/60)) > 1
        console.warning("PerlinSpeed too high! Math.abs(#{max_perlin})")
      @perlinTime      = getFloat(@options.perlinTime, 0)
      @perlinAccelLow  = getFloat(@options.perlinAccelLow, -0.075)
      @perlinAccelHigh = getFloat(@options.perlinAccelHigh, 0.075)

      @offsetX = getFloat(@options.offsetX, Math.random()*10000)
      @offsetY = getFloat(@options.offsetY, Math.random()*10000)

      @random = getBool(@options.random, false)
      @randomRadius = getFloat(@options.randomRadius, 100)

    applyForces: (delta = 1/60) ->
      # walker can use either perlin noise or random walk
      SimplexNoise = @SimplexNoise
      if @perlin
        @perlinTime += @perlinSpeed * delta
        if @remainsOnScreen
          @acceleration.zero()
          @velocity.zero()
          @position.x = mapValue(SimplexNoise.noise2D(@perlinTime + @offsetX, 0), -1, 1, 0, 800)
          @position.y = mapValue(SimplexNoise.noise2D(0, @perlinTime + @offsetY), -1, 1, 0, 600)
        else
          @acceleration.x = mapValue(SimplexNoise.noise2D(@perlinTime + @offsetX, 0), -1, 1, @perlinAccelLow, @perlinAccelHigh)
          @acceleration.y = mapValue(SimplexNoise.noise2D(0, @perlinTime + @offsetY), -1, 1, @perlinAccelLow, @perlinAccelHigh)
      else if @random
        rand_vec = new Vector(getRandomNumber(-@randomRadius, @randomRadius),
                              getRandomNumber(-@randomRadius, @randomRadius))
        @seekTarget = position: Vector.add(@position, rand_vec) # find a random point and steer toward it
        @applyForce(@_seek(@seekTarget))
      if @avoidEdges
        @_checkAvoidEdges()

  class RandomMover
    @RandomWalker = RandomWalker
    constructor: (@config) ->
      # NOTE: world boundaries must be in 800x600 coordinates
      @runner = new RandomWalker(@config.runner)
      @follower = new PhysicalObject(@config.follower)
      if @config.worldBoundaries?
        @worldBoundaries = new Path(@config.worldBoundaries)
        @worldBoundaries.closePath()
        @runner.worldBoundaries = @worldBoundaries
        @follower.worldBoundaries = @worldBoundaries
      @follower.seekTarget = @runner
      @useRunner = getBool(@config.useRunner, false)

    step: (delta) ->
      delta *= 40
      @runner.step(delta)
      @follower.step(delta)

    getMovementDirection: ->
      vel = @_obj().velocity
      return if vel.x == 0 then '' else if vel.x > 0 then 'r' else 'l'

    getRotation: -> @_obj().angle

    getPosition: -> @_obj().getPosition()

    setPosition:(pos)->
      @follower.position=new Vector(pos)
      @runner.position=new Vector(pos)

    getDistanceFromWorldBounds: ->
      obj = @_obj()
      pos = obj.position
      loc = obj.worldBoundaries.ppath.getNearestLocation(pos)
      return loc.distance

    moveTo: (pos) -> @follower.seekTarget = position: (new Vector(pos)).scale(800, 600)

    follow: -> @follower.seekTarget = @runner

    _obj: -> if @useRunner then @runner else @follower

  return RandomMover
