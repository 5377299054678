import ExerciseDescriptor, { areaName, AREAS } from "../exercise_descriptor"
import FOOTBALL_LEVELS from "./footbal_levels"
import ConfigGenerator from "../../config_generators/config_generator"
import PositionGenerator from "../../config_generators/position_generator"
import { PredefinedArea } from "../../config_generators/area"
import football_positions from "./football.svg?inline"
import SvgReader from "helpers/svg_helper"
import { mapValues, omit, pick } from "lodash"
import rand from "utils/rand"

const MAP = {
  [AREAS.tl]: "top_left",
  [AREAS.tr]: "top_right",
  [AREAS.bl]: "bottom_left",
  [AREAS.br]: "bottom_right",
}

export class FootballPositionGenerator extends PositionGenerator {
  static AREAS_CONFIG = {}
  static getAreaConfig = (range) => {
    if (FootballPositionGenerator.AREAS_CONFIG[range] == null) {
      const reader = new SvgReader(football_positions)
      const config = {}
      Object.entries(MAP).forEach(([area, svg_name]) => {
        const node = reader.getNode("#" + svg_name + "_" + range)
        config[area] = {
          start_point: { x: 0, y: 0 },
          positions: reader.getAllPositionsInsidePolygon(node, 0.01),
        }
      })
      FootballPositionGenerator.AREAS_CONFIG[range] = config
    }
    return FootballPositionGenerator.AREAS_CONFIG[range]
  }

  constructor(areas, range, minDistance = 0.1) {
    const areaConfig = FootballPositionGenerator.getAreaConfig(range)
    areas = mapValues(areas, (_, area) => areaConfig[area])
    super(areas, minDistance)
  }
}

Object.assign(FootballPositionGenerator.prototype, { AREA_CLASS: PredefinedArea })
const NON_TARGET_DURATION = 2

export class FootballConfigGenerator extends ConfigGenerator {
  MIN_DISTANCE = 0.1
  NON_TARGET_DURATION = NON_TARGET_DURATION

  constructor(level_conf, areas) {
    super(level_conf, areas)
    this._pos_generator = new FootballPositionGenerator(
      this._areas,
      level_conf.range,
      this.MIN_DISTANCE
    )
  }

  static minTrialDuration(conf) {
    return conf.next_target > 0 ? conf.next_target : (conf.timeout > 0 ? conf.timeout : 15) * 0.9
  }

  generateScenario(round_duration) {
    const minDuration = Math.min(FootballConfigGenerator.minTrialDuration(this._conf), 1)
    const trials = round_duration / minDuration
    while (this.scenario.length < trials) {
      const trial = this.generateTrial()
      if (trial) this.scenario.push(trial)
      else this.scenario.pop()
    }
    return this.scenario
  }

  generateRoundConfig(round_duration) {
    return {
      ...super.generateRoundConfig(round_duration),
      ...pick(this._conf, "target_score", "non_target_score", "timeout_score"),
    }
  }

  generateTrial() {
    const { timeout, next_target, non_targets } = this._conf
    const last = this.scenario[this.scenario.length - 1]
    const isTarget =
      last == null || last.isTarget === false || non_targets === 0
        ? true
        : !rand.bool(this._conf.non_targets)
    const minDuration = next_target < 0 ? (last.timeout > 0 ? last.timeout : 15) : next_target
    let minTime = last ? last.minTime + minDuration : 0
    let position = null
    const activeElements = this.scenario.filter((s) => s.minTime + s.timeout > minTime)
    while (position == null) {
      position = this._pos_generator.getPosition(isTarget, activeElements)
      if (position == null) {
        if (activeElements.length === 0)
          throw Error("Could not find position and active elements are empty!")
        const first = activeElements[0]
        minTime = Math.max(first.minTime + first.timeout, minTime)
        console.warn(
          "Could not place object at",
          minTime,
          "because of ",
          [...activeElements],
          "launch_delay:",
          minTime - last.minTime
        )
        activeElements.unshift()
      }
    }
    return {
      id: "Ball_" + this.scenario.length,
      timeout: isTarget ? timeout : Math.min(minDuration, this.NON_TARGET_DURATION),
      activation_duration: 0.2,
      position: position,
      size: 0.1,
      isTarget,
      launch_delay: last ? (next_target > 0 ? minTime - last.minTime : -1) : 0,
      minTime: minTime,
    }
  }
}

export default class FootballDesc extends ExerciseDescriptor {
  generateRoundConfig(run_config) {
    const config = super.generateRoundConfig(run_config)
    config.scenario = config.scenario.map((s) => ({
      ...omit(s, "isTarget", "minTime", "position"),
      is_target: s.isTarget,
      position: { x: s.position.x, y: -s.position.y },
    }))
    return config
  }

  getExpectedPoints(run_config) {
    const conf = this._getLevelConfig(run_config)
    const { round_duration } = run_config
    const trialDuration = FootballConfigGenerator.minTrialDuration(conf)
    const nonTargetDuration = Math.min(trialDuration / 0.9, NON_TARGET_DURATION)
    const avgTrialDuration =
      (trialDuration + conf.non_targets * nonTargetDuration) / (1 + conf.non_targets)
    const expectedTrials = (round_duration - 1) / avgTrialDuration
    let expectedNonTargets = expectedTrials * conf.non_targets
    expectedNonTargets =
      (expectedNonTargets / (expectedNonTargets + expectedTrials)) * expectedTrials
    const expectedTargets = expectedTrials - expectedNonTargets

    return expectedTargets * conf.target_score
  }
}

Object.assign(FootballDesc.prototype, {
  scene_name: "Football",
  exercise_id: "football",
  LEVELS_CONFIG: FOOTBALL_LEVELS,
  GeneratorClass: FootballConfigGenerator,
  instructions: [{ video: { slides: "football" } }, { hint: _tnoop("hints:football.instruction") }],
})
