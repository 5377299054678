import { getLevelConfigArray } from "exercises/config_generators/utils"

const LEVELS = {
  level_1: {
    desiredHoldingRatio: 20 / 60,
    distractor_scale: 1.7,
    speed: 20,
    maneuverability: 20,
    focus_delay: 800,
    fly_out: { min: 0, max: 0, random_position: false, wait_min: 1, wait_max: 3 },
    distractors: [],
  },
  level_2: { desiredHoldingRatio: 30 / 60, maneuverability: 30 },
  level_3: { speed: 30, maneuverability: 35 },
  level_4: { desiredHoldingRatio: 35 / 60, speed: 35, maneuverability: 40 },
  level_5: { desiredHoldingRatio: 40 / 60, speed: 40 },
  level_6: { fly_out: { min: 1, max: 1, random_position: false, wait_min: 1, wait_max: 3 } },
  level_7: {
    desiredHoldingRatio: 45 / 60,
    speed: 45,
    maneuverability: 50,
    distractors: ["PaperPlane"],
  },
  level_8: {
    speed: 50,
    maneuverability: 60,
    fly_out: { min: 1, max: 2, random_position: false, wait_min: 1, wait_max: 3 },
  },
  level_9: {
    desiredHoldingRatio: 50 / 60,
    speed: 60,
    maneuverability: 70,
    distractors: ["Leaf", "PaperPlane"],
  },
  level_10: { speed: 65 },
  level_11: {
    speed: 70,
    fly_out: { min: 1, max: 2, random_position: true, wait_min: 1, wait_max: 3 },
  },
  level_12: { maneuverability: 72 },
  level_13: { desiredHoldingRatio: 55 / 60, maneuverability: 75 },
  level_14: {
    speed: 75,
    maneuverability: 80,
    fly_out: { min: 1, max: 3, random_position: true, wait_min: 2, wait_max: 4 },
    distractors: ["Dandelion", "Leaf", "PaperPlane"],
    holdingPointsPerSecond: 1.1,
  },
  level_15: { speed: 80, maneuverability: 90, holdingPointsPerSecond: 1.2 },
  level_16: { speed: 85, maneuverability: 95, holdingPointsPerSecond: 1.3 },
  level_17: {
    speed: 90,
    maneuverability: 100,
    fly_out: { min: 1, max: 3, random_position: true, wait_min: 1.5, wait_max: 4 },
    distractors: ["Feather", "Dandelion", "Leaf", "PaperPlane"],
    holdingPointsPerSecond: 1.4,
  },
  level_18: { speed: 95, holdingPointsPerSecond: 1.5 },
  level_19: { speed: 100, holdingPointsPerSecond: 1.6 },
  level_20: { speed: 125, holdingPointsPerSecond: 1.7 },
  level_21: { speed: 150, holdingPointsPerSecond: 1.8 },
  level_22: { speed: 175, focus_delay: 400, holdingPointsPerSecond: 1.9 },
  level_23: { speed: 200, holdingPointsPerSecond: 2.0 },
  level_24: { speed: 225, holdingPointsPerSecond: 2.1 },
  level_25: { speed: 250, focus_delay: 200, holdingPointsPerSecond: 2.2 },
  level_26: { speed: 275, holdingPointsPerSecond: 2.3 },
  level_27: { speed: 300, holdingPointsPerSecond: 2.4 },
  level_28: { speed: 300, holdingPointsPerSecond: 2.5 },
}
const DEFAULTS = {
  duration: 20,
  distractor_scale: 1.7,
  speed: 20,
  maneuverability: 20,
  focus_delay: 800,
  fly_out: { min: 0, max: 0, random_position: false, wait_min: 1, wait_max: 3 },
  holdingPointsPerSecond: 1.0,
  notHoldingPointsPerSecond: -0.1,
  desiredHoldingRatio: 0.9,
  distractors: [],
}
const BUTTERFLY_LEVELS = getLevelConfigArray(LEVELS, DEFAULTS, "butterfly")
export default BUTTERFLY_LEVELS
