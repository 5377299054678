import RecordRTC, {getTracks} from 'recordrtc'
import * as EBML from 'ts-ebml'
import {createCanvas} from "helpers/canvas_helper"

window.EBML = EBML

export default class CombinedRecorder
  defaults:
    recording_fps: 10.0
    height: 240
    video_bits_per_second: 100000
    audio: false

  constructor: (@_video, @_frontend, @canvases, options) ->
    @_options = _.defaults options, @defaults
    @ratio = @_video.aspect_ratio
    @combined_renderer = createCanvas(@_options.height * @ratio, @_options.height, 'combined_recording_renderer')
    @context = @combined_renderer.getContext('2d')
    @context.font = "bold #{@combined_renderer.height / 10}px sans-serif";
    @context.textBaseline = 'top'
    if @_frontend
      @canvases.push(@_frontend.canvas)
    @_durations = []
    if (sessionStorage.captureUnityOutput)
      @_unity_output = createCanvas(300 * @ratio, 300, 'unity_output')
    @_loading = @_init()
    @_launch_recordings = []

  _getStream: ->
    videostream = @combined_renderer.captureStream(@_options.recording_fps)
    if @_options.audio
      try
        if not (@_options.audio instanceof MediaStream)
          audiostream = await navigator.mediaDevices.getUserMedia({audio: true})
        else
          audiostream = @_options.audio
        stream = new MediaStream()
        getTracks(audiostream, 'audio').forEach (track)->
          stream.addTrack(track)
        getTracks(videostream, 'video').forEach (track)->
          stream.addTrack(track)
        return stream
      catch
        console.error("AudioStream not available")
    return videostream

  _init: ()=>
    options_rtc =
      type: 'video'
      mimeType: 'video/webm;codecs=vp9'
      videoBitsPerSecond: @_options.video_bits_per_second
      frameRate: parseInt(@_options.recording_fps)
    stream = await @_getStream()
    @recorder = new RecordRTC(stream, options_rtc)

  _renderRecordingCanvas: =>
    if @recorder.getState() == 'recording'
      await @_captureFrame()
      requestAnimationFrame(@_renderRecordingCanvas)

  _doCapture: =>
    start = performance.now()
    @_video.draw(@context)
    for canvas in @canvases
      @context.drawImage(canvas, 0, 0, @combined_renderer.width, @combined_renderer.height)
    if @_text
      @context.strokeStyle = "white"
      @context.fillStyle = "black"
      @context.strokeText(@_text, 0, 0, @combined_renderer.width)
      @context.fillText(@_text, 0, 0, @combined_renderer.width)
    if @_unity_output?
      ctx = @_unity_output.getContext('2d')
      ctx.clearRect(0, 0, @_unity_output.width, @_unity_output.height)
      ctx.drawImage(@_frontend.canvas, 0, 0, @_unity_output.width, @_unity_output.height)
    @_durations.push(performance.now() - start)

  _captureFrame: =>
    if performance.now() - @_last_frame_ts > 1 / @_options.recording_fps
      @_last_frame_ts = performance.now()
      if @_frontend?
        return new Promise (resolve) =>
          @_frontend.captureFrame =>
            @_doCapture()
            resolve()
      else
        @_doCapture()

  start: ->
    @_last_frame_ts = performance.now()
    @_stopping = null
    await @_loading
    @recorder.startRecording()
    @_renderRecordingCanvas()
    @_result = null
    @_start = Date.now()
    @_duration = 0
    @_launch_recordings = []
    @_l_recording = null
    @addLaunchRecording()

  addLaunchRecording: () =>
    launch = @_round_launch ? @_ex_launch
    console.log("addRecording", launch, @_round_launch, @_ex_launch)
    if launch
      @_l_recording = l_recording =
        offset: (@_duration + Date.now() - @_start) / 1000
        launch_offset: launch.getOffset()
      @_launch_recordings.push(@_l_recording)
      launch.load().then =>
        l_recording.launch = launch.id
    else
      @_l_recording = null

  pause: ->
    if @_start
      dur = Date.now() - @_start
      @_duration += dur
      if @_l_recording
        if (dur > 500)
          @_l_recording.duration = dur / 1000
        else
          @_launch_recordings.pop()

    @_start = null
    @recorder.pauseRecording()
    if @_schedule
      console.error("WARNING! Pausing with record schedule is not supported!")

  resume: ->
    @recorder.resumeRecording()
    @_renderRecordingCanvas()
    @_start = Date.now()
    @addLaunchRecording()

  stop: ->
    await @_loading
    clearTimeout(@_schedule)
    if @_stopping is null
      @_stopping = new Promise (resolve) =>
        if @recorder.getState() == 'stopped'
          return resolve()
        else
          if @_start
            @_duration += Date.now() - @_start
          @_start = null
          @recorder.stopRecording(resolve)
    return @_stopping

  getRecording: ->
    if @_result?
      return @_result
    await @stop()
    unseekable_blob = @recorder.getBlob()
    return new Promise (resolve) =>
      RecordRTC.getSeekableBlob unseekable_blob, (blob) =>
        @_result = blob
        blob.duration = @_duration / 1000.0
        blob.launch_recordings = @_launch_recordings
        resolve(blob)

  _startFragment: =>
    @resume()
    @_text = @_fragments[0].text
    @_schedule = setTimeout @_stopFragment, @_fragments[0].end - Date.now()

  _stopFragment: =>
    @_schedule = null
    @pause()
    @_fragments.shift()
    if @_fragments.length
      @_schedule = setTimeout(@_startFragment, @_fragments[0].start - Date.now())

  schedule: (fragments)->
    now = Date.now()
    @_fragments = ({f..., start: now + f.start * 1000, end: now + f.end * 1000} for f in fragments)
    @_schedule = setTimeout(@_startFragment, @_fragments[0].start - Date.now())

  setExerciseLaunch: (launch)=>
    @_ex_launch = launch
  setRoundLaunch: (launch)=>
    @_round_launch = launch
