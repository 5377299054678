import PathsConfigGenerator from "../paths/paths_config_generator"
import rand from "utils/rand"
import Point from "utils/point"
import { SHAPES } from "./track_shapes"
import { areaName } from "../exercise_descriptor"
import SimplexNoise from "simplex-noise"

getSuffix = (areas)=>
  if areas.length == 3
    return "large"
  if areas.length == 2
    a_name = areaName(areas)
    if a_name == "tltr"
      return "top"
    else
      return "side"
  else if areas[0] == "tl" or areas[0] == "tr"
    return "small"
  else
    return "small_bottom"

getFlipX = (areas)=>
  a_name = areaName(areas)
  flip_x = 1
  if a_name == "tltr"
    flip_x  = if rand.bool() then 1 else -1
  if ['brtltr', 'brtr', 'br', 'tr'].includes(a_name)
    flip_x = -1
  else
    flip_x = 1

class Shape
  constructor: (name, conf)->
    @name = name
    _.extend this, conf

  getConfig: ({ suffix, flipX })->
    flip = new Point(flipX, 1)
    pos_config = @[suffix]
    position = flip.mul(pos_config)
    scale = pos_config.scale ? @scale ? { x:  1, y: 1 }
    rotation = pos_config.rotation ? @rotation ? 0
    return {
      path_name: "#{@name}_#{suffix}".toUpperCase()
      position: { x: position.x, y: -position.y }
      scale: flip.mul(scale)
      rotation: rotation * flip.x
    }
for name,config of SHAPES
  SHAPES[name] = new Shape(name, config)

export default class TrackConfigGenerator extends PathsConfigGenerator
  TRIAL_PREFIX: "Track"
  DEFAULTS: {
    trials: 2
    variable_speed: 0
    speed: 1
    rules: [when: _.keys(SHAPES), then: _.keys(SHAPES)]
    remember: 1
    startDelay: 5
  }
  TRIAL_START_ANIMATION_DURATION: 0.4

  constructor: (config, areas)->
    super(config, areas)
    @_simplex = new SimplexNoise()
    @_suffix = getSuffix(areas)
    @_flipX = getFlipX(areas)
    @_shapes = _.keys(_.pickBy(SHAPES, (shape)=>shape[@_suffix]))

  generateSpeedConfig: (duration)->
    STEP = 1
    speed_config = [{
      time_offset: 0,
      speed: @_conf.speed,
      acceleration: 1
    }]
    if @_conf.variable_speed == 0
      return speed_config
    cur_time = 1
    y = Math.random() * 100
    while cur_time < duration
      multiplier = 1 + @_simplex.noise2D(cur_time / duration, y) * @_conf.variable_speed
      speed_config.push({
        time_offset: cur_time,
        speed: @_conf.speed * multiplier,
        acceleration: 0.25
      })
      cur_time += STEP
    return speed_config

  generateTrial: (shape, trial_duration)=>
    _.extend {
      speed: @generateSpeedConfig(trial_duration)
    }, SHAPES[shape].getConfig({ suffix: @_suffix, flipX: @_flipX })

  generateRoundConfig: (round_duration)->
    config = super(round_duration)
    config.pointPerSecond = {
      holdingDouble: 1.0
      holdingSingle: 0.5
      holdingNone: -0.5
    }
    return config

