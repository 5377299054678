const DESCRIPTORS = {}
export default DESCRIPTORS

export function loadDescriptors(context) {
  context.keys().forEach((module) => {
    let DescriptorClasses = context(module).default
    if (!_.isArray(DescriptorClasses)) DescriptorClasses = [DescriptorClasses]
    DescriptorClasses.forEach(
      (DescriptorClass) => (DESCRIPTORS[DescriptorClass.prototype.exercise_id] = DescriptorClass)
    )
  })
}

export function loadDefaultDescriptors() {
  loadDescriptors(require.context("./", true, /\.desc$/, "sync"))
}
export function getDescriptor(base, overrides) {
  if (DESCRIPTORS[base]) return new DESCRIPTORS[base](overrides)
}
