import { getLevelConfigArray } from "exercises/config_generators/utils"

export const LEVELS = {
  level_1: { trials: 5, timeout: -1, next_target: -1, non_targets: 0, range: "small" },
  level_2: { trials: 6, timeout: 10 },
  level_3: { trials: 8, timeout: 6 },
  level_4: { trials: 10, timeout: 5.5 },
  level_5: { timeout: 5, non_targets: 0.25 },
  level_6: { trials: 12, timeout: 4.5, next_target: 4, range: "medium" },
  level_7: { trials: 14, timeout: 4, next_target: 3.5 },
  level_8: { next_target: 3 },
  level_9: { timeout: 3.5, next_target: 2.5 },
  level_10: { trials: 16, next_target: 2 },
  level_11: { trials: 18, timeout: 3, range: "large" },
  level_12: { trials: 20, timeout: 2.5 },
  level_13: { timeout: 2, next_target: 1.8 },
  level_14: { trials: 21, timeout: 1.95, next_target: 1.65 },
  level_15: { trials: 22, timeout: 1.9, next_target: 1.5 },
  level_16: { trials: 23, timeout: 1.85, next_target: 1.35 },
  level_17: { trials: 24, timeout: 1.8, next_target: 1.2 },
  level_18: { trials: 26, timeout: 1.75 },
  level_19: { trials: 28, next_target: 1.1 },
  level_20: { trials: 30, timeout: 1.65 },
  level_21: { trials: 32, next_target: 1.0 },
  level_22: { trials: 34, timeout: 1.6 },
  level_23: { trials: 36, next_target: 0.9 },
  level_24: { trials: 38, timeout: 1.55, non_targets: 0.4 },
  level_25: { trials: 40, next_target: 0.8 },
  level_26: { trials: 42, timeout: 1.5 },
  level_27: { trials: 44, next_target: 0.7 },
  level_28: { trials: 45, timeout: 1.45 },
}
const DEFAULTS = {
  trials: 5,
  target_score: 2,
  non_target_score: -1,
  timeout: -1,
  next_target: -1,
  non_targets: 0,
  range: "small",
}
const FOOTBALL_LEVELS = getLevelConfigArray(LEVELS, DEFAULTS, "football")
export default FOOTBALL_LEVELS
