import ConfigGenerator from "exercises/config_generators/config_generator"
import { mirrorPoints } from "helpers/point_helper"
import Point from "utils/point"
import rand from "utils/rand"

SIZE = 0.18
TOP = -0.45
LEFT_POSITIONS = [
  { x: -SIZE * 0.75, y: TOP + SIZE * 0.5 }
  { x: -SIZE * 2, y: (TOP + SIZE * 0.7) }
  { x: -SIZE * 3.25, y: (TOP + SIZE * 0.9) }
  { x: -SIZE * 4, y: (TOP + SIZE * 2) }
  { x: -SIZE * 4, y: (TOP + SIZE * 3.25) }
  { x: -SIZE * 3.8, y: (TOP + SIZE * 4.5) }
]

export ALL_POSITIONS = [LEFT_POSITIONS..., mirrorPoints(LEFT_POSITIONS)...]
TYPES_ENUM = {
  DISC: 1,
  HEART: 2,
  OAR: 3,
  PIKE_POLE: 4,
  RHOMBOID: 5,
  SPADE: 6,
  STAR: 7,
  TRIDENT: 8
}
TYPES = _.keys(TYPES_ENUM)
class Stick
  constructor: (position, type)->
    @position = position
    @type = type

  findEdge: (p1, p2, circle)->
    if p1.distance(p2) < 0.001
      return p1
    p3 = p1.interpolate(p2, 0.5)
    if p3.distance(circle) < circle.radius
      return @findEdge(p3, p2, circle)
    else
      return @findEdge(p1, p3, circle)

  setRotation: (prev, intersection_area)=>
    STICK_LENGTH = 0.66
    #Założenie: długość bierki musi być wystarczająca, żeby z każdej pozycji mogła on
    #Dotknąc każdego punktu w intersection area
    pos = @position
    if prev?
      other_pos = prev.position
      other_vec = prev.vector
      other_ip = prev.intersection_point
    else
      other_pos = new Point(0.0, 0.0)
      other_vec = new Point(0, 1) #normalized vec
      other_ip = new Point(0.0, 0.0)
    # other_ip jest gdzieś w środku obszaru
    start = @findEdge(other_ip, other_ip.add(other_vec), intersection_area)
    end = @findEdge(other_ip, other_ip.sub(other_vec), intersection_area)
    @intersection_point = start.interpolate(end, Math.random())
    @vector = @intersection_point.subtract(pos).normalize()
    @rotation = @vector.degreesTo({ x: 0, y: 0 })

  toConfig: (id_prefix, id)->{
    id: "#{id_prefix}_Stick_#{id}",
    position: {
      x: @position.x,
      y: -@position.y,
    },
    angle: 90 - @rotation
    type: TYPES_ENUM[@type],
    size: 0.1
  }

export class SticksConfigGenerator extends ConfigGenerator
  SIZE: SIZE
  intersection_area: {
    x: 0,
    y: 0.2,
    radius: 0.3
  }
  generateScenario: (round_duration)->
    time_per_stick = @MIN_REACTION_TIME + @ANIMATION_DURATION
    sticks = @_conf.elements
    piles = Math.ceil(round_duration * 1000 / (sticks * time_per_stick + 2 * @ANIMATION_DURATION))
    scenario = []
    for pile in [0...piles]
      scenario.push(@_generatePile(pile))
    return scenario

  generatePile: ()->
    positions = rand.elements(ALL_POSITIONS, @_conf.elements)
    colors = rand.elements(TYPES, @_conf.colors)
    sticks = (new Stick(p, colors[i % colors.length]) for p,i in positions)
    for s,i in sticks
      s.setRotation(sticks[i - 1], @intersection_area)
    return sticks

  _generatePile: (id = 0) ->
    sticks = @generatePile()
    id = "Pile_#{id}"
    return {
      id: id,
      "active_sticks": @_conf.active,
      "activation_duration": @_conf.activation_duration,
      "error_duration": 2.1,
      "sticks": (s.toConfig(id, i) for s,i in sticks)
    }

