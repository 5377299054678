import EndRoundScreen from "./screens/end_round_ui"
import EndExerciseScreen from "./screens/end_exercise_ui"
import PauseScreen from "./screens/pause_ui"
import GameLoaderUi from "runner/screens/game_loader_ui"
import { EVENTS } from "exercises/base_exercise"
import browser_warning from "./screens/browser_version_warning.html"

export default class ExerciseScreens
  constructor: (container, options)->
    @_container = container
    @_loader = new GameLoaderUi(container)
    @_options = options
    @showing_screen = Promise.resolve()

  waitForButton: (button_text)->
    return @_loader.waitForButton(button_text)

  showRoundEndScreen: (options)->
    round_end = new EndRoundScreen(@_container)
    return round_end.show(options)

  showExerciseEndScreen: (options)->
    exercise_end = new EndExerciseScreen(@_container)
    return exercise_end.show(options)

  loadProgress: (percent)->
    @_loader.setPercents(percent)

  showLoader: (options) ->
    @_loader.show(options)

  _transition: (start_func)->
    return new Promise (resolve) =>
      @_container.one "transitionend", resolve
      start_func()

  hideLoader: () ->
    if @_loader.isVisible()
      await @_transition => @_loader.hide()
    else
      return Promise.resolve()

  connectEvents: (exerciseEventEmitter, proxy) ->
    proxy ?= (f)->f
    exerciseEventEmitter.one EVENTS.ready, proxy =>
      await @hideLoader()
      exerciseEventEmitter.continue()

    exerciseEventEmitter.on EVENTS.round_finished, proxy (results) =>
      if not results.last_round
        options = _.extend {}, @_options, results
        await @showing_screen = @showRoundEndScreen(options)
        exerciseEventEmitter.continue()

    exerciseEventEmitter.on EVENTS.loading_started, proxy (options)=>
      @showLoader(options).catch(()->)

    exerciseEventEmitter.one EVENTS.round_ready, proxy =>
      exerciseEventEmitter.continue()
    exerciseEventEmitter.on EVENTS.loading, proxy (progress) =>
      @loadProgress(progress)

  connectExerciseFinishScreen: (exerciseEventEmitter, proxy)->
    proxy ?= (f)->f
    exerciseEventEmitter.on EVENTS.exercise_finished, proxy (results) =>
      await @showing_screen = @showExerciseEndScreen(results)


  showChromiumInfo: (onClick)->
    modal = $(browser_warning)
    await new Promise (resolve) =>
      modal.appendTo(@_container)
      modal.on('hidden.bs.modal', resolve)
      modal.find('button').on 'click', onClick
      modal.modal('show')
    modal.remove()
