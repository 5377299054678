#Adapted from web/nf_games/static/game/follow_hand/follow_hand_director.coffee
define [
  'utils/vector'
  'utils/rand'
  'utils/math'
  'utils/tmachine'
  'utils/random_mover'
  'utils/path'
  'underscore'
], (Vector
  rand
  math
  TMachine
  RandomMover
  Path
  _) ->
  gen_whirlybird_paths = (n, x_min = 0.1, x_max = 0.9) ->
    paths = []
    for i in [0...n]
      x_from = rand.float(x_min, x_max)
      x_to = rand.float(x_from - 0.2, x_from + 0.2)
      c1_x = rand.float(x_from - 0.38, x_from + 0.38)
      c1_y = rand.float(0.1, 0.9)
      c2_x = rand.float(x_from - 0.38, x_from + 0.38)
      c2_y = rand.float(0.1, 0.9)
      paths.push
        path: "M #{x_from},-0.1 C #{c1_x},#{c1_y} #{c2_x},#{c2_y} #{x_to},1.1"
        duration: rand.float(2000, 4000)
    # FIXME: this triggers a bug
    #posFunc: rand.element(['Linear', 'InQuad', 'InCubic', 'InQuart', 'InSine', 'InCirc'])
    # console.log p.path for p in paths
    return paths

  getMoverConfig = (config) ->
    speed = config.speed ? 0
    maneuverability = config.maneuverability ? 0
    cfg =
      useRunner: false
      runner:
        maxSpeed: 2
        minSpeed: 0.5
        remainsOnScreen: false
        avoidEdges: false
        avoidEdgesStrength: 100
        perlin: true
        perlinSpeed: 1
        perlinAccelLow: -0.075
        perlinAccelHigh: 0.075
      follower:
        avoidEdges: true
        maxSpeed: 3
        minSpeed: 0.5

    if speed < 0 or speed > 300
      throw new Error 'Speed must be from 0 to 300'
    if maneuverability < 0 or maneuverability > 100
      throw new Error 'Maneuverability must be from 0 to 100'

    cfg.runner.maxSpeed = math.mapValue(speed, 0, 300, 4, 22)
    cfg.follower.maxSpeed = math.mapValue(speed, 0, 300, 6, 33)

    cfg.runner.minSpeed = math.mapValue(speed, 0, 100, 0, 4)
    cfg.follower.minSpeed = math.mapValue(speed, 0, 100, 0, 4)

    accel_val = math.mapValue(maneuverability, 0, 100, 0.07, 10 * 0.075)
    cfg.runner.perlinAccelLow = -accel_val
    cfg.runner.perlinAccelHigh = accel_val

    base_speed = 0.01
    cfg.runner.perlinSpeed = math.mapValue(maneuverability, 0, 100, base_speed, 100 * base_speed)

    return cfg

  class FlyMachine extends TMachine
    @config:
      events: [
        { name: 'startup', from: 'none', to: 'free_flight' }
        { name: 'fly_out', from: 'free_flight', to: 'fly_out_escaping' }
        { name: 'fly_out_wait', from: 'fly_out_escaping', to: 'fly_out_waiting' }
        { name: 'fly_out_return', from: 'fly_out_waiting', to: 'fly_out_returning' }
        { name: 'resume_free_flight', from: 'fly_out_returning', to: 'free_flight' }
      ]
    constructor: (@mover, @config = {}) ->
      super(arguments...)
      @config.area ?= new Path('M 0,0 0,600 800,600 800,0 z')
      @config.speed ?= 20
      @config.maneuverability ?= 20
      @config.flyOutRandPos ?= false
      @_setWorldBoundaries(@config.area)
      @mover.follow()
      @visible_rect =
        p1:
          x: -@config.margin_x, y: -@config.margin_y
        p2:
          x: 800 + @config.margin_x, y: 600 + @config.margin_y

# @debugChanging('FlyMachine')

    _setWorldBoundaries: (path) =>
      @mover.runner.worldBoundaries = path
      @mover.follower.worldBoundaries = path

    @onEnter fly_out_escaping: ->
      pos = @mover.follower.position
      vel = @mover.follower.velocity

      # save current position
      @flyout_return_pos = pos.copy()

      # select flyout destination point
      v = vel.copy()
      if v.length() > 0
        v.normalize()
      else
        v = new Vector(0, -1)
      v.mul(100)
      @flyout_dest_pos = pos.copy()
      while math.isPointInsideRectangle(@flyout_dest_pos, @visible_rect.p1, @visible_rect.p2)
        @flyout_dest_pos.add(v)

      @mover.follower.seekTarget =
        position: @flyout_dest_pos.copy()

      @mover.follower.avoidEdges = false
      @mover.follower.checkEdges = false
      return true

    @onEnter fly_out_waiting: ->
      if @config.flyOutRandPos
        p = new Vector()
        p.x = rand.float(@visible_rect.p1.x, @visible_rect.p2.x)
        p.y = rand.float(@visible_rect.p1.y, @visible_rect.p2.y)
        switch rand.int(0, 3)
          when 0
            p.y = -@config.margin_y
          when 1
            p.y = 600 + @config.margin_y
          when 2
            p.x = -@config.margin_x
          when 3
            p.x = 800 + @config.margin_x
          else
            throw new Error 'flyOutRandPos: this should never be reached'
        @mover.follower.position.set(p.x, p.y)

    @onEnter fly_out_returning: ->
      @mover.follower.seekTarget =
        position: @flyout_return_pos.copy()

    @onEnter free_flight: (e, f, t) ->
      if f is 'fly_out_returning'
        @mover.runner.position = @flyout_return_pos.copy()
        @mover.follow()
        @mover.follower.checkEdges = true

  class ButterflyMover
    @gen_whirlybird_paths = gen_whirlybird_paths
    constructor: (@config) ->
      _.defaultsDeep @config,
        flight_logic:
          speed: 20 # 0 <= x <= 100
          maneuverability: 20 # 0 <= x <= 100
          flyOutsMin: 0
          flyOutsMax: 0
          flyOutRandPos: false # randomize return position
          flyOutWaitMin: 1000
          flyOutWaitMax: 3000
          flyOutInitialGrace: 5 * 1000
          margin_x: 70
          margin_y: 70
        area: null
        initial_point: { x: 0.5, y: 0.5 }

      selected_area_def = @config.area
      @area = new Path(selected_area_def)
      @area = @area.getScaled(selected_area_def)
      @area.closePath()
      @config.flight_logic.area = @area

    start: (level_duration)  ->
      @mover = new RandomMover(getMoverConfig(@config.flight_logic))
      @fly_machine = new (FlyMachine.create())(@mover, @config.flight_logic)
      @game_time = 0

      @initial_point = new Vector(@config.initial_point)
      @initial_point.add({x:0.5,y:0.5}).scale(800, 600)
      console.log(@config.initial_point,@initial_point)
      @fly_machine.mover.runner.position = @initial_point
      @fly_machine.mover.follower.position = @initial_point
      c = @config
      fc = @config.flight_logic

      @flyOutWaitMin = fc.flyOutWaitMin
      @flyOutWaitMax = fc.flyOutWaitMax
      @flyOutInitialGrace = fc.flyOutInitialGrace

      # randomize flyOut times
      @flyOutsNumber = rand.int(fc.flyOutsMin, fc.flyOutsMax)
      console.log "Number of fly outs: #{@flyOutsNumber}, min: #{fc.flyOutsMin}, max: #{fc.flyOutsMax}"
      @flyOutTimes = []
      if @flyOutsNumber > 0
        total_time = level_duration - @flyOutInitialGrace
        mean_flight_time = total_time / (@flyOutsNumber + 1)
        t = @flyOutInitialGrace
        for i in [0...@flyOutsNumber]
          t += mean_flight_time + rand.float(-mean_flight_time / 3.5, mean_flight_time / 3.5)
          @flyOutTimes.push(t)
      console.log 'flyOutTimes: ' + (x.toFixed(2) for x in @flyOutTimes).join(', ')

      console.log '_start finished: '
      @flyOutWaitStartTime = 0

    advance: (frame) ->
      delta = frame.delta
      @_advanceFlyMachineState(@game_time, delta, frame.time)
      mover = @fly_machine.mover
      is_inside_area = @area.isPointInside(mover.follower.position)
      if is_inside_area # time is ticking only when flyer is inside area
        @game_time += delta
      @is_inside = is_inside_area
      @position = mover.follower.getPosition()
      @is_waiting = @fly_machine.current == "fly_out_waiting"


    _advanceFlyMachineState: (game_time, delta, time) ->
      state = @fly_machine.current
      mover = @fly_machine.mover
      pos = mover.follower.position
      switch state
        when 'free_flight'
          if @flyOutTimes.length > 0 and game_time >= @flyOutTimes[0]
            @flyOutTimes.shift() # remove first element
            if @fly_machine.can('fly_out')
              console.log 'bye, bye'
              @fly_machine.fly_out()
            else
              console.log "can't fly out - already out"
        when 'fly_out_escaping'
# switch to waiting when flyer disappears from screen
#console.log 'exiting...'

          margin_x = @config.flight_logic.margin_x
          margin_y = @config.flight_logic.margin_y
          p1 = @fly_machine.visible_rect.p1
          p2 = @fly_machine.visible_rect.p2
          if not math.isPointInsideRectangle(pos, p1, p2)
            console.log 'escaped:', pos.formatStr(), p1, p2
            @flyOutWaitStartTime = time
            @fly_out_wait_duration = rand.float(@flyOutWaitMin, @flyOutWaitMax)
            console.log "Flyer is out - waiting #{@fly_out_wait_duration} ms"
            @fly_machine.event('fly_out_wait')
        when 'fly_out_waiting'
          fly_out_wait = time - @flyOutWaitStartTime
          if fly_out_wait >= @fly_out_wait_duration
            console.log "Flyer is returning (waited: #{fly_out_wait})"
            @fly_machine.event('fly_out_return')
        when 'fly_out_returning'
#console.log 'flyout_flyback'
          if mover.follower.position.distanceTo(@fly_machine.flyout_return_pos) < 15
            console.log 'resuming free flight'
            @fly_machine.event('resume_free_flight')

      if @fly_machine.current != "fly_out_waiting"
        mover.step(delta / 1000)
