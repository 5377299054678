import ConfigGenerator from "../config_generators/config_generator"

generateSubsets = (items) ->
  if items.length == 1
    return [items]
  result = []
  item = items[0]
  subsets = generateSubsets(items[1..])
  for s in subsets
    result.push [item, s...]
  result.push([item], subsets...)
  return result
export AREAS = { 'tl', 'tr', 'bl', 'br' }
export areaName = (areas)=> areas.sort().join("")
export areasFromName = (areaName)=>areaName.match(/.{2}/g)

export default class ExerciseDescriptor
  scene_name: 'LoaderScene'
  exercise_id: "loader"
  instructions: []
  aspect_ratio: 16 / 9
  unity_config: 'NFExercises/Build/NFExercises.json'
  calibration:
    desired_arm_range: 16 / 9
    target_face_position: { x: 0.0, y: -0.05 }
    visualization:
      unity_only: true
  available_areas: generateSubsets(_.keys(AREAS))
  levels: 28
  has_levels: true
  GeneratorClass: ConfigGenerator
  round_duration: 60
  LEVELS_CONFIG: []
  TEST_LEVELS: []

  constructor: (overrides)->
    _.extend this, overrides

  getName: ->_t("db:exercise.#{@exercise_id}.name")

  getInstructions: (run_config) ->
    return @instructions

  getInstructionVideo: ->
    video = _.find(@instructions, 'video')
    if (video)
      return {
        slides: video.video.slides
        file: video.video,
        author: undefined,
        offset: 0,
        base: true
      }

  getUnityConfig: ->
    if @unity_config == 'mock'
      return @unity_config
    if /^(https?|\/)/.test @unity_config
      return @unity_config
    return __webpack_public_path__ + @unity_config

  getExerciseClass: ->
    return `import(
      /* webpackChunkName:"exercise" */
      "exercises/exercise")`

  withOverrides: (overrides)->
    return new this.constructor(overrides)

  createExercise: (exercise_ui)->
    ExerciseClass = (await @getExerciseClass()).default
    return new ExerciseClass(this, exercise_ui)

  updateTexts: (round_config, run_config)->
    round_config.texts = {
      "round_label": _t("exercise_ui.round_label"),
      "round": run_config.round,
      "total_rounds": run_config.total_rounds,
      "level_label": _t("exercise_ui.level_label"),
      "level": run_config.level,
      "game_name": @name,
      "score_label": _t("exercise_ui.score_label"),
      "goal_label": _t("exercise_ui.goal_label"),
      "goal": run_config.goal
      "game_name": @getName()
    }
    return round_config

  _getLevelConfig: (run_config)->
    level = run_config.level
    level_config = if level > 0
      @LEVELS_CONFIG[level - 1]
    else
      @TEST_LEVELS[-level - 1]
    if not level_config?
      console.error("No such level: #{level}")
    return level_config

  getGenerator: (run_config)->
    return new @GeneratorClass(@_getLevelConfig(run_config), run_config.areas)

  generateRoundConfig: (run_config) ->
    generator = @getGenerator(run_config)
    config = generator.generateRoundConfig(run_config.round_duration)
    @updateTexts(config, run_config)
    return config

  LEVEL_UP_THRESHOLD: 1.0
  LEVEL_DOWN_THRESHOLD: 0.3
  TWO_LEVEL_UP_THRESHOLD: 1.15
  THREE_LEVEL_UP_THRESHOLD: 1.3

  _getLevelChange: (normalized_score, level) ->
    if normalized_score >= @THREE_LEVEL_UP_THRESHOLD and level < 10
      return +3
    else if normalized_score >= @TWO_LEVEL_UP_THRESHOLD and level < 20
      return +2
    else if normalized_score >= @LEVEL_UP_THRESHOLD
      return +1
    else if normalized_score <= @LEVEL_DOWN_THRESHOLD
      return -1
    else
      return  0

  _calculateResults: (run_config, results)->
    return results

  calculateResults: (run_config, results)->
    results.expected_points = Math.floor(@getExpectedPoints(run_config))
    results = @_calculateResults(run_config, results)
    results.normalized_score = @getNormalizedScore(results)
    results.level_change = @_getLevelChange(results.normalized_score, run_config.level)
    return results

  getNormalizedScore: (results)->
    return results.score / Math.max(results.expected_points, 1)

  shouldCalibrate: (current_position)->
    return current_position is false and @calibration

  getWatcherConfig: ->{
    subtractor: "cv"
    threshold: 0.2
  }

  getRunConfig: (run_config)->
    config = _.defaults {}, run_config, {
      name: @getName(),
      image: @icon,
      level: 1
      round: 1
      round_duration: parseInt(/round_duration=(\d+)/.exec(location.search)?[1] ? @round_duration),
      total_rounds: 3,
      areas: @available_areas[0],
      calibration: not /skip_calibration/.test location.search
      exercise_id: @exercise_id
      parent: null
      show_instructions: true
      tutorial: false
      load_delay: 0
      last_level: 0
      last_score: 1.0
    }
    config.level ?= 1
    if !config.goal? or _.isNaN(config.goal)
      config.goal = @getGoal(config)

    for int_property in ['total_rounds', 'round', 'level', 'round_duration', 'load_delay']
      config[int_property] = parseInt(config[int_property])
    return config

  getExpectedPoints: (run_config)->
    { level, round_duration } = run_config
    return 30 * round_duration / 60

  SAME_LEVEL_GOAL: 1.1
  LOWER_LEVEL_GOAL: 0.8
  _getModifier: (level, last_level, last_normalized_score)->
    if last_level is null or last_level < level
      return 1.0
    else if last_level > level
      is_level_modified_manually = last_normalized_score >= 1.0
      if is_level_modified_manually
        return 1.0
      else
        return @LOWER_LEVEL_GOAL
    else if last_normalized_score?
      return Math.min(Math.max(@SAME_LEVEL_GOAL * last_normalized_score, 0.5), 1.0)
    else
      return 1.0


  getGoal: (run_config)->
    { level, last_level, last_score } = run_config
    expectedPoints = @getExpectedPoints(run_config)
    return Math.floor(@_getModifier(level, last_level, last_score) * expectedPoints)
