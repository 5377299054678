import ExerciseDescriptor, { AREAS, areaName } from "../exercise_descriptor"
import TRACK_LEVELS, { TEST_LEVELS } from "./track_levels"
import TrackConfigGenerator from "./track_config_generator"

excluded_areas = ["bltr", "blbr", "brtl", "blbrtltr", "blbrtl", "blbrtr"]
export default class PathsDescriptor extends ExerciseDescriptor
  scene_name: "Track"
  exercise_id: "track"
  levels: TRACK_LEVELS.length
  instructions: [
    { video: { slides: "track" } },
    { hint: _tnoop("hints:track.instruction") }
  ]
  LEVELS_CONFIG: TRACK_LEVELS
  TEST_LEVELS: TEST_LEVELS
  GeneratorClass: TrackConfigGenerator
  available_areas: ExerciseDescriptor::available_areas.filter(
    (area)=>not excluded_areas.includes(areaName(area))
  )

  _calculateResults: (run_config, results)->
    results.correctness_score = results.score / results.max_score
    results.avg_trial_duration = results.duration / results.max_score
    results.speed_score = results.max_score / results.expected_points
    return results

  getExpectedPoints: (run_config)->
    conf = @_getLevelConfig(run_config)
    duration = run_config.round_duration - 2 * @GeneratorClass::TRIAL_START_ANIMATION_DURATION
    return 0.9 * Math.min(duration, conf.duration)
