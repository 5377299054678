import ConfigGenerator from "exercises/config_generators/config_generator"
import SHAPES from "./halves_shapes"
import rand from "utils/rand"
import { DEFAULTS } from "./halves_levels"
import SvgPositionGenerator from "exercises/config_generators/svg_position_generator"
import quiz_positions from "./quiz_positions.svg?inline"

export class Shape
  constructor: (name, conf)->
    @name = name
    _.extend this, conf

  setComplexityGroup: (group)->
    group.push(this)
    @complexity_group = group

  setSimilarityGroup: (group)->
    group.push(this)
    @similarity_group = group

  randomRotation: (angle) ->
    if angle == 0
      return 0
    else
      return Math.round(Math.random() * 360 / angle) * angle

  commonSlices: (slices)->
    return _.intersection @slices.split(''), slices.split('')

  randomSlice: (possible_slices) ->
    return rand.element(@commonSlices(possible_slices))

export default class HalvesConfigGenerator extends ConfigGenerator
  Y_OFFSET = 0.07
  X_OFFSET = 0.08
  TOP_OFFSET = 0.02
  BOTTOM_MAX = 52
  VERTICAL_BORDER = 11
  TOP_MAX = 100


  AREAS:
    tr: {
      start_point: { x: X_OFFSET, y: Y_OFFSET + TOP_OFFSET },
      angle: { start: -TOP_MAX, end: -VERTICAL_BORDER },
      radius: { inner: 0.5, outer: 0.5 }
    }
    br: {
      start_point: { x: X_OFFSET, y: Y_OFFSET },
      angle: { start: -VERTICAL_BORDER, end: BOTTOM_MAX },
      radius: { inner: 0.5, outer: 0.5 }
    }
    tl: {
      start_point: { x: -X_OFFSET, y: Y_OFFSET + TOP_OFFSET },
      angle: { start: -180 + VERTICAL_BORDER, end: -180 + TOP_MAX },
      radius: { inner: 0.5, outer: 0.5 }
    }
    bl: {
      start_point: { x: -X_OFFSET, y: Y_OFFSET },
      angle: { start: 180 - BOTTOM_MAX, end: 180 + VERTICAL_BORDER },
      radius: { inner: 0.5, outer: 0.5 }
    }
  ELEMENT_SIZE: 0.18
  START_DELAY: 1000
  constructor: (level_conf, areas)->
    super(level_conf, areas)
    @_shapes = {}
    @_by_complexity = {}
    @_by_group = {}
    base_probability = 1 / _.size(SHAPES)
    for name,s_conf of SHAPES
      shape = new Shape(name, s_conf)
      if shape.commonSlices(@_conf.slices).length == 0
        continue
      @_shapes[name] = s = shape
      s.probability = base_probability
      @_by_complexity[s.complexity] ?= []
      s.setComplexityGroup(@_by_complexity[s.complexity] ?= [])
      s.setSimilarityGroup(@_by_group[s.group] ?= [])
    @_position_generator = new SvgPositionGenerator(quiz_positions, @_areas, level_conf.answers, @ELEMENT_SIZE)

  generateScenario: (round_duration) ->
    min_element_duration = @ANIMATION_DURATION * 2 + Math.min(@MIN_REACTION_TIME, @_conf.timeout)
    total_elements = Math.ceil(round_duration * 1000 / min_element_duration)
    possible_questions = @_by_complexity[@_conf.complexity]
    size = @ELEMENT_SIZE
    for i in [0...total_elements]
      probabilities = @_getShapeProbabilities(possible_questions)
      question = @_shapes[rand.weightedObj(probabilities)]
      @_updateProbabilities(question, possible_questions)
      distractors = @_getDistractors(question, @_conf.answers - 1, @_conf.similarity)
      positions = @_position_generator.getPositions(@_conf.answers)
      trial =
        correctAnswer:
          id: "answer_" + @_id
          shapeID: question.name
          rotation: question.randomRotation(@_conf.rotation)
          size: size
          slice: question.randomSlice(@_conf.slices)
          position: positions[0].toObject()
        wrongAnswers: []
        timeout: @_conf.timeout
      for d,i in distractors
        trial.wrongAnswers.push
          id: "distractor_#{@_id}_#{i}"
          shapeID: d.name
          rotation: d.randomRotation(@_conf.rotation)
          size: size
          slice: d.randomSlice(@_conf.slices)
          position: positions[i + 1].toObject()
      @scenario.push trial
      @_id = @scenario.length
    return @scenario

  _getId: (prefix)->
    return "#{prefix}_#{@_id++}"

  _getShapeProbabilities: (shapes) ->
    total = _.sumBy(shapes, "probability")
    probabilities = {}
    for s in shapes
      probabilities[s.name] = s.probability / total
    return probabilities

  _updateProbabilities: (shape, shapes)->
    for s in shapes
      s.probability += shape.probability / (shapes.length - 1)
    shape.probability = 0

  _getDistractors: (question, number, similarity)->
    possible_distractors = []
    if similarity
      possible_distractors.push(_.without(@_by_group[question.group], question)...)
    else
      for name,group of @_by_group
        if name != question.group
          possible_distractors.push(group...)
    distractors = []
    while distractors.length < number
      probabilities = @_getShapeProbabilities(possible_distractors)
      distractor = @_shapes[rand.weightedObj(probabilities)]
      @_updateProbabilities(distractor, possible_distractors)
      distractors.push(distractor)
    return distractors



