define ['stateMachine', 'utils/call_queue', 'underscore', 'utils/logger',
  "utils/jquery.titanis"], (StateMachine, CallQueue, _, Logger, $) ->
  console = new Logger('TM')
  class MachineCallQueue extends CallQueue
    constructor: ->
      emit = super(arguments...) # explicit return is required here
      @inTransition = false
      @_pending_events = []
      return emit

    emit: ->
      res = super(arguments...)
      for r in res
        if r in [false, 'async'] or r?.goTo?
          return r
      return res

  _key_listeners = {}
  _listener_stack = []
  if window?
    $(window).one 'unload', ->
      _key_listeners = {}

  createObj = (obj_or_array, func) ->
    if _.isArray(obj_or_array)
      res = {}
      for event in obj_or_array
        res[event] = func
      return res
    else if _.isObject(obj_or_array)
      return obj_or_array
    else
      res = {}
      res[obj_or_array] = func
      return res

  class TMachine
    SKIP_KEY: 'ctrl+shift+1'
    BIG_SKIP_KEY: 'ctrl+shift+2'
    RAISE_ON_INVALID_TRANSITION: true
    constructor: (@name, config) ->
      if not @name?
        @name = @constructor.name
      if config?.startup ? true
        @startup()
      @_destroyed = false

    event: (event, params...) ->
      warning = true
      try
        result = @[event](params...)
        warning = false
        return result
      finally
        if warning
          console.warning "Exception during processing of '#{event}' event in #{@name} machine"

    destroy: ->
      for own k of @
        @[k] = undefined
      @_destroyed = true
      @transition = ->

    setTimeout: (timeout, callback)->
      setTimeout_ timeout, =>
        if @_destroyed == false
          callback?()

    tryEvent: (event, params...) -> if @can event then @event event, params...

    delayedEvent: (name, params...) -> setGTimeout 0, => @event name, params...

    tryInStateEvent: (state, event, params...) -> if @current == state and @can event then @event event, params...

    error: (event, from, to, args, error, errorMsg, e) ->
      if @_destroyed
        return
      console.warning @name, errorMsg
      if e?
        throw e
      if error == StateMachine.Error.INVALID_TRANSITION
        if @RAISE_ON_INVALID_TRANSITION or !console.report_error?
          throw new Error(@name + ':' + errorMsg)
        else
          console.report_error @name + ':' + errorMsg

    _on: (prefix, events, func, clear = false, next = false) ->
      events = createObj(events, func)
      for event, func of events
        queue_name = 'on' + prefix + event
        if clear
          @[queue_name] = undefined
        MachineCallQueue.install(@, queue_name, func, next)
        if prefix == 'enter' and @current == event
          func()
      null

    remove: (event, state, func) -> @_remove 'on' + event + state, func

    _remove: (event, func) -> @[event]?.disconnect?(func)

    onChanging: (func) -> @_on('changing', 'state', func)

    onChanged: (func) -> @_on('changed', 'state', func)

    debugChanging: (name) ->
      if @_debugging_changing?
        return
      @_debugging_changing = true
      @onChanging (e, f, t) ->
        if @_stop_debug
          return
        msg = "on event '#{e}' changing state from '#{f}' to '#{t}'"
        if name and name != @name
          msg = "#{name}(#{@name})\t#{msg}"
        else
          msg = "#{@name}\t#{msg}"
        console.debug msg
    log: (args...)->
      console.debug "#{@name}\t", args...
    clear: (event) ->
      if not event.match(/^on/)
        event = 'on' + event
      @[event] = new CallQueue(@)
    isFinal: ->
      return @current in @final_states
    @create: ->
      if @config.final_states
        @::final_states = @config.final_states
      if @config.stop_event
        @::stop_event = @config.stop_event
      events = []
      for e in @config.events
        if e.fromTo?
          for from_,to of e.fromTo
            events.push _.defaults from: from_, to: to, _.omit(e, 'fromTo')
        else
          events.push e
      @config.events = events
      events = []
      for e in @config.events
        if _.isArray(e.name)
          for name in e.name
            events.push _.defaults name: name, e
        else
          events.push e
      @config.events = events
      StateMachine.create(@config, @::)
      @Result = @::Result = StateMachine.Result
      @Error = @::Error = StateMachine.Error
      return @

    setupCallThrough: (obj) ->
      for k, v of @
        if not obj[k]? and _.isFunction(v)
          obj[k] = _.bind(v, @)
      return

    addSubMachine: (state, machine, event, finished_callback)->
      if _.isFunction(machine)
        machine = machine()
      finished_callback ?= (args...)=>@finished(args...) if @can('finished')
      machine.onChanged (args...) =>finished_callback(args...) if @is(state) and machine.isFinal()
      @onEnter state, (e, f, t, params...)->
        machine.event event ? 'start', params...
      @onLeave state, ->
        machine.event machine.stop_event ? 'stop'

    onKey: (states, keys, func_or_event, skip = false) ->
      u_id = _.uniqueId()
      if _.isString(func_or_event)
        f = (key) =>
          @[func_or_event](key)
          if skip
            return 'skip'
      else
        f = func_or_event
      if _.isString(keys)
        keys = [keys]
      if !keys? or keys.lenght == 0
        throw Error("Keys are empty!")
      do (u_id, f, keys) =>
        enable_listener = =>
          _key_listeners[u_id] = (key_pressed) =>
            for key in keys
              if key == 'any' or key_pressed == key
                key_name = key + if key != key_pressed then " (#{key_pressed})" else ''
                console.debug "Key Pressed: '#{key_name}'"
                return f.call(@, key_name)
              if ('!' + key_pressed) == key
                return false
            return false
          _listener_stack.push(u_id)
        disable_listener = ->
          _listener_stack = _.without(_listener_stack, u_id)
          delete _key_listeners[u_id]
        @onEnter states, enable_listener
        @onLeave states, disable_listener

    understands: (event_name) -> @[event_name]?

    @extend: (events) ->
      old = @config ? events: []
      events = [].concat(old.events, events)
      @config = _.defaults({events: events}, old)
      @create()

    @_keyPressed = (key) ->
      keys = _listener_stack[..]
      keys.reverse()
      result = false
      for u_id in keys when not result
        result = _key_listeners[u_id]?(key) == 'skip'

    @keyEvent = (event) ->
      key = $.parseKey(event)
      results = TMachine._keyPressed(key)
      if _.some(results)
        event.stopImmediatePropagation()
        event.preventDefault()

    @installEvents: (target)->
      events = "keyup.tmachine click.tmachine"
      $(target).off(events, TMachine.keyEvent).on(events, TMachine.keyEvent)

    @removeEvents: (target)->
      events = "keyup.tmachine click.tmachine"
      $(target).off(events)

    @setRaiseOnInvalidTransition: (val)->
      TMachine::RAISE_ON_INVALID_TRANSITION = val
    @_onPrefix: (prefix, obj, func)->
      for event, func of createObj(obj, func)
        name = 'on' + prefix + event
        if @::[name]?
          msg = "Method #{name} for class #{@} already defined! Check parent classes!"
          console.error(msg)
          throw new Error(msg)
        @::[name] = func
      return null
    @onEnter: ->@_onPrefix('enter', arguments...)
    @onLeave: ->@_onPrefix('leave', arguments...)
    @onBefore: ->@_onPrefix('before', arguments...)
    @onAfter: ->@_onPrefix('after', arguments...)
  for ev in ['leave', 'before', 'after', 'enter']
    do (ev) ->
      camel = _.capitalize(ev)
      f_name = 'on' + camel
      TMachine::[f_name] = (event, func, clear) -> @_on(ev, event, func, clear)
      f_name = 'onNext' + camel
      TMachine::[f_name] = (event, func, clear) -> @_on(ev, event, func, clear, true)
      f_name = 'remove' + camel
      TMachine::[f_name] = (event, func) -> @_remove('on' + ev + event, func)
  TMachine
