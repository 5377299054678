import "bootstrap"

TEMPLATE = _.template """
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><%=title%></h5>
      </div>
      <div class="modal-body">
        <%=body%>
        <pre><%=data%></pre>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary"  data-dismiss="modal"><%=continue_btn%></button>
      </div>
    </div>
  </div>
</div>
"""

export default class EndExerciseScreen
  constructor: (parent)->
    @_parent = parent
  show: (results) ->
    total_score = _.sumBy(results, 'score')
    texts =
      title: _t("end_exercise.title")
      body: _t("end_exercise.points", { total_points: total_score })
      "continue_btn": _t("button.continue")
      data: JSON.stringify(results, null, 2)
    @_content = $(TEMPLATE(texts))
    @_parent.append(@_content)
    return new Promise (resolve, reject) =>
      @_reject = reject
      @_content.modal(backdrop: false, keyboard: false)
      @_content.one 'hidden.bs.modal', resolve
  hide: ->
    @_reject()
    @_content.modal('hide')

