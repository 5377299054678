#@coffeescript2
import $ from 'jquery'
import konva from './konva_compat'
import "./_viewport_video.scss"
import { EventEmitterMixin } from "helpers/event_helper"
import { ImageSource } from "./camera"

class CropCancelled extends Error
  constructor: (new_crop)->
    super()
    @new_crop = new_crop

export default class VideoCropper
  CropCancelled: @CropCancelled = CropCancelled
  constructor: (img_source, coord_system, aspect_ratio) ->
    EventEmitterMixin(this, img_source)
    @video = img_source.video
    @aspect_ratio = aspect_ratio ? @video.width / @video.height
    @$video = $(@video)
    @$video.wrap("<div class='video_cropper'></div>")
    $('window').on 'resize', _.debounce(@resize, 100)
    @node = new konva.Group(name: "VideoCropper")
    coord_system?.add(@node)
    @node.add @video_coords = new konva.Group(name: "VideoCoordinates")
    @video_coords.add @_inner = new konva.Group scale: { x: -1, y: 1 }, name: "Inner"
    @_current = { x: 0, y: 0, width: @$video.parent().width(), height: @$video.parent().height() }
    @_flip_horizontally = img_source.flip_horizontally
    @resize()
    img_source.onLoaded(()=>@$video.addClass("initialized"))
    @on ImageSource.EVENTS.size_changed, (size) =>
      if size?
        @resize(video_size: size)

  onChanged: (callback) -> @on 'crop_change', callback
  offChanged: (callback) -> @off 'crop_change', callback
  expectCancelled: (e)->
    if not (e instanceof CropCancelled)
      throw e
  resize: (sizes = {})=>
    if sizes.parent_size?
      width = sizes.parent_size.width
      height = sizes.parent_size.height
    else
      width = @$video.parent().width()
      height = @$video.parent().height()
    if width > 0 and height > 0
      @width = width
      @height = height
    else
      return
    @video_size = sizes.video_size ? {
      height: @video.videoHeight
      width: @video.videoWidth
    }
    @_video_scale = Math.max(@height / @video_size.height, @width / @video_size.width)
    if @video_size.width == 0 or @video_size.height == 0
      @_video_scale = 1
    else
      @_video_scale = Math.max(@height / @video_size.height, @width / @video_size.width)
    scale =
      x: @_video_scale,
      y: @_video_scale
    @_inner.setPosition(
      x: (if @_flip_horizontally then @width else 0),
      y: (@height - @video_size.height * @_video_scale) / 2)
    @_inner.setScale(x: scale.x * (if @_flip_horizontally then -1 else 1), y: scale.y)
    @node.setPosition(x: -(@width / @height) / 2, y: -0.5)
    stretch = (@width / @height) / @aspect_ratio
    @node.setScale(x: 1 / @height * stretch, y: 1 / @height)
    @setCrop(@_current)

  animateTo: (rect, duration = 250) ->
    @_animation?.stop()
    previous = @_animation?.reject
    animating = new Promise (resolve, reject) =>
      start = @_current
      diff = _.mapValues start, (val, key) -> rect[key] - val
      if not _.some(_.values(diff))
        return resolve()
      @_animation = new konva.Animation (frame) =>
        progress = Math.min(frame.time / duration, 1)
        target = _.mapValues start, (val, key) -> val + progress * diff[key]
        @_setCrop(target)
        if progress >= 1
          @_animation.stop()
          resolve()
      @_animation.start()
      @_animation.reject = reject
    if previous?
      previous(new CropCancelled(animating))
    return animating

  setCrop: (rect) ->
    @_setCrop(rect)
    @_animation?.stop()
    @_animation?.reject(new CropCancelled(Promise.resolve()))

  _setCrop: (crop) ->
    @_current = crop
    attrs =
      offsetX: crop.x - (@width - @video_size.width * @_video_scale) / 2
      offsetY: crop.y
      scaleX: @height / crop.height
      scaleY: @height / crop.height
    @video_coords.setAttrs(attrs)
    t = @_inner.getAbsoluteTransform(@node)
    transform = "matrix(#{t.getMatrix().join(',')})"
    @$video.css transform: transform

  transformCrop: (crop, source_node)->
    return konva.Util.transformRect(crop, source_node, @node)

  isDifferent: (rect, threshold)->
    if not @_current?
      return true
    thresholds = {
      x: threshold * @_current.width,
      y: threshold * @_current.height,
      width: threshold * @_current.width,
      height: threshold * @_current.height
    }
    return _.some(_.keys(thresholds), (k) => Math.abs(rect[k] - @_current[k]) > thresholds[k])

  reset: (animate) ->
    rect = { x: 0, y: 0, width: @width, height: @height }
    if animate
      @animateTo(rect)
    else
      @setCrop(rect)

  draw: (context, destination_rect = null, source_rect = null, source_node = null) ->
    if not destination_rect?
      destination_rect = { x: 0, y: 0, height: context.canvas.height, width: context.canvas.width }
    if not source_rect?
      source_rect = { x: -@aspect_ratio / 2, y: -0.5, height: @height, width: @width }
      source_node = @node
    source_rect = konva.Util.transformRect(source_rect, source_node, @_inner)
    context.save()
    if source_rect.width < 0
      context.translate(context.canvas.width, 0)
      context.scale(-1, 1)
    context.drawImage(@video,
      source_rect.x, source_rect.y, source_rect.width, source_rect.height,
      context.canvas.width - destination_rect.x, destination_rect.y,
      -destination_rect.width, destination_rect.height)
    context.restore()

  destroy: ->
    @$video.unwrap()
    @off 'crop_change'
    @node.destroy()

Object.defineProperties(VideoCropper::, {
  size:
    get: ->{ width: @width, height: @height }
  orig_size:
    get: ->{ width: @video.width, height: @video.height }
  camera_resolution:
    get: -> {
      width: Math.min(@video.width, @aspect_ratio * @video.height),
      height: Math.min(@video.height, @video.width / @aspect_ratio)
    }
})
