export normalizeAngle = (angle, degrees = false)->
  full = if degrees then 360 else 2 * Math.PI
  while angle < 0 or angle >= full
    angle += -Math.sign(angle) * full
  return angle
export toRadians = (angle) ->angle * Math.PI / 180
export toDegrees = (angle) ->angle * 180 / Math.PI
export isAngleBetween = (angle, start_angle, angle_width, degrees = false) ->
  angle = normalizeAngle(angle, degrees)
  width = angle_width
  start = start_angle
  if width < 0
    start = start + width
    width = -width
  start = normalizeAngle(start, degrees)
  if angle < start
    angle += if degrees then 360 else 2 * Math.PI
  return angle <= start + width
