# @coffeescript2
import fx from 'glfx'
import {createCanvas} from "helpers/canvas_helper"
import BackgroundSubtractor, {SkipFrame} from "./background_subtractor"

export default class GLBackgroundSubtractor extends BackgroundSubtractor
  constructor: (width, height, threshold)->
    super(width, height)
    @_frames = createCanvas(@width, @height * 2, 'subtractor_frames')
    @_result = createCanvas(@width, @height, 'subtractor_result')
    @_fctx = @_frames.getContext('2d')
    @_rctx = @_result.getContext('2d')
    @_fx = fx.canvas()
    @_texture = @_fx.texture(@_frames)
    @_texture._.gl.skip_check = true
    @_first_frame = true
    @_threshold = threshold ? 0.05
    @_use_draw_pixels = false


  _prepareFrame: (drawImageFunction)->
    @_fctx.drawImage(@_frames, 0, 0, @width, @height, 0, @height, @width, @height)
    await drawImageFunction(@_fctx, 0, 0, @width, @height)

  findForeground: (drawImageFunction) ->
    @_prepareFrame(drawImageFunction)
    if @_first_frame
#this is first frame, so it it should be skipped
      @_first_frame = false
      return Promise.reject(new SkipFrame("First Frame"))
    await @_process()
#    @_process().then ->
#      return new Promise (resolve)->
#        requestAnimationFrame(resolve)

  _process: ->
    try
      @_loadTextureContents()
      @_drawTexture()
      @_processFrame()
      @_drawResult()
      return Promise.resolve()
    catch error
      return Promise.reject(error)

  _loadTextureContents: ->@_texture.loadContentsOf(@_frames)
  _drawTexture: ->@_fx.draw(@_texture)
  _processFrame: ->
    @_fx.diffThreshold(0) #Threshold is mainly for debug purposes
    @_fx.update()
  _drawResult: ->
    @_result_data = null
    @_rctx.drawImage(@_fx, 0, 0)

  _drawPreviousFrame: (drawImageFunction)->
    drawImageFunction(@_fctx, 0, @height, @width, @height)

  _getResultData: ->
    if @_use_draw_pixels
      data =
        data: @_fx.getPixelArray()
        width: @width
        height: @height
    else
      data = @_rctx.getImageData(0, 0, @width, @height)
    if @_dataIsValid(data)
      return data
    else
      throw new SkipFrame("Every pixel was the same in both frames. Probably these are the same frames. ")
  _dataIsValid: (data) ->
    index = 0
    for i in [0...@width * @height]
      if data.data[index] > 0
        return true
      index += 4
    return false
  _getThreshold: (threshold)->
    return (threshold ? @_threshold) * 255
  destroy: ->
    @_frames.remove()
    @_result.remove()

  load: ->
    await @_process()
  clear: ->
    @_first_frame = true
