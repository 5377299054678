# @enable_linter
define [
  'paper',
  'underscore',

  'utils/point',
], (
  paper,
  underscore,

  Point,
) ->
  if !paper.project
    paper.setup(0,0)
    layer = new paper.Layer()
    layer.visible = false

  class Path
    constructor: (path, @res) ->
      if not res?
        @res = { width: 1, height: 1 }
      @ppath = new paper.Path()
      @ppath.style.strokeColor = 'black'
      @setPath(path)

    setPath: (path) ->
      @_area = undefined
      if !_.isString(path)
        if path.path? and not (path.path instanceof Path)
          pathData = path.path
        else
          pathData = path
        if _.isArray(pathData)
          @ppath.pathData = @_oldPathToSvgPath(path)
        else if _.isObject(pathData)
          @ppath.pathData = pathData.data
        else
          @ppath.pathData = pathData
        if pathData.width?
          @ppath.scale(1.0 / pathData.width, 1.0 / pathData.height, [0, 0])

        if path.reversed
          @ppath.reverse()
          if @ppath.closed
            @ppath.insert(0, @ppath.removeSegment(@ppath.segments.length - 1))
        if path.and_return
          @for_display = new Path(@ppath.pathData, @res)
          new_path = @ppath.clone()
          new_path.reverse()
          @ppath.join(new_path)
      else
        @ppath.pathData = path

      @len = @ppath.getLength()

    join: (path) ->
      res =
        width: Math.max(@res.width, path.res.width)
        height: Math.max(@res.height, path.res.height)
      p = @getData() + ' ' + path.getData()
      return new Path(p, res)

    closePath: -> @ppath.closePath()

    isPointInside: (point) -> @ppath.contains(new paper.Point(point.x, point.y))

    pointAtLength: (len) -> new Point(@ppath.getPointAt(if len > @len then @len else len))

    pointAtPercent: (percent) -> @pointAtLength(@len * percent)

    getNormalAt: (len) -> new Point(@ppath.getNormalAt(len))

    pointAndLengthClosestTo: (point) ->
      loc = @ppath.getNearestLocation(new paper.Point(point.x, point.y))
      return { point: new Point(loc.getPoint()), len: loc.curveOffset }

    getScaled: (size) ->
      p = new Path(@ppath.pathData, { width: size.width, height: size.height })
      p.ppath.scale(size.width / @res.width, size.height / @res.height, { x: 0, y: 0 })
      p.len = p.ppath.getLength()
      if @for_display?
        p.for_display = @for_display.getScaled(size)
      return p

    getData: (size) ->
      @ppath.pathData

    getBounds: ->
      @ppath.bounds

    getArea: ->
      @_area ?= @ppath.area

    _oldPathToSvgPath: (path) ->
      data = "M #{path[0].x},#{path[0].y} "
      last = "M"
      for p in path[1..]
        if p.qx?
          if last != "Q"
            data += (last = "Q") + " "
          data += "#{p.qx} #{p.qy} "
        else if p.b1x?
          if last != "C"
            data += (last = "C") + " "
          data += "#{p.b1x} #{p.b1y} #{p.b2x} #{p.b2y} "
        else
          if last != "L"
            data += (last = "L") + " "
        data += "#{p.x} #{p.y} "
      return data
