define ['lodash'], (_) ->
  __safe_callbacks = {}
  window?.__safe_callbacks = __safe_callbacks #this will be cleared in utils/frame
  iframeSafeCallback = (callback)->
    callback_id = _.uniqueId()
    __safe_callbacks[callback_id] = callback
    do (callback_id)->
      return (args...)->
        __safe_callbacks?[callback_id]?()

  class CallQueue
    @iframeSafeCallback = iframeSafeCallback
    constructor: (@context, func) ->
      @_callbacks = []
      if func?
        @connect(func)
      if @context is undefined
        @context = @
      @_single_shot = []
      signal = @
      emit = -> signal.emit arguments...
      emit.connect = -> signal.connect arguments...
      emit.next = -> signal.next arguments...
      emit.add = -> signal.add arguments...
      emit.disconnect = -> signal.disconnect arguments...
      emit.disconnectNext = -> signal.disconnectNext arguments...
      emit.clear = -> signal.clear arguments...
      emit.onConnect = -> signal.onConnect arguments...
      emit.emit = emit

      return emit

    connect: (callback_or_obj, method) ->
      if method?
        callback = (args...) -> callback_or_obj[method](args...)
      else
        callback = callback_or_obj

      @_callbacks.push(callback)
      if @_onConnectMethod
        @_onConnectMethod(callback)

      return @

    onConnect: (callback) ->
      @_onConnectMethod = callback

    next: (callback) ->
      @_single_shot.push(callback)
      return @

    add: (callback) -> @connect(callback)

    _disconnect: (callback, array) ->
      @[array] = if callback? then _.without(@[array], callback) else []
      return @

    disconnect: (callback) -> @_disconnect(callback, '_callbacks')

    disconnectNext: (callback) -> @_disconnect(callback, '_single_shot')

    emit: ->
      ss = @_single_shot
      @_single_shot = []
      for callback in ss.concat(@_callbacks)
        callback.apply(@context, arguments)

    clear: -> @disconnect()

    @install: (obj, fname, func = null, next = false) ->
      old = null
      if not (obj[fname]?.add?)
        obj[fname] = new this(obj, obj[fname])
      if func?
        if next
          obj[fname].next func
        else
          obj[fname].add func
