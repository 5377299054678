export default SHAPES = {
  Square: {group: "squares", complexity: "low", slices: "vdh", id: 0},
  Triangle: {group: "triangles", complexity: "low", slices: "v", id: 1},
  Circle: {group: "round", complexity: "low", slices: "vdh", id: 2},
  Stop: {group: "round", complexity: "low", slices: "vdh", id: 3},
  Star: {group: "star", complexity: "high", slices: "v", id: 4},
  House: {group: "triangles", complexity: "medium", slices: "v", id: 5},
  Heart: {group: "triangles", complexity: "low", slices: "v", id: 6},
  Frilled: {group: "round", complexity: "low", slices: "vdh", id: 7},
  CardSpade: {group: "triangles", complexity: "medium", slices: "v", id: 8},
  Hexagram: {group: "round", complexity: "low", slices: "vdh", id: 9},
  Diamond: {group: "squares", complexity: "medium", slices: "v", id: 10},
  Teardrop: {group: "triangles", complexity: "medium", slices: "v", id: 11},
  Shield: {group: "round", complexity: "medium", slices: "v", id: 12},
  Bone: {group: "bones", complexity: "high", slices: "vh", id: 13},
  Trapeze: {group: "squares", complexity: "low", slices: "v", id: 14},
  Butterfly: {group: "star", complexity: "high", slices: "v", id: 15},
  Trefoil: {group: "star", complexity: "medium", slices: "v", id: 16},
  Semicircle: {group: "triangles", complexity: "low", slices: "v", id: 17},
  Hexagon: {group: "squares", complexity: "low", slices: "vdh", id: 18}
  SquareDonut: {group: "squares", complexity: "medium", slices: "vdh", id: 19}
  Cross: {group: "star", complexity: "high", slices: "vdh", id: 20}
  Donut: {group: "round", complexity: "medium", slices: "vdh", id: 21}
  Quatrefoil: {group: "round", complexity: "medium", slices: "vdh", id: 22}
  Sparkle: {group: "star", complexity: "medium", slices: "vdh", id: 23}
  Tree: {group: "bones", complexity: "high", slices: "h", id: 24}
  DiamondDonut: {group: "squares", complexity: "medium", slices: "v", id: 25}
  QuaterfoilDonut: {group: "round", complexity: "medium", slices: "vdh", id: 26}
  StopDonut: {group: "round", complexity: "medium", slices: "vdh", id: 27}
  TrapezeDonut: {group: "squares", complexity: "medium", slices: "v", id: 28}
  Arrow: {group: "bones", complexity: "high", slices: "vh", id: 29}
  DumbellHalfRound: {group: "bones", complexity: "high", slices: "vh", id: 30}
  DumbellPuzzle: {group: "bones", complexity: "high", slices: "vh", id: 31}
  DumbellRound: {group: "bones", complexity: "high", slices: "vh", id: 32}
  DumbellSquare: {group: "bones", complexity: "high", slices: "vh", id: 33}
  HouseRound: {group: "triangles", complexity: "low", slices: "v", id: 34}
  HouseTeardrop: {group: "triangles", complexity: "medium", slices: "v", id: 35}
  StarEight: {group: "star", complexity: "high", slices: "vdh", id: 36}
  StarTen: {group: "star", complexity: "high", slices: "vdh", id: 37}
  TreeBig: {group: "bones", complexity: "high", slices: "h", id: 38}
  Vase: {group: "squares", complexity: "low", slices: "v", id: 39}
}
