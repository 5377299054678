import PositionGenerator from "exercises/config_generators/position_generator"
import { areaName, AREAS } from "../exercise_descriptor"
import { pick } from "lodash"
import { PredefinedArea } from "exercises/config_generators/area"
import rand from "utils/rand"

class MachineArea extends PredefinedArea {
  constructor(name, config, probability) {
    const offset = name[0] === "b" ? 4 : 0
    const positions = new Array(4).fill(0).map((_, i) => ({
      s: name[1],
      x: i % 2,
      y: Math.floor((offset + i) / 2),
      i: offset + i,
    }))
    super(
      name,
      {
        positions: positions,
        start_point: { x: 0, y: 0 },
      },
      probability
    )
    this.positions.forEach((p, i) => Object.assign(p, positions[i]))
  }

  getAvailablePositions(occupiedPositions) {
    return this.positions.filter(
      (p, i) =>
        !(
          occupiedPositions.includes(p) ||
          (p.x === 1 ? occupiedPositions.includes(this.positions[i - 1]) : false)
        )
    )
  }

  getOppositeAreas(areas) {
    const names = ["t", "b"].map((t) => t + this.other.x).filter((n) => areas[n] != null)
    return pick(areas, names)
  }

  getOpposite(areas) {
    console.log(this.side, this.other, areas)
    return areas[this.side.y + this.other.x]
  }

  getRandomOpposite(areas) {
    areas = this.getOppositeAreas(areas)
    return areas[rand.weightedObj(_.mapValues(areas, "prob"))]
  }

  getPosition(pos, type) {
    return rand.element(
      this.positions.filter((p) => p.y === pos.y && (type === "mirror" ? p.x === pos.x : true))
    )
  }
}

export class MachinePositionGenerator extends PositionGenerator {
  getPositions(targetType, nonTargetsNum) {
    const area = this.getAreaForTarget()
    this.areaChosen(area)
    const pos = rand.element(area.getAvailablePositions([]))
    let otherArea = null
    let other = null
    if (targetType === "mirror" || targetType === "row") {
      otherArea = area.getOpposite(this._areas)
      if (otherArea) other = otherArea.getPosition(pos, targetType)
      console.log(targetType, pos, other, otherArea)
    }
    if (otherArea == null) {
      otherArea = area.getRandomOpposite(this._areas)
      other = rand.element(otherArea.getAvailablePositions([]))
    }
    this.areaChosen(otherArea)
    const result = [pos, other]
    while (result.length < nonTargetsNum + 2) {
      const nArea = this.getRandomArea()
      const nPos = rand.element(nArea.getAvailablePositions(result))
      if (nPos) result.push(nPos)
    }
    return result
  }
}

Object.assign(MachinePositionGenerator.prototype, {
  AREA_CLASS: MachineArea,
})
