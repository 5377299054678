import ExerciseDescriptor, { AREAS } from "../exercise_descriptor"
import PATHS_LEVELS, { TEST_LEVELS } from "./paths_levels"
import PathsConfigGenerator from "./paths_config_generator"

export default class PathsDescriptor extends ExerciseDescriptor
  scene_name: "Synchro"
  exercise_id: "paths"
  levels: PATHS_LEVELS.length
  instructions: [
    { video: { slides: "paths" } },
    { hint: _tnoop("hints:paths.instruction") }
  ]
  LEVELS_CONFIG: PATHS_LEVELS
  TEST_LEVELS: TEST_LEVELS
  GeneratorClass: PathsConfigGenerator
  available_areas: [
    ['tl', 'tr', 'bl', 'br'],
#    ['tl', 'tr'],
#    ['bl', 'br'],
#    ['tl', 'br'],
#    ['bl', 'tr'],
  ]
  _calculateResults: (run_config, results)->
    results.correctness_score = results.score / results.max_score
    results.avg_trial_duration = results.duration / results.max_score
    results.speed_score = results.max_score / results.expected_points
    return results

  getExpectedPoints: (run_config)->
    conf = @_getLevelConfig(run_config)
    duration = run_config.round_duration - 2 * @GeneratorClass::TRIAL_START_ANIMATION_DURATION
    return 0.9 * Math.min(duration, conf.duration)
