import Rect from 'helpers/rect'
import Segment from "helpers/segment"
import Vector from "utils/vector"
import Point from "utils/point"
import rand from "utils/rand"
import { isAngleBetween, toRadians, toDegrees } from "helpers/angle"

export class Area
  MAX_LEN: 2
  constructor: (name, start_point, probability)->
    @name = name
    @side =
      x: name[1],
      y: name[0]
    @other =
      x: if name[1] == 'l' then 'r' else 'l',
      y: if name[0] == 't' then 'b' else 't'
    @prob = probability
    @start_point = new Point(start_point)
    @angle_range =
      min: Number.POSITIVE_INFINITY
      max: Number.NEGATIVE_INFINITY
  getCenter: ->
  intersects: (point, margin)->
  getRaySegment: (angle)->
    vector = (new Vector(@MAX_LEN, 0)).rotate(@center_angle + angle)
    return new Segment(@start_point, vector.add(@start_point))
  getRandomAngle: ->
    return @angle_range.min + Math.random() * (@angle_range.max - @angle_range.min)
  getRandomRay: ()->@getRaySegment(@getRandomAngle())
  intersectWitRay: (ray_segment)->

export class RectArea extends Area
  constructor: (name, conf, prob)->
    super(name, conf.start_point, prob)
    @rect = new Rect(_.pick(conf, 'x', 'y', 'width', 'height'))
    @center_angle = Vector.fromPoints(@start_point, @getCenter()).angle()
    if @intersects(@start_point, 0)
      @angle_range =
        min: 0
        max: Math.PI * 2
    else
      for p in [@rect.top_left, @rect.top_right, @rect.bottom_left, @rect.bottom_right]
        angle = Vector.fromPoints(@start_point, p).angle() - @center_angle
        if Math.abs(angle) > Math.PI
          angle -= Math.sign(angle) * 2 * Math.PI
        @angle_range.min = Math.min(@angle_range.min, angle)
        @angle_range.max = Math.max(@angle_range.max, angle)
    @_segments = [
      new Segment(@rect.top_left, @rect.top_right),
      new Segment(@rect.top_right, @rect.bottom_right),
      new Segment(@rect.bottom_right, @rect.bottom_left),
      new Segment(@rect.bottom_left, @rect.top_left),
    ]
  getCenter: -> @rect.center

  intersects: (pos, dist = 0) ->
    return @rect.containsPoint(pos, dist)

  intersectWithRay: (ray_segment)->
    points = []
    for segment in @_segments
      intersection = ray_segment.intersection(segment)
      if intersection == 'colinear'
        return segment
      else if intersection?.x
        points.push intersection
    if points.length == 1
      return new Segment(@start_point, points[0])
    else if points.length == 2
      segment = new Segment(points[0], points[1])
      if points[0].distance2(@start_point) > points[1].distance2(@start_point)
        return segment.reverse()
      else
        return segment
    else if points.length == 0
      return null
    else
      throw Error("More then 2 intersection points")
    return points

class ArcSegment
  constructor: (center, diameter, angles)->
    @diameter = diameter
    @center = new Point(center)
    @angles = angles

  intersection: (segment)->
#https://stackoverflow.com/a/1084899/12743196
    d = Vector.fromPoints(segment.start, segment.end)
    f = Vector.fromPoints(@center, segment.start)
    r = @diameter
    a = d.dot(d)
    b = 2 * f.dot(d)
    c = f.dot(f) - r * r
    discriminant = b * b - 4 * a * c;
    if discriminant < 0
      return null
    t1 = (-b - discriminant) / (2 * a);
    t2 = (-b + discriminant) / (2 * a);

export class ArcArea extends Area
  constructor: (name, conf, prob)->
    super(name, conf.start_point, prob)
    _.extend this, _.pick(conf, 'angle', 'radius')
    if @angle.width?
      @angle.end = @angle.start + @angle.width
    else
      @angle.width = @angle.end - @angle.start
    @radius.width = @radius.outer - @radius.inner
    @center_angle = (@angle.start + @angle.end) / 2
    @angle_range.min = @angle.start - @center_angle
    @angle_range.max = @angle.end - @center_angle


  getCenter: ->
    length = (@radius.inner + @radius.outer) / 2
    point = @start_point(x: length, y: 0)
    angle = (@angle.start + @angle.end) / 2
    return point.rotateAroundOrigin(@start_point, toRadians(angle))

  intersects: (point, margin) ->
    vector = Vector.fromPoints(@start_point, point)
    len = vector.length()
    if len < @radius.inner - margin or len > @radius.outer + margin
      return false
    angle = vector.angle()
    return angle >= @angle.start and angle <= @angle.end

  intersectWithRay: (ray_segment) ->
    if ray_segment.start.x != @start_point.x or ray_segment.start.y != @start_point.y
      throw Error("Not Supported yet")
    else
      vector = Vector.fromPoints(ray_segment.start, ray_segment.end)
      angle = toDegrees(vector.angle())
      if isAngleBetween(angle, @angle.start, @angle.width, true)
        vector.setLength(@radius.inner)
        start = ray_segment.start.add(vector)
        vector.setLength(@radius.outer)
        return new Segment(start, ray_segment.start.add(vector))
    return null

  getRandomPosition: ->
    ray = @getRandomRay()
    ray.setLength(@radius.inner + Math.random() * @radius.width)
    return ray.end
  getRaySegment: (angle)->
    vector = (new Vector(@MAX_LEN, 0)).rotate(toRadians(@center_angle + angle))
    return new Segment(@start_point, vector.add(@start_point))

  getOptimalPositions: (margin) ->
    radius = (@radius.inner + @radius.outer) / 2
    half_margin = margin / 2
    side_len = Math.sqrt(radius * radius - half_margin * half_margin)
    half_angle = toDegrees(Math.atan2(half_margin, side_len))
    angle = 0
    positions = []
    total = Math.floor(Math.abs(@angle.width) / (half_angle * 2)) - 1
    width = total * half_angle * 2
    margin = @angle.width - width
    start = @angle.start + margin / 2
    while Math.abs(angle) <= Math.abs(width)
      vector = (new Vector(radius, 0)).rotate(toRadians(start + angle))
      positions.push @start_point.add(vector)
      angle += Math.sign(width) * 2 * half_angle
    return positions

export class PredefinedArea extends Area
  constructor: (name, conf, prob) ->
    super(name, conf.start_point, prob)
    @positions = (new Point(p) for p in conf.positions)

  getRandomPosition: ->
    return rand.element(@positions)
