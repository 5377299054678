import {api} from "api"
import {throttle, defer} from "lodash"
import {addSeconds} from "date-fns"

export default class Launch
  @create: (data, root)->
    root ?= api
    return new Launch(root.create(data), root)

  constructor: (data = {}, root)->
    if data.then?
      @_loaded = data.then @_load
    else
      @_loaded = Promise.resolve(@_load(data))
    if root?
      @root = root
    @_stats = []
    @_start = Date.now()
    @_children = []
    @_saveStats = throttle(@_saveStats, 3000)

  _load: (data)=>
    if data.data?
      data = data.data
    _.extend this, data
    @_api ?= @root.detail(@id)
    return this

  load: ->@_loaded
  refresh: -> @_api.get().then(@_load)
  create: (data)->
    load = @_loaded.then =>
      if _.isNumber(data.start)
        data.start = addSeconds(new Date(@start), data.start)
      @_api.createChild(data)
    child =  new Launch(load, @root)
    @_children.push(child)
    return child

  update: (data)->
    await @_loaded
    @_api.update(data)

  addRecording: (recording)->
    form_data = new FormData()
    form_data.append("type", recording.type ? "game_play")
    if recording.duration
      form_data.append("duration", recording.duration)
    form_data.append("file", recording.blob, recording.name)
    result = await @_api.recording(form_data)
    return result.json()

  addStatistics: (stats)->
    if _.isPlainObject(stats)
      stats = @_flattenStats(stats, "", 2)
    @_stats.push(stats...)
    return @_saveStats()

  getOffset: (timestamp)-> ((timestamp ? Date.now()) - @_start) / 1000.0

  _createStat: (type, value, timestamp)->
    stat =
      type: type,
      offset: @getOffset(timestamp)
    if _.isNumber(value)
      stat.value = value
    else
      stat.data = value
    return stat

  addStat: (type, value, timestamp)->
    @_stats.push @_createStat(type, value, timestamp)
    if (@autosave)
      defer(@_saveStats)

  _saveStats: =>
    stats = @_stats
    @_stats = []
    await @_loaded
    try
      response = await @_api.statistics(stats)
    catch e
      @_stats = [stats..., @_stats...]
      throw e
    return response.data

  _flattenStats: (stats_obj, prefix = "", max_depth = 0)->
    stats = []
    for name,value of stats_obj
      if _.isPlainObject(value) and max_depth > 0
        stats.push(@_flattenStats(value, prefix + name + '.', max_depth - 1)...)
      else
        stats.push @_createStat(prefix + name, value)
    return stats

  markEnd: ->
    @duration = (Date.now() - @_start) / 1000.00
    await @_loaded
    if @_stats.length
      @_saveStats()
    @_api.update(duration: @duration)

class MockLaunch
  create: ->new MockLaunch()
  addStat: ->
  addStatistics: ->
  load: ->Promise.resolve()
  refresh: ->@load()
  addRecording: ->Promise.resolve()
  markEnd: ->
Launch.Mock = MockLaunch
