#@coffeescript2
import UnityLoader from './UnityLoader'
import CustomEventEmitter from "../helpers/event_helper"
import calibration_config from "Assets/Calibration/Resources/calibration_config.json"
import { openDB } from "idb"

to_clear = [
  {
    "db": "/idbfs",
    "table": "FILE_DATA",
    "key_filter": /UnityCache|com\.unity\.addressables|Unity\//
  }, {
    db: "CachedXMLHttpRequest",
    table: "cache",
    "key_filter": /.*/
  }
]

databaseExists = (dbname, callback) =>
  req = indexedDB.open(dbname)
  existed = true;
  req.onsuccess = () =>
    req.result.close()
    if (!existed)
      indexedDB.deleteDatabase(dbname)
    callback(existed)
  req.onupgradeneeded = => existed = false;

clearCache = ()=>
  for conf in to_clear
    exists = await new Promise((resolve)=> databaseExists(conf.db, resolve))
    if !exists
      continue
    try
      db = await openDB(conf.db)
      keys = await db.getAllKeys(conf.table)
      tx = db.transaction(conf.table, "readwrite")
      for k in keys
        if conf.key_filter.test(k)
          console.debug("Deleting", conf.table, k)
          await tx.store.delete(k)
      await tx.done
      await db.close()
    catch err
      console.warning("DB error", err)
  console.log("CLEAR CACHE DONE")


clearCacheIfNeeded = (new_version)=>
  if localStorage.unity_version != new_version
    await clearCache()
    localStorage.unity_version = new_version

UnityLoader.Error.handler = ->return
class UnityMockInstance
  LOADING_DURATION = 1000
  constructor: (on_progress, container_id) ->
    @_on_progress = on_progress
    @_container = $("#" + container_id).addClass('mock')
    @_canvas = $("<canvas>")
    @_canvas.appendTo(@_container)
    @mockCommunication()
    @_scene_name = "LoadingScene"
    @Module =
      canvas: @_canvas[0]
      setCanvasSize: (width, height)->
        @canvas.width = width
        @canvas.height = height
      pauseMainLoop: ->
      resumeMainLoop: ->
    @_calib_log = []
    @_running = false

  _sleep: (timeout) ->
    new Promise((resolve) -> setTimeout(resolve, timeout * 1000))

  mockCommunication: =>
    cur = 0
    while cur <= LOADING_DURATION
      @_on_progress(this, cur / LOADING_DURATION)
      cur += 100
      await @_sleep(0.1)
    @_backend = window._frontend_bridge.frontendReady("MockFrontend")
    await @_sleep(0.1)
    while not @_backend.isReady()
      await @_sleep(0.1)

  printMessage: (args) ->
    line = ""
    line += " " + a for a in args
    @_container.append("#{line}\n")
    console.log args...

  SendMessage: (element_id, method, args...) ->
    if method != '_SetCalibrationState'
      @printMessage(arguments)
    @[method]?(args...)

  _StartRound: ->
    if @_config.round_duration > 0
      setTimeout(@_mockFinish, @_config.round_duration * 1000)
    @_running = true
    for element in @_config.scenario
      await @_sleep(0.5)
      if not @_running
        return
      rect =
        x: element.position.x
        y: -element.position.y
        width: element.scale * 0.1
        height: element.scale * 0.1
      @_backend.watchRect(element.id, rect)

  _mockFinish: =>
    @_running = false
    @_backend.gameFinished(JSON.stringify(score: 1, duration: 1, max_score: 1))
  _SetConfig: (config) ->
    @_config = JSON.parse(config)

  _LoadScene: (scene_name) ->
    @_scene_name = scene_name
    @_backend.frontendReady("MockFrontend")

  _GetSceneName: (result_id)->
    @_backend._sendResult(result_id, @_scene_name)

  _GetTestConfig: (result_id)->
    @_backend._sendResult(result_id, JSON.stringify(calibration_config))

  _SetCalibrationState: (calib_state) ->
#    @_calib_log.push(time: Date.now(), state: JSON.parse(calib_state))
#    sessionStorage.calib_log = JSON.stringify(@_calib_log)
  _CaptureFrame: ->
    @_backend._frameReady()

export default class FrontendBridge extends CustomEventEmitter
  constructor: (container_id, unity_config) ->
    super()
    @_container_id = container_id
    @_unity_config = unity_config
    @_backend_ready = false
    @_results = {}
    @suspended = false

  setConfig: (config) ->
    @current_config = config
    data = JSON.stringify(config)
    sessionStorage.current_config = data
    @unityInstance.SendMessage(@_backend_id, '_SetConfig', data)
  pause: ->
    @unityInstance.SendMessage(@_backend_id, '_Pause')
  unpause: ->
    @unityInstance.SendMessage(@_backend_id, '_Unpause')
  start: ->
    try
      @unityInstance.SendMessage(@_backend_id, '_StartRound')
    catch
      @unityInstance.SendMessage(@_backend_id, '_Play')
  stop: ->
    @unityInstance.SendMessage(@_backend_id, '_Stop')
  onDetection: (rect_id) ->
    @unityInstance.SendMessage(@_backend_id, '_OnDetection', rect_id)
  onDetectionLost: (rect_id) ->
    @unityInstance.SendMessage(@_backend_id, '_OnDetectionLost', rect_id)
  changeConfig: (element_config) ->
    console.log "Change config", JSON.stringify(element_config)
    @unityInstance.SendMessage(@_backend_id, '_ChangeConfig', JSON.stringify(element_config))

  _sendResult: (id, json_result)->
    try
      result = JSON.parse(json_result)
    catch
      result = json_result
    @_results[id](result)
    delete @_results[id]

  _getter: (name)->
    id = _.uniqueId()
    return new Promise (resolve, reject) =>
      @_results[id] = resolve
      @unityInstance.SendMessage(@_backend_id, name, id)
  getTestConfig: ->@_getter('_GetTestConfig')
  getSceneName: ->@_getter('_GetSceneName')

  loadScene: (scene_name)->
    return new Promise (resolve) =>
      console.log "Load Scene", scene_name
      @one 'scene_loaded', =>
        console.log "Scene Loaded"
        if @suspended
          @unityInstance.Module.pauseMainLoop()
        resolve()
      if @suspended
        @unityInstance.Module.resumeMainLoop()
      @unityInstance.SendMessage(@_backend_id, '_LoadScene', scene_name)

  _on_progress: (instance, progress) =>
    @emit('load_progress', progress)

  captureFrame: (callback)->
    @_results.frameReady = callback
    @unityInstance.SendMessage(@_backend_id, '_CaptureFrame')

  _frameReady: ->
    @_results.frameReady()

  _getConfigUrl: ->
    return @_unity_config + "?version=#{UNITY_EXERCISES_VERSION}"

  load: ->
    if @unityInstance
      return @_frontend_ready ? Promise.resolve(this)
    window._frontend_bridge = this
    new Promise (resolve, reject) =>
      config_url = @_getConfigUrl()
      await clearCacheIfNeeded(config_url)
      @unityInstance = @_createInstance(config_url)
      @_frontend_ready = resolve

  frontendReady: (@_backend_id) ->
    console.log "Frontend Ready"
    @_last_calib_state = null
    if @_frontend_ready
      @unityInstance.Module.canvas.controlTransferredOffscreen = true
      @unityInstance.Module.canvas.setAttribute('tabindex', '99')
      container = $("#" + @_container_id)
      @setSize(container.width(), container.height())
      @_frontend_ready(this)
      @_frontend_ready = null
    else
      @emit('scene_loaded')
    return this

  _createInstance: (config_url) ->
    UnityLoader.Error.handler = (e, t) ->
      console.log e, t
    org_popup = UnityLoader.Error.popup
    UnityLoader.Error.popup = (i, e, t) ->
      if e.includes("Press OK if you wish to continue anyway.")
        console.log "Continue"
        t[0].callback()
      else
        org_popup(i, e, t)
    window.UnityLoader = UnityLoader
    config = {
      Module: {
        webglContextAttributes:
          preserveDrawingBuffer: false
#          premultipliedAlpha: false
#          alpha: false
#          preserveDrawingBuffer: true
#          antialias: true
      }
      onProgress: @_on_progress
    }
    UnityLoader.instantiate(@_container_id, config_url, config)

  setSize: (width, height, parent_size)->
    width = Math.ceil(width)
    height = Math.ceil(height)
    @unityInstance.Module.setCanvasSize(width, height)
    container = $("#" + @_container_id)
    parent_size ?=
      width: container.width()
      height: container.height()
    $(@unityInstance.Module.canvas).css
      transform: "scale(#{ parent_size.width / width},#{ parent_size.height / height})"

  gameFinished: (result) ->
    try
      result = JSON.parse(result)
    catch
    @emit('finished', result)
  gamePaused: -> @emit('paused')
  watchRect: (rect_id, rect)-> @emit('watch_rect', rect_id, rect)
  stopWatchingRect: (rect_id)->@emit('stop_watching_rect', rect_id)
  gameEvent: (event)-> @emit('game_event', event)
  isReady: -> @_backend_ready
  backendReady: ->@_backend_ready = true
  suspend: ->
    @suspended = true
    @unityInstance.Module?.pauseMainLoop()
  resume: ->
    @suspended = false
    @unityInstance.Module?.resumeMainLoop()
    @unityInstance.Module?.canvas.focus()
  rectChanged: (rect_id, detection) =>
    if detection
      @onDetection(rect_id)
    else
      @onDetectionLost(rect_id)

  clear: ->
    @stop()
    super()

  HINT_ENUM =
    unknown: 0,
    backward: 1 << 0,
    forward: 1 << 1,
    left: 1 << 2,
    right: 1 << 3,
    tilt_up: 1 << 4,
    tilt_down: 1 << 5,
    correct: 1 << 6,
    dont_move: 1 << 7,
    hands_up: 1 << 8,
    hands_down: 1 << 9,

  PHASE_ENUM =
    unknown: 0,
    calibrating: 1,
    cropping: 2,
    detecting_movement: 3
    finding_range: 4
    target_practice: 5
    finished: 6
  setCalibrationState: (state) ->
    data = _.cloneDeep(state)
    if data.hint?
      data.hint.type = HINT_ENUM[data.hint.type]
    if data.face?
      data.face.y = -data.face.y
      data.face.rotation = -data.face.rotation
    if data.target?
      data.target.y = -data.target.y
    data.phase = PHASE_ENUM[data.phase]
    string_data = JSON.stringify(data)
    if string_data != @_last_calib_state
      @unityInstance.SendMessage(@_backend_id, '_SetCalibrationState', string_data)
      @_last_calib_state = string_data

  assetsUrl: ->
    config = @_unity_config
    url = new URL(config + '/../../assets', document.baseURI)
    return url.href

Object.defineProperties(FrontendBridge::, {
  canvas:
    get: ->@unityInstance.Module.canvas
})

class FrontendMockBridge extends FrontendBridge
  mock: true
  _createInstance: ->
    instance = new UnityMockInstance(@_on_progress, @_container_id)
  loadCalibrationScene: ->
    await @load()
    await @loadScene('CalibrationScene')
    calib_config = await @getTestConfig()
    await @setConfig(calib_config)

FrontendBridge.Mock = FrontendMockBridge
