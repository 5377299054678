import Point from "utils/point"
import {checkIntersection} from "line-intersect"

export default class Segment
  constructor: (start, end)->
    @start = new Point(start)
    @setEnd(end)

  intersection: (other) ->
    intersection = checkIntersection(@start.x, @start.y, @end.x, @end.y, other.start.x, other.start.y, other.end.x, other.end.y)
    if intersection.type == 'intersecting'
      return new Point(intersection.point)
    else if intersection.type == 'colinear' and other.length_sq == 0
      return other.start
    else if intersection.type == 'none'
      return null
    else
      return intersection.type

  perpendicularSegmentAtPoint: (point)->
    if @_dx == 0 and @_dy == 0
      throw Error("Segment has zero length")
    slope = 1 / @slope
    b = point.y - point.x * slope
    if slope == Infinity or slope == -Infinity
      return new Segment({x: point.x, y: -Number.MAX_SAFE_INTEGER}, {x: point.x, y: +Number.MAX_SAFE_INTEGER})
    else
      return new Segment({x: @start.x, y: slope * @start.x + b},
                         {x: @end.x, y: slope * @end.x + b})

  perpendicularIntersection: (point) ->
    other = @perpendicularSegmentAtPoint(point)
    return @intersection(other)

  setLength: (length)->
    if @_dx == 0
      @setEnd(x: @start.x, y: @start.y + Math.sign(@_dy) * length)
    else
      dx = Math.sign(@_dx) * Math.sqrt(length * length / (1 + @slope * @slope))
      @setEnd(x: @start.x + dx, y: @start.y + dx * @slope)

  setEnd: (point)->
    @end = new Point(point)
    @_dx = (@end.x - @start.x)
    @_dy = (@end.y - @start.y)
    @slope = @_dy / @_dx
    delete @_length_sq
    delete @_length

  reverse: ()->
    return new Segment(@end, @start)

  commonSegment: (other) ->
    if @_dx == 0
      a = 'y'
    else
      a = 'x'
    other_min = Math.min(other.start[a], other.end[a])
    other_max = Math.max(other.start[a], other.end[a])
    min = Math.min(@start[a], @end[a])
    max = Math.max(@start[a], @end[a])
    start = Math.max(min, other_min)
    end = Math.min(max, other_max)
    if end >= start
      if @start[a] > @end[a]
        [start, end] = [end, start]
      if @_dx == 0
        return new Segment({x: @start.x, y: start}, {x: @start.x, y: end})
      return new Segment({x: start, y: @start.y + (start - @start.x) * @slope},
                         {x: end, y: @start.y + (end - @start.x) * @slope})
    return null
  contains: (point) ->
    if @_dx == 0
      return @start.x == point.x and Math.min(@start.y, @end.y) <= point.y <= Math.max(@start.y, @end.y)
    else
      return Math.abs(@start.y + (point.x - @start.x) * @slope - point.y) < 0.000000001

  randomPositionOnCommonSegment:(other)->
    available_s_before_touching = @commonSegment(other)
    if available_s_before_touching
      available_s_before_touching.setLength(available_s_before_touching.length * Math.random())
      return available_s_before_touching.end
    return null

Object.defineProperties(Segment::, {
  length:
    get: ->@_length ?= Math.sqrt(@length_sq)
  length_sq:
    get: ->@_length_sq ?= @_dx * @_dx + @_dy * @_dy
})
