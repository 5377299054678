import "./_loader.scss"
import load_screen_html from "./game_loader_ui.html"
import logo_png from "runner/images/logo.png"

TEMPLATE = _.template load_screen_html


export default class GameLoaderUi
  constructor: (@_parent) ->

  waitForButton: (button_text) ->
    img = @_container.find('img')
    offset = img.offset()
    img.css(top: offset.top + 'px', left: offset.left + 'px')
    _.defer =>
      @_container.addClass('show_buttons')
    #      img.css(top: "", left: "")
    button = @_container.find('#start_demo')
    button.html(button_text ? _t("start_demo"))
    return new Promise (resolve) => @_container.find('#start_demo').one 'click', resolve

  setPercents: (percent)=>
    @_content?.find('.progress-bar').css(width: "#{percent * 100}%")

  show: (options)->
    if options.categories
      categories = ("""<span class="badge badge-pill badge-secondary exercise-category">
<div class="pill-circle bg-#{category.color}"></div>#{category.name}</span>""" for category in options.categories)
    else
      categories = []
    texts =
      name: options.name ? "Exercise Name"
      image: options.image ? logo_png
      categories: categories.join("")
      loading: _t("loading")
    @_content = $(TEMPLATE(texts))
    @_parent.append(@_content)
    return new Promise (resolve, reject) =>
      @_reject = reject
      @_content.one 'hidden.bs.modal', =>
        resolve()
        setTimeout((=>@_content.remove()), 500)
      @_content.modal(backdrop: false, keyboard: false)
  hide: ->
    @_reject()
    @_content.modal('hide')
  isVisible: ->
    @_content.hasClass('show')
