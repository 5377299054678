import BoxingConfigGenerator from "../boxing/boxing_config_generator"
import CHESTNUTS_LEVELS from "./chestnuts_levels"
import BoxingDescriptor from "../boxing/boxing.desc"

class ChestnutsConfigGenerator extends BoxingConfigGenerator
  BAGS: BAGS = {
    "left": {
      "x": -0.80,
      "y": -0.35,
      "width": 0.3,
      "height": 0.8
    },
    "right": {
      "x": 0.55,
      "y": -0.35,
      "width": 0.3,
      "height": 0.8
    }
  }
  AREAS: BoxingConfigGenerator.generateAreas(BAGS.right, BAGS.left)

  @getPunchingBags: ->
    bags = {
      "left": {"x": -1.3, "y": -0.5, "width": 1, "height": 1}
      "right": {"x": 0.3, "y": -0.5, "width": 1, "height": 1}
    }
    bags.left.y = -bags.left.y
    bags.right.y = -bags.right.y
    return bags

export default class ChestnutsDescriptor extends BoxingDescriptor
  scene_name: "Chestnuts"
  exercise_id: "chestnuts"
  levels: CHESTNUTS_LEVELS.length
  instructions: [
    {video: {slides: "chestnuts"}}
    {hint: _tnoop("hints:chestnuts.instruction")}
  ]
  GeneratorClass: ChestnutsConfigGenerator
