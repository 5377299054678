#@coffeescript2
import * as faceapi from 'face-api.js'
import TFFaceDetectorBackend from "./tf_face_detector_backend"
import loadOpenCV from "../opencv"

MODEL_URL = TFFaceDetectorBackend.MODEL_URL
HAAR_CASCADE = 'haarcascade_frontalface_default.xml'
loadOpenCVFile = (path) ->
  data = (await Promise.all([
    loadOpenCV(),
    fetch(MODEL_URL + '/' + path).then((response)=>response.arrayBuffer())
  ]))[1]
  data = new Uint8Array(data)
  cv.FS_createDataFile('/', path, data, true, false, false)

SCALE_Y = 1.25
export default class CVFaceDetectorBackend extends TFFaceDetectorBackend
  defaults:
    scale_factor: 1.1
    min_neighbours: 3
    flags: 0

  load: ->
    await Promise.all([
      faceapi.loadFaceLandmarkModel(TFFaceDetectorBackend.MODEL_URL)
      loadOpenCVFile(HAAR_CASCADE)
    ])
    @_face_cascade = new cv.CascadeClassifier()
    @_faces = new cv.RectVector()
    @_face_cascade.load(HAAR_CASCADE)

  prepareForSize: (width, height, img_data)->
    img_data ?= new ImageData(width, height)
    await @detectAll(img_data)

  _detectFaces: (canvas) ->
    width = canvas.width
    height = canvas.height
    image_data = canvas.getContext('2d').getImageData(0, 0, width, height)
    src = cv.matFromImageData(image_data)
    gray = new cv.Mat()
    cv.cvtColor(src, gray, cv.COLOR_RGBA2GRAY, 0)
    src.delete()
    @_face_cascade.detectMultiScale(gray, @_faces, @_options.scale_factor, @_options.min_neighbours, @_options.flags)
    gray.delete()
    result = []
    for i in [0...@_faces.size()]
      face = @_faces.get(i)
      new_face =
        x: face.x,
        y: face.y,
        width: face.width,
        height: face.height * SCALE_Y
      result.push new_face
    return result

  detectAll: (image_data)->
    canvas = @_getCanvas(image_data)
    faces = @_detectFaces(canvas)
    rects = (new faceapi.Rect(face.x, face.y, face.width, face.height) for face in faces)
    canvases = await faceapi.extractFaces(canvas, rects)
    return Promise.all _.map canvases, (canvas, i) ->
      faceapi.detectFaceLandmarks(canvas).then (landmarks) ->
        landmarks = landmarks.shiftBy(faces[i].x, faces[i].y)
        box = landmarks.align()
        relative_box = new faceapi.Rect(box.x / landmarks.imageWidth,
                                        box.y / landmarks.imageHeight,
                                        box.width / landmarks.imageWidth,
                                        box.height / landmarks.imageHeight,
                                       )
        face = faceapi.FaceDetection(1, relative_box, landmarks._imgDims)
        return {
          detection:
            relativeBox: box
          landmarks: landmarks
        }

  detectSingle: (image_data)->
    @detectAll(image_data).then (faces) ->
      return faces[0] ? null
