# @enable_linter
import rand from "utils/rand"
import {t,tnoop} from "i18n_init"
FUNCTIONS = {
  min: tnoop("exercise.operations.minimal")
  max: tnoop("exercise.operations.maximal")
}
OPS = {
  '+': '+'
  '-': "\u2212"
  '*': "\xD7"
  '/': ":"
}
export default class OperationsGenerator
  constructor: (config = {}) ->
    @config = _.defaults config, {
      question: ['min', 'max']
      range: {min: 0, max: 30}
      diff: {min: 5, max: 10}
      arg_range: {min: 1, max: 10}
      operators: '-+/*'
    }

  generateQuestion: (num_answers) ->
    answers = []
    results = []
    i = 0
    while results.length < num_answers
      if i == 10000000
        throw new Error('Unable to genereate questions in 10 000 000 iterations.')
      if i % 100 == 0
        func = rand.element(@config.question)
        #Zakres dla poprawnej odpowiedzi
        res_range =
          max: @config.range.max - if func == 'min' then (@config.diff?.min ? 1) * 2 else 0
          min: @config.range.min + if func == 'max' then (@config.diff?.min ? 1) * 2 else 0
        answers = []
        correct = undefined
        results = []
      i += 1

      [operation, html, res] = @_generateOperation(res_range)
      if operation == undefined
        continue

      if _.isNaN(res) or (operation in results)
        continue

      if correct is undefined
        correct = res
        if func == 'max'
          res_range.max = Math.max(res - (@config.diff?.min ? 1), @config.range.min)
          res_range.min = Math.max(res - (@config.diff?.max ? (res - @config.range.min)), @config.range.min)
        else
          res_range.min = Math.min(res + (@config.diff?.min ? 1), @config.range.max)
          res_range.max = Math.min(res + (@config.diff?.max ? (@config.range.max - res)), @config.range.max)

      results.push(operation)
      answers.push
        html: html
        op: operation
        correct: false
        result: res
    answers[0].correct = true
    question =
      html: t(FUNCTIONS[func])
      answers: _.shuffle(answers)
    return question

  _generateOperation: (res_range) ->
    arg_range = @config.arg_range
    op = rand.element(@config.operators)
    res = rand.int(res_range)
    if op == '-'
      f1 = rand.int(res, arg_range.max)
      f2 = f1 - res
    else if op == '+'
      f1 = rand.int(arg_range.min, res)
      f2 = res - f1
    else if op == '*'
      f1 = rand.int(arg_range.min, Math.sqrt(res))
      f2 = Math.round(res / f1)
      res = f1 * f2
    else if op == '/'
      f1 = rand.int(res_range.min, arg_range.max)
      if f1 == 0
        f2 = rand.int(arg_range)
        res = 0
      else
        f2 = rand.int(Math.max(arg_range.min, 1), Math.ceil(Math.min(Math.sqrt(f1), f1 / res_range.min)))
        res = Math.round(f1 / f2)
        f1 = res * f2

    if f1 < arg_range.min or f2 < arg_range.min
      return []

    if res < res_range.min or res > res_range.max
      return []

    '''
    calc_res =
      switch op
        when '-' then f1 - f2
        when '+' then f1 + f2
        when '*' then f1 * f2
        when '/' then f1 / f2
        else throw new Error("_generateOperation verification: unknown operation")

    if calc_res != res
      throw new Error("_generateOperation verification: wtf?")
    '''

    operation = "#{f1}#{OPS[op]}#{f2}=#{res}"
    html = "#{f1}#{OPS[op]}#{f2}"
    return [operation, html, res]
