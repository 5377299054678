import rand from "utils/rand"
import Segment from "helpers/segment"
import { RectArea } from "./area"
import Vector from "utils/vector"

export default class PositionGenerator
  NEW_HAND_PREFERENCE: 0.7
  NEW_AREA_PREFERENCE: 0.5
  AREA_CLASS: RectArea

  constructor: (areas, min_distance) ->
    @_areas = {}
    @_sides = { t: [], b: [], l: [], r: [] }
    prob = 1 / _.size(areas)
    for name,area of areas
      @_areas[name] = a = new @AREA_CLASS(name, area, prob)
      a.prob = prob
      @_sides[a.side.x].push(a)
      @_sides[a.side.y].push(a)
    @_elements = []
    @_min_distance = min_distance
    @_min_distance2 = @_min_distance * @_min_distance


  updateProbabilities: (area, factor) ->
    if _.size(@_areas) == 1
      return
    if @_sides[area.other.x].length == 0
      prob = y: factor
    else if @_sides[area.other.y].length == 0
      prob =
        x: factor
    else
      prob =
        x: factor * @NEW_HAND_PREFERENCE
        y: factor * (1 - @NEW_HAND_PREFERENCE)
    for attr,p of prob
      current_sides = _.sumBy @_sides[area.side[attr]], 'prob'
      other_sides = _.sumBy @_sides[area.other[attr]], 'prob'
      diff = current_sides * p
      for a in @_sides[area.side[attr]]
        a.prob -= a.prob / current_sides * diff
      for a in @_sides[area.other[attr]]
        a.prob += a.prob / other_sides * diff

  getAreaForTarget: ->
    return @_areas[rand.weightedObj(_.mapValues(@_areas, 'prob'))]

  getRandomArea:-> return rand.value(@_areas)

  areaChosen: (area) ->
    @updateProbabilities(area, @NEW_AREA_PREFERENCE)

  getAvailableRays: (ray, elements)->
    shortest = { target: ray, nontarget: ray }
    for el in elements
      intersection = ray.perpendicularIntersection(el.pos)
      shortest[el.type] ?= ray
      if intersection?
        perp_segment = new Segment(el.pos, intersection)
        if perp_segment.length_sq < @_min_distance2
          valid_ray = new Segment(ray.start, intersection)
          current = shortest[el.type]
          if current.length_sq > valid_ray.length_sq - (@_min_distance2 - perp_segment.length_sq)
            valid_ray.setLength(valid_ray.length - Math.sqrt((@_min_distance2 - perp_segment.length_sq)))
            shortest[el.type] = valid_ray
    return shortest

  filterPositions: (positions, elements) ->
    return _.filter positions, (p) =>
      _.every elements, (e) => Vector.fromPoints(e.pos, p).lengthSq() > @_min_distance2


  DEFAULT_MIN_ANGLE = 0.35 # ~ 10 stopni
  GET_POSITION_TRIES = 50

  getPosition: (isTarget, activeElements = []) ->
    otherType = activeElements.filter((p)->p.isTarget != isTarget)
    # Najmniejsza odległość do innych [odwrotności tego czym jesteśmy - target/nontarget, aktywnych przycisków]
    bestChoice = {
      position: null,
      distanceToActiveElement: 0
      distanceToOtherType: 0
    }
    i = 0
    area = null
    minDistance = (position, elements)=>  Math.min(...elements.map(({ position:pos })=>pos.distance2(position)))
    while ++i < GET_POSITION_TRIES
      if (area == null)
        area = @getAreaForTarget()
      position = area.getRandomPosition()
      if position
        distanceToActiveElement = minDistance(position, activeElements)
        if distanceToActiveElement >= @_min_distance2
# Pozycja spełnia wszystkie założenia - bierzemy! :)
          bestChoice = { position, distanceToActiveElement, area }
          break
        else
          distanceToOtherType = Math.min(minDistance(position, otherType), @_min_distance2)
          if (distanceToOtherType > bestChoice.distanceToOtherType or (distanceToOtherType == bestChoice.distanceToOtherType and distanceToActiveElement > bestChoice.distanceToActiveElement))
# To najlepsza pozycja jak narazie - najdalej od naszych odwrotności lub dalej od wszystkich aktywnych
# oraz w obrębie obszaru ćwiczenia
            bestChoice = { distanceToOtherType, distanceToActiveElement, position, area }
      if i % 2 == 0
        area = null
    if i >= GET_POSITION_TRIES
# Nie znaleźliśmy idealnej pozycji, spróbujemy wziąć to, co najlepiej pasuje
      if bestChoice.distanceToOtherType < @_min_distance2 and isTarget
# Jesteśmy non-targetem, zbyt blisko targeta - nie pokazujmy się
        return null
    @areaChosen(bestChoice.area)
    return bestChoice.position
