import ExerciseDescriptor, { areaName, AREAS } from "../exercise_descriptor"
import WIPER_LEVELS from "./wiper_levels"
import ConfigGenerator from "../../config_generators/config_generator"
import PositionGenerator from "../../config_generators/position_generator"
import { PredefinedArea } from "../../config_generators/area"
import wiperPositions from "./wiper.svg?inline"
import SvgReader from "helpers/svg_helper"
import { mapValues, omit, pick } from "lodash"
import rand from "utils/rand"

const MAP = {
  [AREAS.tl]: "top_left",
  [AREAS.tr]: "top_right",
  [AREAS.bl]: "bottom_left",
  [AREAS.br]: "bottom_right",
}

export class WiperPositionGenerator extends PositionGenerator {
  static AREAS_CONFIG = null
  static getAreaConfig = () => {
    if (WiperPositionGenerator.AREAS_CONFIG == null) {
      const reader = new SvgReader(wiperPositions)
      const config = {}
      Object.entries(MAP).forEach(([area, svg_name]) => {
        const node = reader.getNode("#" + svg_name)
        config[area] = {
          start_point: { x: 0, y: 0 },
          positions: reader.getAllPositionsInsidePolygon(node, 0.01),
        }
      })
      WiperPositionGenerator.AREAS_CONFIG = config
    }
    return WiperPositionGenerator.AREAS_CONFIG
  }

  constructor(areas, minDistance = 0.2) {
    const areaConfig = WiperPositionGenerator.getAreaConfig()
    areas = mapValues(areas, (_, area) => areaConfig[area])
    super(areas, minDistance)
  }
}

Object.assign(WiperPositionGenerator.prototype, { AREA_CLASS: PredefinedArea })

export class WiperConfigGenerator extends ConfigGenerator {
  MIN_DISTANCE = 0.2
  FINISH_DELAY = 0.5

  constructor(level_conf, areas) {
    super(level_conf, areas)
    this._pos_generator = {
      both: new WiperPositionGenerator(this._areas, this.MIN_DISTANCE),
      left: new WiperPositionGenerator(pick(this._areas, AREAS.tl, AREAS.bl), this.MIN_DISTANCE),
      right: new WiperPositionGenerator(pick(this._areas, AREAS.tr, AREAS.br), this.MIN_DISTANCE),
    }
    this.minTime = 0
    this.types = Array.from({ length: 5 }, (_, i) => i + 1)
    this.colors = ["#0079D0", "#629BFF", "#FF0000", "#0976C3", "#4BB500", "#ECDC04", "#C104D1"]
    this.double = this.isOneSideSelected() ? 0 : this._conf.double
  }

  minTrialDuration() {
    const conf = this._conf
    return (
      (conf.next_stain != null ? conf.next_stain : conf.timeout != null ? conf.timeout : 15) * 0.9
    )
  }

  _boolRandArray(probability, length) {
    const boolArray = new Array(length).fill(false)
    for (let i = 0; i < length * probability; i++) boolArray[i] = true
    return rand.shuffleArray(boolArray)
  }

  generateScenario(round_duration) {
    const minDuration = this._conf.duration
    const trials = round_duration / minDuration
    const expected = Math.floor((this._conf.stains * round_duration) / 60)
    const stains = [
      ...this._boolRandArray(this.double, expected),
      ...this._boolRandArray(this.double, Math.ceil(trials - expected)),
    ]
    this.scenario = []
    this.previous = null
    stains.forEach((double) => {
      this.previous = double ? this.getDoubleStain() : this.getSingleStain()
      this.scenario.push(...this.previous)
    })
    return this.scenario
  }

  generateRoundConfig(round_duration) {
    const config = super.generateRoundConfig(round_duration)
    config.pointsPerStain = this._conf.pointsPerStain
    return config
  }

  activeStains(time) {
    return this.scenario.filter(
      (s) => s.startTime < time && s.startTime + (s.timeout_after ?? 15) > time
    )
  }

  getLaunchConfig() {
    let launch = []
    const { next_stain } = this._conf
    let startTime = 0
    if (this.previous) {
      const ids = this.previous.map((e) => e.id)
      launch.push({
        type: "after_finish_of",
        with_delay: Math.round(this.FINISH_DELAY * 1000),
        elements: ids,
      })
      const p = this.previous[0]
      startTime = p.startTime + (p.timeout_after ?? 15) + this.FINISH_DELAY
      if (next_stain != null)
        launch.push({
          type: "after_start_of",
          with_delay: Math.round(next_stain * 1000),
          elements: ids,
        })
      startTime = Math.min(p.startTime + next_stain, startTime)
    } else {
      launch.push({ type: "after_start_of", with_delay: 0 })
    }
    return { launch, startTime }
  }

  _getStain() {
    const { timeout, duration } = this._conf
    return {
      id: "Stain_" + this.scenario.length,
      type: rand.element(this.types),
      color: rand.element(this.colors),
      scale: 0.2,
      success_after_hover: duration,
      timeout_after: timeout,
      ...this.getLaunchConfig(),
    }
  }

  getSingleStain() {
    const stain = this._getStain()
    const activeStains = this.activeStains(stain.startTime)
    stain.position = this._pos_generator.both.getPosition(null, activeStains)
    return [stain]
  }

  getDoubleStain() {
    const leftStain = this._getStain()
    const activeStains = this.activeStains(leftStain.startTime)
    leftStain.position = this._pos_generator.left.getPosition(null, activeStains)
    activeStains.push(leftStain)
    const rightStain = {
      ...leftStain,
      position: this._pos_generator.right.getPosition(null, activeStains),
      id: "Right" + leftStain.id,
    }
    leftStain.id = "Left" + leftStain.id
    return [leftStain, rightStain]
  }
}

export default class WiperDesc extends ExerciseDescriptor {
  generateRoundConfig(run_config) {
    const config = super.generateRoundConfig(run_config)
    config.scenario = config.scenario.map((s) => ({
      ...omit(s, "startTime"),
      position: { x: s.position.x, y: -s.position.y },
      timeout_after: s.timeout_after ? Math.round(s.timeout_after * 1000) : -1,
      success_after_hover: Math.round(s.success_after_hover * 1000),
    }))
    return config
  }

  getExpectedPoints(run_config) {
    const generator = this.getGenerator(run_config)
    const conf = this._getLevelConfig(run_config)
    const { round_duration } = run_config
    const expectedTrials = (round_duration / 60) * conf.stains
    const doubleStains = generator.double * expectedTrials
    const singleStains = expectedTrials - doubleStains
    return (singleStains + doubleStains * 2) * conf.pointsPerStain
  }

  getWatcherConfig() {
    return { subtractor: "gl", threshold: 0.05 }
  }
}

Object.assign(WiperDesc.prototype, {
  scene_name: "Wiper",
  exercise_id: "wiper",
  LEVELS_CONFIG: WIPER_LEVELS,
  GeneratorClass: WiperConfigGenerator,
  instructions: [{ video: { slides: "wiper" } }, { hint: _tnoop("hints:wiper.instruction") }],
})
