# @enable_linter
define [
  'jquery'

  'utils/math'

  './key_map'
], (
  $

  math

  key_map
) ->
  _transitionend = 'transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd'
  _transitionstart = 'transitionstart webkitTransitionStart oTransitionStart MSTransitionStart'

  $.parseKey = (event, modifiers = true) ->
    key = ''
    if modifiers
      for mod in ['alt', 'ctrl', 'meta', 'shift']
        if event[mod + 'Key'] then key += mod + '+'
    if event.type == 'keypress'
      key += event.key
    else
      key += key_map.toString(event.which ? event.keyCode)
    return key

  $.fn.transitionEnd = (options) ->
    # Fire callback when transitionEnd event occurs. If transition does not start,
    # fire event after timeout (usefull when transition for certain elements is not defined).
    options = $.extend { timeout: 500, is_started: false }, options
    if options.timeout == 0
      return options.callback?()
    transitionStart = =>
      @data('transitionstarted', true)
      @one _transitionend, options.callback
    if options.is_started
      transitionStart()
    else
      @one _transitionstart, transitionStart
    setTimeout ( =>
      if not @data('transitionstarted')
        @off _transitionstart, transitionStart
        options.callback?()
      ), options.timeout
    return this

  $.fn.bindFirst = (name, fn) ->
    # bind as you normally would
    # don't want to miss out on any jQuery magic
    this.on(name, fn)
    # Thanks to a comment by @Martin, adding support for
    # namespaced events too.
    this.each ->
      handlers = $._data(this, 'events')[name.split('.')[0]]
      # take out the handler we just inserted from the end
      handler = handlers.pop()
      #move it at the beginning
      handlers.splice(0, 0, handler)

  $.fn.stableSort = (compare) ->
    @each (i) -> @_sort_index = i
    @sort (a, b) ->
      res = compare(a, b)
      if res == 0
        return a._sort_index - b._sort_index
      else
        return res
  $.fakeEvent = (type, which, shift) ->
    e = $.Event(type)
    e.fake = true
    if which?
      e.keyCode = e.which = key_map.fromString(which)
    if shift?
      e.shiftKey = shift
    return e
  $.fn.mapNavigationKeys = (mapping) ->
    defaults =
      #'esc': 'back'
      'F5': 'enter'
      'page_up': 'prev'
      'page_down': 'next'
    mapping = $.extend defaults, mapping
    keys = _.keys(mapping)
    el = this
    this.off '.navigation'
    this.on 'keyup.navigation keydown.navigation', (e) ->
      key = $.parseKey e
      action = mapping[key]
      fakeEvent = $.fakeEvent
      if action?
        focused = el.find(':focus')
        if key.length > 1
          e.preventDefault()
        e.stopImmediatePropagation()
        if action == 'enter'
          if focused.length
            focused.toggleClass('active', e.type == 'keydown')
            if e.type == 'keyup'
              # Kliknięcie chcemy zatriggerować raz
              focused.trigger fakeEvent 'click'
          else
            el.trigger(fakeEvent e.type, ' ') # Naciśnięcie spacji
        else if action == 'back'
          (if focused.length then focused else el).trigger fakeEvent e.type, 'esc'
        else if action in ['prev', 'next']
          fake_e = fakeEvent e.type, 'tab', prev = action == 'prev' # Tab lub tab + shift
          focused.trigger fake_e
          if fake_e.isDefaultPrevented()
            return
          else if e.type == 'keydown'
            _.defer ->
              tabbable = el.find(':tabbable')
              tabbable.stableSort (a, b) -> (a.getAttribute('tabindex') ? 32000) - (b.getAttribute('tabindex') ? 32000)
              if focused.length == 0
                tabbable[if prev then tabbable.length - 1 else 0]?.focus()
              else
                tabbable.each (i) ->
                  if this == focused[0]
                    i = math.mod(i + (if prev then -1 else 1), tabbable.length)
                    tabbable[i].focus()
                    return false

  $.onInternalMessage = (msg_type, callback, options) ->
    options= _.defaults {}, options, same_window:false, propagate_to_children:false,
    $(window)
      .on 'message.' + msg_type, (event) ->
        event = event.originalEvent
        if event.origin != location.origin
          return
        msg = event.data
        if msg.type == msg_type
          if options.same_window or event.source != window
            callback(msg.data, event)
          if options.propagate_to_children
            $('iframe').each ->
              try
                if this.contentWindow != event.source and this.contentWindow.location.origin == location.origin
                  this.contentWindow.postMessage(msg,location.origin)
              catch
                # cross origin error, probably xdomain
      .one 'unload', -> $(this).off 'message.' + msg_type

  $.internalMessage = (msg_type, data) ->
    window.top.postMessage(type: msg_type, data: data, window.location.origin)

  return $
