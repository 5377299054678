import Rect from "./rect"
import "path-data-polyfill"
import pointInSvgPolygon from "point-in-svg-polygon"

export default class SVGReader
  constructor: (svg)->
    @$ = $(svg)
    @_svg = @$[0]
    @_viewbox = new Rect(@_svg.viewBox.baseVal)
    for node in @_svg.querySelectorAll("g")
      node.setAttribute("label", node.getAttribute("inkscape:label"))

  _getRect: (node)->
    if node.tagName == 'rect'
      rect = new Rect(node.x.baseVal.value, node.y.baseVal.value, node.width.baseVal.value, node.height.baseVal.value)
    else if node.tagName == "path"
      points = @_getPointsFromPath(node.getPathData(normalize: true))
      rect = @_getRectFromPoints(points)
    pos = @fromLocal(rect)
    return (new Rect({
      pos...
      width: rect.width / @_viewbox.height,
      height: rect.height / @_viewbox.height
    }))
  _getPosition: (node)=>
    if node.tagName == 'rect' or node.tagName == 'path'
      return @_getRect(node).center
    else if node.tagName == 'ellipse'
      return new Point(@fromLocal({ x: node.cx.baseVal.value, y: node.cy.baseVal.value }))

  _getPointsFromPath: (pathData)->
    points = []
    for s in pathData
      if s.type == 'M' or s.type == "L"
        points.push x: s.values[0], y: s.values[1]
      else if s.type == "C"
        points.push x: s.values[0], y: s.values[1]
        points.push x: s.values[2], y: s.values[3]
        points.push x: s.values[4], y: s.values[5]
    return points

  _getRectFromPoints: (points)->
    min = { x: +Infinity, y: +Infinity }
    max = { x: -Infinity, y: -Infinity }
    for p in points
      for a in 'xy'
        min[a] = Math.min(min[a], p[a])
        max[a] = Math.max(max[a], p[a])
    return new Rect(min.x, min.y, max.x - min.x, max.y - min.y)

  getPositions: (selector)->
    return _.map(@_svg.querySelectorAll(selector), @_getPosition)

  fromLocal: (point)->
    return {
      x: ((point.x - @_viewbox.x) - @_viewbox.width / 2) / @_viewbox.height,
      y: ((point.y - @_viewbox.y) - @_viewbox.height / 2) / @_viewbox.height,
    }

  toLocal: (point)->
    return {
      x: point.x * @_viewbox.height + @_viewbox.width / 2 + @_viewbox.x
      y: point.y * @_viewbox.height + @_viewbox.height / 2 + @_viewbox.y
    }

  getAllPositionsInsidePolygon: (node, resolution)->
    box = @_getRect(node)
    points = Array.from(box.pointsInside(resolution))
    if node.tagName == 'path'
      segments = pointInSvgPolygon.segments(node.getAttribute('d'))
      return points.filter((p) =>
        local = @toLocal(p)
        pointInSvgPolygon.isInside([local.x, local.y], segments))
    else if node.tagName == 'rect'
      return Array.from(box.pointsInside(resolution))

  getAllPositionsInsidePolygons: (selector, resolution)->
    return _.map(@_svg.querySelectorAll(selector), (node)=>@getAllPositionsInsidePolygon(node, resolution))
  getNode: (selector)-> @_svg.querySelector(selector)
  getNodes: (selector)-> @_svg.querySelectorAll(selector)
