define ->
  exports=
    key_map: key_map=
      1: 'left_btn'
      2: 'middle_btn'
      3: 'right_btn'
      8: "backspace"
      9: "tab"
      13: "enter"
      16: "shift"
      17: "ctrl"
      18: "alt"
      19: "pause_break"
      20: "caps_lock"
      27: "esc"
      33: "page_up"
      34: "page_down"
      35: "end"
      36: "home"
      37: "left"
      38: "up"
      39: "right"
      40: "down"
      45: "insert"
      46: "delete"
      91: "left_window"
      92: "right_window"
      93: "select_key"
      96: "numpad_0"
      97: "numpad_1"
      98: "numpad_2"
      99: "numpad_3"
      100: "numpad_4"
      101: "numpad_5"
      102: "numpad_6"
      103: "numpad_7"
      104: "numpad_8"
      105: "numpad_9"
      106: "multiply"
      107: "add"
      109: "subtract"
      110: "decimal_point"
      111: "divide"
      112: "F1"
      113: "F2"
      114: "F3"
      115: "F4"
      116: "F5"
      117: "F6"
      118: "F7"
      119: "F8"
      120: "F9"
      121: "F10"
      122: "F11"
      123: "F12"
      144: "num_lock"
      145: "scroll_lock"
      186: ";"
      187: "="
      188: ","
      189: "-"
      190: "."
      191: "/"
      192: "`"
      219: "["
      220: "\\"
      221: "]"
      222: "'"
    fromString:(name)->
      for e,str of key_map
        if str == name
          return parseInt(e)
      if name.length == 1
        return name.charCodeAt(0)
    toString:(char_code)->
      return key_map[char_code] ? String.fromCharCode(char_code)
