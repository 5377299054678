export function isWebWorker() {
  return typeof WorkerGlobalScope != "undefined" && self instanceof WorkerGlobalScope
}

export function createBrowserEnv() {
  if (isWebWorker())
    return {
      Canvas: OffscreenCanvas,
      CanvasRenderingContext2D: OffscreenCanvasRenderingContext2D,
      Image: function () {},
      ImageData: ImageData,
      Video: function () {},
      createCanvasElement: () => new OffscreenCanvas(1, 1),
      createImageElement: () => new Image(),
      fetch: self.fetch,
      readFile: function () {
        throw new Error("readFile - filesystem not available for browser environment")
      },
    }
  var fetch =
    window["fetch"] ||
    function () {
      throw new Error("fetch - missing fetch implementation for browser environment")
    }
  var readFile = function () {
    throw new Error("readFile - filesystem not available for browser environment")
  }
  return {
    Canvas: HTMLCanvasElement,
    CanvasRenderingContext2D: CanvasRenderingContext2D,
    Image: HTMLImageElement,
    ImageData: ImageData,
    Video: HTMLVideoElement,
    createCanvasElement: function () {
      return document.createElement("canvas")
    },
    createImageElement: function () {
      return document.createElement("img")
    },
    fetch: fetch,
    readFile: readFile,
  }
}
