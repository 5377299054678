define ->
  class Point
    @getXY = (x, y) ->
      if x and typeof x isnt 'number'
        if x.x?
          return x
        else if x.width?
          y = x.height
          x = x.width
        else
          y = x[1] ? x[0]
          x = x[0]

      return{
        x: x ? 0
        y: y ? x ? 0
      }
    constructor: (x, y) ->
      p = Point.getXY(x, y)
      @x = p.x
      @y = p.y

    add: (v) ->
      v = Point.getXY(arguments...)
      new Point(@x + v.x, @y + v.y)

    addEq: (v) ->
      v = Point.getXY(arguments...)
      @x += v.x
      @y += v.y
      return this

    copy: -> new Point(@x, @y)

    toObject: -> {x: @x, y: @y}

    toArray: -> return [@x, @y]

    radiansTo: (v) ->
      """Angle between vector (this,v) and X-axis"""
      v = Point.getXY(arguments...)
      dx = @x - v.x
      dy = @y - v.y
      return Math.atan2(dy, dx)

    degreesTo: (v) -> @radiansTo(v) * (180.0 / Math.PI)

    distance2: (v, scale_x = 1) ->
      v = Point.getXY(v)
      x = (@x - v.x) * scale_x
      y = @y - v.y
      return x * x + y * y

    distance: (v, scale_x = 1) ->
      return Math.sqrt(@distance2(v, scale_x))

    distanceToSegment: (p1, p2) ->
# http://stackoverflow.com/questions/849211/shortest-distance-between-a-point-and-a-line-segment
      sqr = (x) -> x * x
      dist2 = (v, w) -> sqr(v.x - w.x) + sqr(v.y - w.y)
      distToSegmentSquared = (p, v, w) ->
        l2 = dist2(v, w);
        return dist2(p, v) if (l2 == 0)
        t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
        t = Math.max(0, Math.min(1, t));
        return dist2(p, {x: v.x + t * (w.x - v.x), y: v.y + t * (w.y - v.y)});
      return Math.sqrt(distToSegmentSquared(@, p1, p2))

    equals: (c) -> @x == c.x and @y == c.y

    ###
    Determines a point between two specified points. The parameter f determines
    where the new interpolated point is located relative to this and the end point
    (parameter v). The closer the value of the parameter f is to 1.0, the closer
    the interpolated point is to this. The closer the value of the parameter f is
    to 0, the closer the interpolated point is to the destination point (parameter v).
    v  - The point at the opposite end of the distance comparison.
    f - The level of interpolation between the two points. Indicates where the new
        point will be, along the line between this and the destination point. If f=1,
        this is returned; if f=0, v is returned.
    ###
    interpolate: (v, f) ->
      v = Point.getXY(v)
      inv_f = 1.0 - f
      return new Point(inv_f * v.x + f * @x, inv_f * v.y + f * @y)

    length: -> Math.sqrt(@x * @x + @y * @y)

    normalize: (thickness = 1.0) ->
      len = @length()
      @x = @x / len * thickness
      @y = @y / len * thickness
      return this

    normalized: (thickness = 1.0) -> new Point(@x, @y).normalize(thickness)

    ###
    Updates a Point to reflect the position based on the passed parameters describing an arc.
    origin -  The point from which to calculate the new position of this.
    arcWidth - A number desribing the width of the arc definining the orbital path.
    arcHeight - A number desribing the height of the arc definining the orbital path.
    degrees -  The position (0 to 360) describing the position along the arc to be computed.
    ###
    orbit: (origin, arcWidth, arcHeight, degrees) ->
      radians = degrees * (Math.PI / 180.0)
      @x = origin.x + arcWidth * Math.cos(radians)
      @y = origin.y + arcHeight * Math.sin(radians)
      return this

    offset: (dx, dy) ->
      @x += dx
      @y += dy
      return this

    subtract: sub = (v) -> new Point(@x - v.x, @y - v.y)
    sub: sub
    subtractEq: (v) ->
      @x -= v.x
      @y -= v.y
      return this

    toString: -> "(x = #{@x}, y = #{@y})"

    mul: (v) ->
      v = new Point(v, v) if typeof v is 'number'
      return new Point(@x * v.x, @y * v.y)

    mulEq: (v) ->
      v = new Point(v, v) if typeof v is 'number'
      @x *= v.x
      @y *= v.y
      return this

    div: (v) ->
      v = new Point(v, v) if typeof v is 'number'
      new Point(@x / v.x, @y / v.y)

    divEq: (v) ->
      v = new Point(v, v) if typeof v is 'number'
      @x /= v.x
      @y /= v.y
      return this

    inverse: -> new Point(-@x, -@y)

    floor: -> new Point(Math.floor(@x), Math.floor(@y))

    rotate: (angle) ->
      c = Math.cos(angle)
      s = Math.sin(angle)
      return new Point(@x * c - @y * s, @y * c + @x * s)

    rotateAroundOrigin: (origin, angle) ->
      c = Math.cos(angle)
      s = Math.sin(angle)
      return new Point(c * (@x - origin.x) - s * (@y - origin.y) + origin.x, s * (@x - origin.x) + c * (@y - origin.y) + origin.y)

    @angleAround: (a, b, center)->
      center ?= x: 0, y: 0
      return Math.atan2(b.y - center.y, b.x - center.x) - Math.atan2(a.y - center.y, a.x - center.x)

    ###
    Determines a point between two specified points. The parameter f determines
    where the new interpolated point is located relative to the two end points
    specified by parameters pt1 and pt2. The closer the value of the parameter f
    is to 1.0, the closer the interpolated point is to the first point (parameter pt1).
    The closer the value of the parameter f is to 0, the closer the interpolated point
    is to the second point (parameter pt2).
    pt1 - The first point.
    pt2 - The second point.
    f  - The level of interpolation between the two points. Indicates where the new
         point will be, along the line between pt1 and pt2. If f=1, pt1 is returned
         if f=0, pt2 is returned.
    ###
    @interpolate: (pt1, pt2, f) ->
      inv_f = 1.0 - f
      return new Point(inv_f * pt2.x + f * pt1.x, inv_f * pt2.y + f * pt1.y)

# converts a pair of polar coordinates to a Cartesian point coordinate.
    @polar: (len, angle) -> new Point(len * Math.sin(angle), len * Math.cos(angle))

    @distance: (pt1, pt2) ->
      dx = pt1.x - pt2.x
      dy = pt1.y - pt2.y
      return Math.sqrt(dx * dx + dy * dy)
  Object.defineProperties Point::, {
    width:
      get: ->@x
    height:
      get: ->@y
  }
  return Point
