import "utils/jquery.titanis"
import CustomEventEmitter from "helpers/event_helper"
import { saveAs } from "file-saver"
import { simplifyObject } from "helpers/error_helper"

start = Date.now()
if process.env.NODE_ENV == 'production'
  for type in ['log', 'info', 'warn', 'error', 'debug']
    do (type) ->
      console['_' + type] = console[type]
      console[type] = -> console._intercept(type, arguments)

history = []
try
  show_console = sessionStorage.show_debug?
catch
  show_console = false
console.__enable = ->
  show_console = true
  console._log(getConsoleText())

console._intercept = (type, args) ->
  if not args or args.length == 0
    return
  history.push
    timestamp: Date.now() - start
    type: type
    arguments: args
  if show_console
    console['_' + type].apply(console, args)

printVal = (val) ->
  if _.isObject(val)
    val = simplifyObject(val, 2)
    try
      printed = JSON.stringify(val)
    catch
      printed = "" + val
    if printed.length > 1000
      printed = printed.substr(0, 1000) + '...'
    return printed
  return val

getConsoleText = ->
  lines = ["Started at #{new Date(start)} (#{start}) "]
  for log in history
    lines.push  "#{log.timestamp / 1000}\t#{log.type}\t" + (printVal(arg) for arg in log.arguments).join(" ")
  return lines.join("\n")

class ErrorHandler extends CustomEventEmitter
  constructor: ->
    super()
    $(document).on 'keydown.error_handler', (event)=>
      key = $.parseKey(event)
      if key == 'ctrl+F1'
        event.preventDefault()
        @_handleError({ type: 'report' })

  _handleError: (report_data) ->
    await @emitAsync 'fill_report', report_data
    date = (new Date()).toLocaleString().replace(/:/g, '-')
    if report_data.recording
      saveAs(report_data.recording, "Neuroforma_#{date}.webm")
    blob = new Blob([getConsoleText()], type: "text/plain")
    saveAs(blob, "Neuroforma_#{date}.txt")
    if (sessionStorage.current_config)
      blob = new Blob([sessionStorage.current_config], type: "text/plain")
      saveAs(blob, "Neuroforma_#{date}.json")
export default ERROR_HANDLER = new ErrorHandler()
