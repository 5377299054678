import ConfigGenerator from "../../config_generators/config_generator"
import rand from "utils/rand"
import Point from "utils/point"
import { SHAPES } from "./paths_shapes"

class Shape
  constructor: (name, conf)->
    @name = name
    _.extend this, conf

  getConfig: ({ area, speed, reversed })->
    if (area == "left" or area == "right")
      if not /_medium/.test(@name)
        path_name = @name + '_large'
      else
        path_name = @name
    else
      path_name = @name + '_small'
    flip = new Point((if /left/.test(area) then 1 else -1), 1)
    pos_config = @left
    position = flip.mul(pos_config)
    speed_config = [
      {
        time_offset: 0,
        speed: speed,
        acceleration: 1
      }
    ]
    return {
      path_name: path_name.toUpperCase()
      position: { x: position.x, y: -position.y }
      scale: flip.mul(@scale ? { x: 1, y: 1 })
      rotation: pos_config.rotation * flip.x
      reversed: reversed
      speed: speed_config
    }
for name,config of SHAPES
  SHAPES[name] = new Shape(name, config)

ALL_SHAPES = _.keys(SHAPES)
SMALL_SHAPES = ALL_SHAPES.filter((s)-> /_small/.test(s))
LARGE_SHAPES = ALL_SHAPES.filter((s)-> not /_small/.test(s))

export getAvailableShapes = (all_shapes, lastShape, rules, history)->
  shapes = _.map(rules, (r)->
    if not lastShape?
      return r.when
    else
      if r.when.includes(lastShape)
        return r.then
      else
        return []
  )
  available_shapes = _.intersection(all_shapes, _.flatten(shapes))
  if available_shapes.length == 0 and lastShape
    return getAvailableShapes(all_shapes, null, rules, history)
  for p in history
    if available_shapes.length > 0
      available_shapes = _.without(available_shapes, p)
  return available_shapes

export default class PathsConfigGenerator extends ConfigGenerator
  TRIAL_PREFIX: "Synchro"
  DEFAULTS: {
    trials: 2
    shape_diff: 0
    speed: 1
    async: false
    rules: [when: LARGE_SHAPES, then: LARGE_SHAPES]
    remember: 1
    startDelay: 5
  }
  TRIAL_START_ANIMATION_DURATION: 0.6
  constructor: (config, areas)->
    super(config, areas)
    @_history = config.path_history ? []
    @_shapes = LARGE_SHAPES

  getPathConfig: (shape)->
    if @_conf.shape_diff
      speed = rand.shuffleArray([@_conf.speed - @_conf.shape_diff / 2, @_conf.speed + @_conf.shape_diff / 2])
    else
      speed = [@_conf.speed, @_conf.speed]
    if @_conf.async
      reversed = rand.shuffleArray([true, false])
    else
      reversed = [false, false]
    area = ['left', 'right']
    return  (shape.getConfig({ area: area[i], speed: speed[i], reversed: reversed[i] }) for i in [0..1])

  getNextShape: (lastShape)->
    available_shapes = @getAvailableShapes(lastShape)
    if @_conf.test?
      return available_shapes[0]
    return rand.element(available_shapes)

  getAvailableShapes: (lastShape)->
    getAvailableShapes(@_shapes, lastShape, @_conf.rules, @_history[0...@_conf.remember])

  shapeUsed: (shape)->
    @_history.unshift(shape)

  generateTrial: (shape, trial_duration)->
    return { paths: @getPathConfig(SHAPES[shape]) }

  generateScenario: (round_duration)->
    shape = null
    trial_duration = (round_duration - @_conf.trials * @TRIAL_START_ANIMATION_DURATION) / @_conf.trials
    scenario = []
    for t in [0...@_conf.trials]
      shape = @getNextShape(shape)
      scenario.push _.extend {
        id: "#{@TRIAL_PREFIX}_#{t}"
        duration: trial_duration
        startDelay: @_conf.startDelay,
      }, @generateTrial(shape, trial_duration)
      @shapeUsed(shape)
    return scenario

  generateRoundConfig: (round_duration)->
    config = super(round_duration)
    config.pointPerSecond = {
      holdingDouble: 1.0
      holdingSingle: 0.0
      holdingNone: -0.5
    }
    return config
