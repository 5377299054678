import ExerciseDescriptor, { areaName } from "../exercise_descriptor"
import HalvesConfigGenerator from "./halves_generator"
import HALVES_LEVELS from "./halves_levels"
import HALVES_LEVELS_2AREAS from "./halves_levels_2areas"
import SHAPES from "./halves_shapes"
import halves_mp4 from "./halves.mp4"

SLICES = { v: 0, d: 1, h: 2 }
available_areas = _.filter ExerciseDescriptor::available_areas, (e)->e.length > 1 and not ['brtl',
  'bltr'].includes(areaName(e))
export default class HalvesDescriptor extends ExerciseDescriptor
  scene_name: "Halves"
  exercise_id: "halves"
  LEVELS_CONFIG: HALVES_LEVELS
  available_areas: available_areas
  GeneratorClass: HalvesConfigGenerator
  instructions: [
    { video: { slides: "halves" } }
    { hint: _tnoop("hints:halves.instruction") }
  ]
  _getLevelConfig: (run_config)->
    level = run_config.level - 1
    if run_config.areas.length < 3
      return HALVES_LEVELS_2AREAS[level]
    else
      return HALVES_LEVELS[level]
  _translateEnums: (scenario) ->
    for s in scenario
      for ans in [s.correctAnswer, s.wrongAnswers...]
        ans.s = ans.slice # debug
        ans.n = ans.shapeID
        ans.shapeID = SHAPES[ans.shapeID].id
        ans.slice = SLICES[ans.slice]
        ans.position.y = -ans.position.y
    return scenario

  generateRoundConfig: (run_config) ->
    config = super(run_config)
    config.scenario = @_translateEnums(config.scenario)
    return config

  _calculateResults: (run_config, results)->
    results.correctness_score = results.score / results.max_score
    results.avg_trial_duration = results.duration / results.trials
    results.speed_score = results.trials / @getExpectedTrials(run_config)
    return results

  getExpectedTrials: (run_config)->
    l_config = @_getLevelConfig(run_config)
    trial_duration = if l_config.timeout > 0 then l_config.timeout * 0.9 else 15
    trial_duration += (2 * @GeneratorClass::ANIMATION_DURATION) / 1000
    duration = run_config.round_duration - @GeneratorClass::START_DELAY / 1000
    return  duration / trial_duration

  getExpectedPoints: (run_config)->
    l_config = @_getLevelConfig(run_config)
    return @getExpectedTrials(run_config) * (l_config.answers + 1)

