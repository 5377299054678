import ConfigGenerator from "exercises/config_generators/config_generator"
import SvgPositionGenerator from "exercises/config_generators/svg_position_generator"
import OperationsGenerator from "./operations_generator"
import quiz_positions from "./operations_positions.svg?inline"

export default class OperationsConfigGenerator extends ConfigGenerator
  START_DELAY: 1000
  TRIAL_PREPARE_TIME: 0.5 + 0.25
  TRIAL_SUCCEEDED_TIME: 1 + 0.5
  FAILURE_TIME: 3

  constructor: (level_conf, areas)->
    super(arguments...)
    if areas.length == 1
      choices = Math.min(4, level_conf.choices_nr)
    else
      choices = level_conf.choices_nr
    @_position_generator = new SvgPositionGenerator(quiz_positions, @_areas, choices, @ELEMENT_SIZE)
    minmax = (obj)->{ min: obj[0], max: obj[1] }
    @_operations_generator = new OperationsGenerator({
      question: level_conf.question,
      range: minmax(level_conf.range),
      diff: minmax(level_conf.diff),
      arg_range: minmax(level_conf.arg_range),
      operators: level_conf.operators
    })

  generateScenario: (round_duration)->
    min_element_duration = @TRIAL_PREPARE_TIME + @TRIAL_SUCCEEDED_TIME + @MIN_REACTION_TIME / 1000
    total_elements = Math.ceil(round_duration / min_element_duration)
    for e in [0..total_elements]
      @scenario.push(@_prepareTrial())
      @_id = @scenario.length
    return @scenario

  _prepareTrial: ->
    positions = @_position_generator.getPositions(@_conf.choices_nr)
    question = @_operations_generator.generateQuestion(@_conf.choices_nr)
    trial = {
      id: "Operation_#{@_id}",
      instruction: question.html.toLocaleUpperCase(),
      timeout: if @_conf.timeout > 0 then @_conf.timeout else -1,
      activated_after: 0.2,
      answers: []
    }
    for a,i in question.answers
      trial.answers.push({
        value: a.html,
        result: "" + a.result,
        size: @ELEMENT_SIZE,
        isCorrect: a.correct,
        position: positions[i].mul(x: 1, y: -1).toObject()
      })
    return trial
