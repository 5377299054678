define [
  'utils/point'
  'utils/path'
  'utils/rand'
  'utils/random_mover'
  'utils/easing_curves'
  'underscore'
  'utils/vector'
], (
  Point
  Path
  rand
  RandomMover
  easing_curves
  _
  Vector
) ->

  class RandomMovement
    constructor: (@config) ->
      @random_walker = new RandomMover(@config)
      @flyOutEnabled = if @config.flyOutEnabled? then not not @config.flyOutEnabled else false
      @flyOutThreshold = @config.flyOutThreshold ? 70
      @flyOutProbability = @config.flyOutProbability ? 0.01
      @flyOutDelayMin = @config.flyOutDelayMin ? 0
      @flyOutDelayMax = @config.flyOutDelayMax ? 0
      @flyOutProtection = @config.flyOutProtection ? 0
      @flyOutRandPos = if @config.flyOutRandPos? then not not @config.flyOutRandPos else false
      @flyOutFaster = if @config.flyOutFaster? then not not @config.flyOutFaster else false
      @maxFlyOuts = @config.maxFlyOuts ? 3
      @flyOutInitialProtection = @config.flyOutInitialProtection ? 10000
      @flyout_phase = 'none'
      @flyOutProtectionDuration = 0
      @flyOuts = 0
      @random_walker.runner.flyOuts = @flyOuts
      @random_walker.runner.flyOutEnabled = @flyOutEnabled
      @maxDelta = @config.maxDelta ? 150


    setPosition:(pos)->
      @random_walker.setPosition(pos.mul(new Point(800,600)))

    advance: (frame) ->
      delta = Math.min(frame.delta, @maxDelta)
      if @flyOutProtectionDuration > 0 then @flyOutProtectionDuration -= delta else @flyOutProtectionDuration = 0
      if @flyOutInitialProtection > 0 then @flyOutInitialProtection -= delta else @flyOutInitialProtection = 0

      if @flyout_phase is 'none' and @flyOutEnabled
        obj = @random_walker._obj()
        pos = obj.position
        dist =  @random_walker.getDistanceFromWorldBounds()
        rb = rand.bool(@flyOutProbability)
        #console.log pos.x, dist, @flyOutThreshold, rb
        if (pos.x < 200 or pos.x > 600) and dist <= @flyOutThreshold and \
           rb and @flyOutProtectionDuration is 0 and @flyOutInitialProtection is 0 and \
           @flyOuts < @maxFlyOuts

          @flyOuts += 1
          @random_walker.runner.flyOuts = @flyOuts
          if @flyOutRandPos
            len = @random_walker.worldBoundaries.len
            @flyout_path_pos = rand.float(0, len)
            @flyout_orig_pos = @random_walker.worldBoundaries.pointAtLength(@flyout_path_pos)
          else
            @flyout_orig_pos = pos.copy()
          if pos.x > 0.5*800
            @flyout_dest_pos = new Vector(870, rand.float(pos.y, pos.y+20))
          else
            @flyout_dest_pos = new Vector(-70, rand.float(pos.y, pos.y+20))
          console.log 'bye, bye', @flyout_dest_pos
          @random_walker.follower.seekTarget = position: @flyout_dest_pos.copy()
          @random_walker.follower.checkEdges = false
          @random_walker.runner.checkEdges = false
          #console.log 'current pos', pos.toString(), 'dest', @flyout_dest_pos.toString()
          @calculated_delay_time = rand.int(@flyOutDelayMin, @flyOutDelayMax)
          @flyout_time = 0
          @flyout_phase = 'flyout'

      if @flyout_phase isnt 'none'
        switch @flyout_phase
          when 'flyout'
            #console.log 'flyout_flyout'
            @flyout_time += frame.delta
            pos = @random_walker._obj().position
            if pos.distanceTo(@flyout_dest_pos) < 1.5 or (@flyout_time > @calculated_delay_time and @flyOutFaster)
              @flyout_wait_start = frame.time
              console.log "Time beyond the screen: #{@flyout_time/1000} seconds"
              p = new Vector()
              if @flyOutRandPos
                # FIXME: brute force intersection detection
                v = new Vector(@random_walker.worldBoundaries.getNormalAt(@flyout_path_pos))
                v.normalize().negate()
                while true
                  v.scale(1.1, 1.1)
                  p = Vector.add(@flyout_orig_pos, v)
                  if p.x <= -70 or p.y <= -70 or p.x >= 870 or p.y >= 670
                    break
                # console.log 'new pos', p.toString()
                @random_walker._obj().position.set(p.x, p.y)
              @flyout_phase = 'wait'
          when 'wait'
            #the random factor is now moved to the flyout phase
            @random_walker.moveTo(@random_walker._obj().position)
            if frame.time - @flyout_wait_start > @calculated_delay_time or @flyOutFaster
              @random_walker.follower.seekTarget = position: @flyout_orig_pos.copy()
              @flyout_phase = 'flyback'
          when 'flyback'
            #console.log 'flyout_flyback'
            if @random_walker._obj().position.distanceTo(@flyout_orig_pos) < 1.5
              @random_walker.follow()
              @random_walker.follower.checkEdges = true
              @random_walker.runner.checkEdges = true
              @flyOutProtectionDuration = @flyOutProtection
              @flyout_phase = 'none'
              if @flyOuts is @maxFlyOuts then @random_walker.runner.avoidEdges = true

      @random_walker.step(delta/1000)
      @position = @random_walker.getPosition()

    getRotation:-> @random_walker.getRotation()
    getDirection:-> @random_walker.getMovementDirection()
