import CustomEventEmitter from "helpers/event_helper"
import Logger from "utils/logger"
import Launch from "./launch"
import PerfMonitor from "img_processing/perf_monitor"

export LOGGER = new Logger("Exercise")
export EVENTS = {
  "ready",
  "round_started",
  "round_finished",
  "round_ready",
  "exercise_starting",
  "exercise_started",
  "exercise_finished",
  "calibration_started",
  "calibration_finished",
  "loading_started",
  "loading_finished",
  "start_round",
  "round_paused",
  "round_resumed",
  "pause",
  "continue",
  "loading",
}
ES = {"init", "loading", "ready", "running", "destroyed"}

NOTIFY_THRESHOLD = 60 * 1000 * 1.5
export default class BaseExercise extends CustomEventEmitter
  constructor: (descriptor, exercise_ui)->
    super()
    @_descriptor = descriptor
    @_exercise_ui = exercise_ui
    @_exercise_ui.on 'load_progress', @_loadProgress
    @_status = ES.init
  _loadProgress: (progress)=>  @emit(EVENTS.loading, progress)

  _checkLoading: =>
    if @_status == ES.loading
      duration = Date.now() - @_loading_start
      console.error("Exercise is still loading after ", duration / 1000.0)
      setTimeout(this._checkLoading, NOTIFY_THRESHOLD)

  _load: ->
    @_loading_start = Date.now()
    setTimeout(this._checkLoading, NOTIFY_THRESHOLD)
    @_status = ES.loading
    @emit EVENTS.loading_started, @_run_config
    await new Promise (resolve)->setTimeout(resolve, 200)
    @_unity = await @_exercise_ui.loadUI(@_descriptor.getUnityConfig())
    if @_status != ES.loading
      return
    await @_unity.loadScene(@_descriptor.scene_name)
    @emit EVENTS.loading_finished


  load: ->
    if @_loading
      return @_loading
    @_loading = new Promise (resolve) =>
      await @_load()
      LOGGER.info("Loaded")
      if @_status == ES.loading
        @_status = ES.ready
        @emit(EVENTS.ready)
      resolve()


  start: (run_config = {})->
    @_run_config = @_descriptor.getRunConfig(run_config)
    console.log "Starting with config", @_run_config
    @one EVENTS.continue, @startExercise
    @emit EVENTS.exercise_starting, @_run_config
    if @_run_config.load_delay
      await new Promise((resolve)=>setTimeout(resolve, @_run_config.load_delay))
    await @load()

  _getCalibrationConfig: ->
    isShort = @_run_config.calibration == "short"
    skipCalibration = not (@_descriptor.calibration and @_run_config.calibration)
    skipInstructions = not @_run_config.show_instructions
    skipTutorial = not @_run_config.tutorial
    config = {
      skip: {
        positioning: skipCalibration
        calibration_intro: isShort or skipInstructions
        finding_range: skipCalibration or isShort
        target_practice: skipCalibration or isShort
        detecting_movement: skipCalibration or isShort
      }
    }
    return config

  startExercise: =>
    @_status = ES.running
    await @_exercise_ui.showScene()
    launch_conf =
      type: "exercise"
      object_id: @_run_config.exercise_id
      patient: @_run_config.patient_id
    @_launch = (@_run_config.parent ? Launch).create(launch_conf)
    @_launch.addStat("areas", @_run_config.areas.sort().join(','))
    @_calibrated_position = null
    @emit(EVENTS.exercise_started, {launch: @_launch})
    calibration_config = @_getCalibrationConfig()
    if calibration_config
      await @calibrate(calibration_config)
    if @_status == ES.destroyed
      return
    @_results = []
    @_status = ES.ready
    @startRound()

  getBaseConfig: ->
    return {
      "texts": {
      },
      "round_duration": -1,
      "aspect_ratio": 1.7777777777777777,
      "scenario": []
    }

  getCalibrationConfig: ->
    base = @getBaseConfig()
    for side in ["Left", "Right"]
      base.scenario.push(
        {
          "id": side,
          "type": "Target",
          "position": {"x": -0.65, "y": 0.1},
          "scale": 0.18,
          "launch": [
            {
              "type": "after_start_of",
              "with_delay": 1000
            }
          ],
          "success_after_hover": 100,
          "timeout_after": -1
        })
    return base

  startRound: =>
    if @_status != ES.ready
      return
    @_round_launch = @_launch.create(
      type: "round",
      object_id: @_run_config.exercise_id,
      patient: @_run_config.patient_id)
    @_round_launch.addStat("level_index", @_run_config.level)
    config = await @_generateConfig()
    LOGGER.info("set round config")
    @_unity.setConfig config
    console.log config
    await @_exercise_ui.setWatcherConfig(@_descriptor.getWatcherConfig())
    @_unity.one 'finished', @_roundFinished
    @_unity.on 'game_event', @_gameEvent
    @one EVENTS.continue, @_doStart
    @emit(EVENTS.round_ready)

  _gameEvent: =>

  _doStart: =>
    PerfMonitor.instance.measureFPS()
    @_status = ES.running
    @_unity.start()
    @emit(EVENTS.round_started, {run_config: @_run_config, launch: @_round_launch})

  _generateConfig: ->
    if @_descriptor.generateRoundConfig?
      round_config = @_descriptor.generateRoundConfig(@_run_config)
    else
      round_config = await @_unity.getTestConfig()
      @_descriptor.updateTexts(round_config, @_run_config)
    return Promise.resolve(round_config)

  _roundFinished: (results) =>
    @_unity.off 'game_event', @_gameEvent
    @_unity.stop()
    @_status = ES.ready
    PerfMonitor.instance.stopMeasureFPS()
    _.extend results, _.pick(@_run_config, 'level', 'goal', 'round_duration', 'round', 'total_rounds')
    results = @_descriptor.calculateResults(@_run_config, results)
    @_round_launch.addStatistics(results)
    @_round_launch.markEnd()
    results.duration = @_round_launch.duration
    results.next_level = @getLevel(results)
    results.next_goal = @_descriptor.getGoal(_.defaults(
      level: results.next_level,
      last_level: results.level
      last_score: results.normalized_score,
      @_run_config))
    results.last_round = @_run_config.round == @_run_config.total_rounds
    results.launch_id = @_round_launch.id
    @_round_launch = null

    @emit(EVENTS.round_finished, results)
    @_results.push(results)
    if results.last_round
      @_launch.markEnd()
      @_results.calibrated = @_calibrated_position
      @emit(EVENTS.exercise_finished, @_results)
      @_exercise_ui.hideScene()
    else
      @_run_config.round += 1
      @_run_config.level = results.next_level
      @_run_config.goal = results.next_goal
      @startRound()

  getLevel: (results)->
    return Math.max(Math.min(@_run_config.level + results.level_change, @_descriptor.levels), 1)

  calibrate: ->
    return Promise.resolve()

  destroy: ->
    prev_status = @_status
    @_status = ES.destroyed
    PerfMonitor.instance.stopMeasureFPS()
    @clear()
    @_exercise_ui.off('load_progress', @_loadProgress)
    try
      @_exercise_ui.hideScene()
    catch error
      console.warn(error)
    unity = @_unity
    @_unity = null
    if unity
      unity.off 'finished', @_roundFinished
      unity.off 'game_event', @_gameEvent
      if prev_status == ES.running
        unity.stop()
        unity.loadScene("CalibrationScene").then () -> unity.suspend()

  continue: ->
    @emit(EVENTS.continue)
