import BoxingPosGenerator from "./boxing_pos_generator"
import rand from "utils/rand"
import ConfigGenerator from "exercises/config_generators/config_generator"

export default class BoxingConfigGenerator extends  ConfigGenerator
  BAGS: @BAGS = BAGS = {
    "left": {
      "x": -0.75,
      "y": -0.35,
      "width": 0.2,
      "height": 0.8
    },
    "right": {
      "x": 0.55,
      "y": -0.35,
      "width": 0.2,
      "height": 0.8
    }
  }
  @generateAreas = (right_arm_target, left_arm_target)->
    VERT_CENTER = right_arm_target.y + right_arm_target.height / 2
    AREAS = {}
    for key,pos of {
      tl: {
        x: left_arm_target.x,
        y: left_arm_target.y,
        start_point: { x: -0.2, y: 0 }
      }, # left side (arm) -> targets on right boxing bag
      tr: { x: right_arm_target.x, y: right_arm_target.y, start_point: { x: 0.2, y: 0 } },
      bl: { x: left_arm_target.x, y: VERT_CENTER, start_point: { x: -0.2, y: 0 } }
      br: { x: right_arm_target.x, y: VERT_CENTER, start_point: { x: 0.2, y: 0 } },
    }
      AREAS[key] = _.extend pos, width: right_arm_target.width, height: right_arm_target.height / 2
    return AREAS
  AREAS: @generateAreas(BAGS.left, BAGS.right)

  constructor: (level_conf, areas)->
    super(level_conf, areas)
    @_cur_time = 0
    @_probabilities = @getProbabilities(@_conf)
    @_pos_generator = new BoxingPosGenerator(@_areas, @ELEMENT_SIZE * 1.1)
    @_elements = {}

  getProbabilities: (config) ->
    single_target = (1 - config.combined_prob) * (1 - config.distractor_prob)
    return {
      Target: single_target * (1 - config.simultaneus_prob)
      NonTarget: (1 - config.combined_prob) * config.distractor_prob
      Both: config.combined_prob * (1 - config.simultaneus_prob)
      NonTargetAfterTarget: single_target * config.simultaneus_prob
      NonTargetAfterBoth: config.combined_prob * config.simultaneus_prob
    }

  @getPunchingBags: ->
    bags = _.cloneDeep(@BAGS)
    bags.left.y = -bags.left.y
    bags.right.y = -bags.right.y
    return bags
  MAX_NONTARGETS_IN_A_ROW: 2
  generateScenario: (round_duration) ->
    round_duration *= 1000
    conf = @_conf
    total_duration = 0
    nontargets_in_row = 0
    all_nontargets = 0
    all_objects = 0
    start_time = 0
    while start_time < round_duration
      nontarget_available = nontargets_in_row < @MAX_NONTARGETS_IN_A_ROW
      nontarget_available &= all_objects > 0 and all_nontargets / all_objects <= @_probabilities.NonTarget
      type = rand.weightedObj(if  nontarget_available then @_probabilities else _.omit(@_probabilities, 'NonTarget'))
      if type == "NonTarget"
        nontargets_in_row++
        all_nontargets++
      else
        nontargets_in_row = 0
      all_objects++
      if type in ["Target", "NonTarget"]
        @_addSingle(type)
      else if type == "Both"
        @_addBoth()
      else if type == "NonTargetAfterTarget"
        @_addNonTargetAfterTarget()
      else if type == "NonTargetAfterBoth"
        @_addNonTargetAfterBoth()
      else
        throw Error("Unknown!:" + type)
      start_time = Math.min _.map @_prev_id, (id) => @_elements[id].start_time
      @_id++
    return @scenario

  START_DELAY: 1000

  _getLaunchConfig: ->
    if not @_prev_id?
      return {
        config: [{
          type: "after_start_of"
          with_delay: @START_DELAY
        }]
        start_time: @START_DELAYs
      }
    start_time = Math.min _.map @_prev_id, (id) => @_elements[id].start_time
    launch_config = [
      {
        type: "after_finish_of"
        elements: @_prev_id
        with_delay: @FINISHED_DELAY
      }
    ]
    if @_conf.next_target_after > 0
      launch_config.push {
        type: "after_start_of"
        elements: @_prev_id
        with_delay: @_conf.next_target_after
      }
      start_time += Math.min(@_conf.next_target_after, @MIN_REACTION_TIME + @FINISHED_DELAY)
    else
      start_time += @MIN_REACTION_TIME + @FINISHED_DELAY
    return {
      config: launch_config
      start_time: start_time
    }

  _getSingleConfig: (type, max_duration)->
    id = type + "_" + @_id
    launch_config = @_getLaunchConfig()
    config = {
      id: id
      type: type
      scale: @ELEMENT_SIZE
      launch: launch_config.config
    }
    if type == 'NonTarget'
      config.fail_after_hover = @_conf.activation_duration
      config.success_after = @_conf.nontarget_timeout
      max_duration ?= config.success_after
    else
      config.success_after_hover = @_conf.activation_duration
      config.timeout_after = @_conf.timeout
      if @_conf.timeout >= 0
        max_duration ?= @_conf.timeout
      else
        max_duration ?= @MIN_REACTION_TIME * 10
    max_duration += @ANIMATION_DURATION * 2
    pos = @_getPos(type.toLowerCase(), launch_config.start_time, max_duration)
    time_diff = pos.start_time - launch_config.start_time
    if time_diff > 0
      for l in config.launch
        l.with_delay += time_diff
    config.position = _.pick(pos, 'x', 'y')
    config.start_time = pos.start_time
    config.end_time = pos.start_time + max_duration
    return config

  _addSingle: (type, max_duration)->
    @_add config = @_getSingleConfig(type, max_duration)
    @_prev_id = [config.id]
    return config


  _addBoth: (prev_id, max_duration) ->
    target = @_getSingleConfig("Target", max_duration)
    target.id = 'Both_' + target.id
    @_add target
    non_target = @_getSingleConfig("NonTarget")
    non_target.id = "Both_" + non_target.id
    @_add non_target
    @_prev_id = [target.id]
    return [target, non_target]

  _addNonTargetAfterTarget: ->
    target = @_addSingle("Target", @_conf.timeout + @_conf.nontarget_timeout)
    @_add @_getNonTargetForTarget(target)

  _addNonTargetAfterBoth: ->
    both = @_addBoth(@_conf.timeout + @_conf.nontarget_timeout)
    @_add @_getNonTargetForTarget(both[0])

  _getNonTargetForTarget: (target)->
    nontarget =
      id: target.id + '_NonTarget'
      type: "NonTarget"
    nontarget = _.defaultsDeep nontarget, target
    nontarget.launch = [
      type: "after_finish_of"
      elements: [target.id]
      with_delay: @FINISHED_DELAY
    ]
    nontarget.start_time = target.start_time + @MIN_REACTION_TIME + @FINISHED_DELAY
    nontarget.end_time = target.end_time + @_conf.nontarget_timeout + @ANIMATION_DURATION
    return nontarget

  _getPos: (type, start_time, max_duration)->
    for i in [1..10]
      pos = @_pos_generator.getPosition(type, start_time, start_time + max_duration)
      if pos
        pos.start_time = start_time
        return pos
      else
        start_time += 1000
    console.log @_elements

  _add: (element) ->
    scenario_element = _.omit(element, 'start_time', 'end_time')
    scenario_element.position =
      x: scenario_element.position.x, y: -scenario_element.position.y
    @scenario.push scenario_element
    @_pos_generator.addPosition(element.position, element.type.toLowerCase(), element.start_time, element.end_time)
    @_elements[element.id] = element
