import { getLevelConfigArray } from "exercises/config_generators/utils"

LEVELS = {
  level_1: {
    targets: 10,
    timeout: 0,
    range: [0, 10],
    diff: [3, 10],
    arg_range: [1, 10],
    choices_nr: 2,
    just_max: true,
    operators: '+',
  }
  level_2: { range: [0, 20], diff: [4, 20], arg_range: [2, 25] }
  level_3: { operators: '+-', }
  level_4: { arg_range: [2, 30], just_max: false, operators: '+-*', }
  level_5: { range: [0, 25], arg_range: [3, 40], }
  level_6: { range: [0, 30], diff: [4, 25], }
  level_7: { range: [0, 33], arg_range: [3, 50], }
  level_8: { timeout: 14, diff: [5, 25], arg_range: [3, 40], choices_nr: 3, }
  level_9: { timeout: 14, range: [0, 37], arg_range: [4, 45], }
  level_10: { timeout: 14, range: [0, 40], diff: [6, 30], arg_range: [5, 50], }
  level_11: { timeout: 14, range: [0, 43], arg_range: [5, 53], }
  level_12: { range: [0, 47], arg_range: [5, 57], }
  level_13: { timeout: 14, range: [0, 50], arg_range: [5, 60], }
  level_14: { timeout: 14, range: [0, 55], arg_range: [6, 60], }
  level_15: { timeout: 14, range: [0, 60], diff: [5, 30], arg_range: [7, 60], }
  level_16: { range: [0, 65], arg_range: [7, 65], }
  level_17: { range: [0, 70], diff: [4, 30], arg_range: [8, 70], }
  level_18: { range: [0, 80], diff: [4, 25], arg_range: [8, 80], choices_nr: 4, operators: '+-*/', }
  level_19: { range: [0, 85], diff: [3, 25], arg_range: [8, 85], }
  level_20: { timeout: 14, range: [0, 90], arg_range: [8, 90], }
  level_21: { timeout: 14, range: [0, 100], arg_range: [8, 100] }
  level_22: { targets: 12, timeout: 14, diff: [2, 25], arg_range: [10, 100] }
  level_23: { targets: 14, timeout: 14, diff: [1, 25], }
  level_24: { targets: 16, timeout: 14, range: [0, 105], diff: [1, 20], arg_range: [10, 105] }
  level_25: { targets: 18, timeout: 13, range: [0, 110], arg_range: [10, 110], }
  level_26: { targets: 20, timeout: 12, range: [0, 120], arg_range: [10, 120], }
  level_27: { targets: 22, timeout: 10, range: [0, 130], diff: [1, 15], arg_range: [10, 130], }
  level_28: { targets: 25, timeout: 9, range: [0, 140], arg_range: [10, 140], }
}
export default OPERATIONS_LEVELS = getLevelConfigArray(LEVELS, null, 'operations')
