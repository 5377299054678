define ['underscore'], (_) ->
  rand =
    bool: (true_probability = 0.5) -> Math.random() < true_probability

    sign: (positive_probability = 0.5) -> [-1, 1][~~rand.bool(positive_probability)]

    float: (min, max) -> Math.random() * (max - min) + min

# returns random number x: min <= x <= max
    int: (min_or_obj, max) ->
      if max is undefined
        if min_or_obj.min isnt undefined
          max = min_or_obj.max
          min = min_or_obj.min
        else
          max = min_or_obj
        min = 0
      else
        min = min_or_obj
      Math.floor(Math.random() * (max - min + 1)) + min

# random number from 0 to (array.length - 1)
    index: (array) -> Math.floor(Math.random() * array.length)

# get new index different from old one
    newIndex: (array, old_index) ->
      index = rand.int(array.length - 1) # all indexex minus old_index
      return if index >= old_index then index + 1 else index # skip old_index

    element: (array) -> array[rand.index(array)]

    elements: (array, n) -> rand.shuffleArray(array, false).slice(0, n)

    pickOne: (array) -> array.splice(rand.index(array), 1)[0]

    floatArray: (n, min = 0.0, max = 1.0) -> (rand.float(min, max) for i in [0...n])

    intArray: (n, min, max) -> (rand.int(min, max) for i in [0...n])

    key: (obj) -> rand.element(_.keys(obj))

    value: (obj) -> obj[rand.key(obj)]

    norm: (mean = 0.0, variance = 1.0) ->
      loop
        x = 2 * Math.random() - 1
        y = 2 * Math.random() - 1
        S = x * x + y * y
        break if not (S >= 1 or S == 0)
      c = Math.sqrt(variance) * Math.sqrt(-2 * Math.log(S) / S)
      return [mean + c * x, mean + c * y]

# inplace Fisher–Yates shuffle
    shuffleArrayInplace: (array) ->
      i = array.length
      if i > 0
        while --i
          j = Math.floor((i + 1) * Math.random())
          [array[i], array[j]] = [array[j], array[i]]
      return array

    shuffleArray: (array, inplace = false) -> rand.shuffleArrayInplace(if inplace then array else array[..])

    elementWithHistory: (array, history = [], max_len, shuffle = true) ->
# Return random element which is not in history.
# If there are no such elements, return one that was the long time ago
      if shuffle
        array = rand.shuffleArray(array)
      min_index = history.length
      res = undefined
      for el in array
        ind = history.lastIndexOf el
        if ind < min_index
          result = el
          min_index = ind
        if min_index == -1
          break
      history.push(result)
      while history.length > max_len
        history.shift()
      return result

    weightedFast: (weights) ->
# Returns function which can generate weighted random in logn time
      sum = 0
      weights_cum = ((sum += w) for w in weights) #generate cum weights
      return do (weights_cum, sum) -> #create scope
        -> _.sortedIndex(weights_cum, Math.random() * sum)

    weighted: (weights) ->
      sum = 0
      sum += w for w in weights
      r = Math.random() * sum
      for w, i in weights
        if w >= r
          return i
        r -= w
      return weights.length - 1

    pointInsidePath: (path, margin) ->
      bounds = path.getBounds()
      i = 0
      loop
        pos = rand.pointInsideRect(bounds, margin)
        if path.isPointInside(pos)
          if margin?
            if path.isPointInside(margin.add(pos)) and path.isPointInside(margin.mul(-1).add(pos))
              return pos
          else
            return pos
        if i++ > 20 and not path.disable_warning
          console.warning "This random is ineffective! Area is too small comparing to its bounding box"

    pointInsidePaths: (paths, margin) ->
      rand.pointInsidePath paths[rand.weighted (p.getArea() for p in paths)], margin

    pointInsideRect: (rect, margin) ->
      tl = rect.topLeft ? rect.top_left
      br = rect.bottomRight ? rect.bottom_right
      if margin?
        tl = margin.add tl
        br = margin.mul(-1).add br
      return x: rand.float(tl.x, br.x), y: rand.float(tl.y, br.y)

    elementWeighted: (array, weights) ->
      return array[rand.weighted(weights)]

    simple_normal: (mean, stdev) ->
      sum = 0
      size = 10
      sum += Math.random() for i in [0...size]
      return (sum - size) / size

    gaussian: (mean, stdev) ->
# returns function which generates random samples
      use_last = false
      return ->
        if(use_last)
          y1 = y2
          use_last = false
        else
          while true
            x1 = 2.0 * Math.random() - 1.0
            x2 = 2.0 * Math.random() - 1.0
            w = x1 * x1 + x2 * x2
            if w >= 1.0
              break
          w = Math.sqrt((-2.0 * Math.log(w)) / w)
          y1 = x1 * w
          y2 = x2 * w
          use_last = true
        retval = mean + stdev * y1
        if(retval > 0)
          return retval
        return -retval

    weightedObj: (probabilities) ->
      vals = _.values(probabilities)
      total = _.sum(vals)
      value = Math.random() * total
      for key,v of probabilities
        if v >= value
          return key
        value -= v
      return key

  test_weighted_rand = ->
    a = ['a', 'b', 'c']
    w = [1, 2, 3]
    c = {}
    c[x] = 0 for x in a
    c[rand.elementWeighted(a, w)]++ for i in [0..100000]
    wn = w[..]
    len = wn.length
    sum = 0
    sum += wn[i] for i in [0...len]
    wn[i] /= sum for i in [0...len]
    sum = 0
    sum += c[x] for x in a
    c[x] /= sum for x in a
    console.log 'Defined probabilities:', wn, 'Real probabilities:', c
  #test_weighted_rand()

  return rand
