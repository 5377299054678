import ExerciseDescriptor, { areaName } from "../exercise_descriptor"
import SYLLABLES_LEVELS from "./syllables_levels"
import syllables_mp4 from "./syllables.mp4"
import all_syllables from "./syllables.json"
import syllables_positions from "./syllables_positions.svg?inline"
import SvgPositionGenerator from "../../config_generators/svg_position_generator"
import rand from "utils/rand"
import ls from "local-storage"

splitSyllables = (syllables)->
  result = []
  for wrong in syllables.split('|')
    for syllable,i in wrong.split('-')
      if syllable
        result.push("#{syllable}": i != 0)
  return result

class SyllablesConfigGenerator extends ExerciseDescriptor::GeneratorClass
  START_DELAY: 0
  ANIMATION_DURATION: 1000
  constructor: ->
    super(arguments...)
    @_position_generator = new SvgPositionGenerator(syllables_positions, @_areas, 2, @ELEMENT_SIZE)
    @_syllables = _.mapValues(all_syllables, splitSyllables)

  generateScenario: (round_duration)->
    min_element_duration = @ANIMATION_DURATION * 2 + @MIN_REACTION_TIME
    total_elements = Math.ceil(round_duration * 1000 / min_element_duration)
    type = 'syllables_' + @_conf.syllables_type
    history =
      key: "syllables_start_" + type
    syllables = @_syllables[type]
    history.value = start = (ls(history.key) ? rand.index(syllables)) % syllables.length
    syllables = [syllables[start...]..., syllables[..start]...]
    positions = @_position_generator.getPositions(2).sort((a, b)-> b.x - a.x)
    answers = [
      {
        "value": _t("syllables.yes"),
        "size": 0.18,
        "position": { x: positions[0].x, y: -positions[0].y },
      },
      {
        "value": _t("syllables.no"),
        "size": 0.18,
        "position": { x: positions[1].x, y: -positions[1].y },
      },
    ]
    colors = if @_conf.colored_questions then ["#409B00", "#0079D0", "#6C009B", '#111111'] else ['#111111']
    for e in [0..total_elements]
      syllable = syllables.shift()
      isCorrect = _.values(syllable)[0]
      trial = {
        "id": "Syllable_" + @scenario.length,
        "syllable": _.keys(syllable)[0],
        "color": rand.element(colors),
        "background_color": "#FFFFFF",
        "timeout": if @_conf.timeout > 0 then @_conf.timeout else -1,
        "activation_duration": if @_conf.timeout > 1 or @_conf.timeout < 0 then 0.2 else 0.0,
      }
      if @scenario.length > 0
        trial.yes = _.extend { isCorrect: isCorrect }, answers[0]
        trial.no = _.extend { isCorrect: !isCorrect }, answers[1]
      @scenario.push trial
    @scenario.history = history
    return @scenario
  generateRoundConfig: (round_duration)=>
    config = super(round_duration)
    config.pointsPerTrial =
      goodAnswer: 2
      wrongAnswer: @_conf.bad_answer_penalty
    return config

available_areas = ExerciseDescriptor::available_areas.filter((a)->a.length < 3)
export default class SyllablesDescriptor extends ExerciseDescriptor
  scene_name: "Syllables"
  exercise_id: "syllables"
  GeneratorClass: SyllablesConfigGenerator
  LEVELS_CONFIG: SYLLABLES_LEVELS
  available_areas: available_areas
  instructions: [
    { video: syllables_mp4 },
    { hint: _tnoop('hints:syllables.instruction') }
  ]
  _calculateResults: (run_config, results)->
    results.correctness_score = results.score / results.max_score
    results.avg_trial_duration = results.duration / results.trials
    results.speed_score = results.trials / @getExpectedTrials(run_config)
    return results

  getExerciseClass: ->
    return `import(
      /* webpackChunkName: "syllables" */
      "./syllables_exercise")`

  FAILED_ANIMATION_DURATION: 4000
  ALLOWED_FAILURES: 1
  getExpectedTrials: (run_config)->
    { level, round_duration } =  run_config
    l_config = @_getLevelConfig(run_config)
    trial_duration = if l_config.timeout > 0 then l_config.timeout * 0.9 else 15
    trial_duration += (2 * @GeneratorClass::ANIMATION_DURATION) / 1000
    round_duration -= (@GeneratorClass::START_DELAY + @ALLOWED_FAILURES * @FAILED_ANIMATION_DURATION) / 1000
    round_duration = Math.max(round_duration, 0)
    return  round_duration / trial_duration

  getExpectedPoints: (run_config)->
    return Math.ceil(@getExpectedTrials(run_config) * 0.9) * 2
