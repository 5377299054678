import EventEmitter from "events"

export default class CustomEventEmitter extends EventEmitter
  off:->return @removeListener(arguments...)
  one: ->return @once(arguments...)
  clear: ->@removeAllListeners()
  emitAsync: (type, args...)->
    handlers = @_events[type]
    if _.isUndefined(handlers)
      return
    if _.isFunction(handlers)
      handlers = [handlers]
    promises = []
    for h in handlers
      result = h(args...)
      if result?.then
        promises.push(result.catch(->null))
    return Promise.all(promises)

export EventEmitterMixin = (obj, emitter) ->
  emitter ?= new CustomEventEmitter()
  _.extend(obj, {
    __emitter: emitter
    on: ->@__emitter.on(arguments...)
    off: ->@__emitter.off(arguments...)
    one: ->@__emitter.one(arguments...)
    once: ->@__emitter.one(arguments...)
    _emit: ->@__emitter.emit(arguments...)
  })
