try
  level_override = JSON.parse(sessionStorage.level_config)
catch
  level_override = null

export printLevelConfig = (level_config) ->
  lines = (
    "\"#{k}\": {" + ("\"#{sk}\": " + JSON.stringify(sv) for sk,sv of v).join(',\t') + "}" for k,v of level_config
  )
  return "{\n#{lines.join(',\n')}\n}"

getConfigOverrides = (level_config, config_name)->
  if sessionStorage.level_config_override
    config_name = config_name + '.level_config'
    try
      attrs = _.union((_.keys(l_conf) for n,l_conf of level_config)...)
      overriden_level_config = JSON.parse(sessionStorage[config_name])
      new_attrs = _.union((_.keys(l_conf) for n,l_conf of overriden_level_config)...)
      diff = _.difference(new_attrs, attrs)
      if diff.length
        alert("Unknonwn attrs " + diff.join(','))
      else
        level_config = overriden_level_config
    catch er
      if !sessionStorage[config_name]
        sessionStorage[config_name] = printLevelConfig(level_config)
      else alert("Parse Error: #{er} while parsing config for #{config_name}")
  return level_config

export getLevelConfigArray = (level_config_obj, defaults, config_name)->
  levels = []
  cur_config = _.defaults {}, defaults
  for name,config of getConfigOverrides(level_config_obj, config_name)
    num = parseInt(/.*_(\d+)/.exec(name)[1])
    if levels.length + 1 != num
      throw new Error("Invalid level name: #{name}. current_levels: #{levels.length}")
    cur_config = _.defaults { name: name }, config, cur_config
    levels.push(cur_config)
  return levels
