#@coffeescript2

import PerfMonitor from 'img_processing/perf_monitor'
import FaceDetectorBackend from "./face_detector_backend"
import CVFaceDetectorBackend from "./cv_face_detector"
import TFFaceDetectorBackend from "./tf_face_detector_backend"
export FACE_DETECTOR_TYPE = {'cv', 'tf', 'debug'}
export default class FaceDetector
  defaults:
    type: 'tf'
  constructor: (@_options = {}) ->
    _.defaults @_options, @defaults
    @_loading = null

  getDurations: -> PerfMonitor.durations()
  load: ()->
    if not @_loading?
      @_loading = @_load(@_options)
    return @_loading
  _load: (options)->
    if options.type == FACE_DETECTOR_TYPE.cv
      @_backend = new CVFaceDetectorBackend(options)
    else if options.type == FACE_DETECTOR_TYPE.tf
      @_backend = new TFFaceDetectorBackend(options)
    else if options.type == FACE_DETECTOR_TYPE.debug
      @_backend = new FaceDetectorBackend(options)
    else throw new Error("Unknown Face detector type:" + options.type)
    await @_backend.load()

  prepareForSize: (width, height, image_data) =>
    @_backend.prepareForSize(width, height, image_data)

  detectLandmarks: (image_data, options)->
    PerfMonitor.measure 'detect', =>
      @_backend.detectLandmarks(image_data, options)

  detectAll: (image_data, options) ->
    PerfMonitor.measure 'detect', =>
      @_backend.detectAll(image_data, options)

  detectSingle: (image_data, options) ->
    PerfMonitor.measure 'detect', =>
      @_backend.detectSingle(image_data, options)

#Performance stats:
# Full image + crop
# stats transfer:15.29 (9) draw_video:10.43 (8) get_image_data:2.00 (1) detect:0.11 (1) full_detect:83.57 (70)
# Cropped Image
# stats transfer:13.73 (18) draw_video:9.85 (10) get_image_data:1.42 (3) detect:0.35 (0) full_detect:76.04 (56)
# Full Image
# stats transfer:16.91 (13) draw_video:13.74 (12) get_image_data:1.57 (0) detect:0.26 (0) full_detect:80.10 (51)
# Full image + crop, no worker
# stats transfer:12.81 (9) draw_video:9.97 (8) get_image_data:2.50 (1) detect:0.19 (1) full_detect:61.59 (63)
# Cropped image, no worker
# stats transfer:13.91 (9) draw_video:11.74 (8) get_image_data:1.69 (1) detect:0.29 (0) full_detect:71.11 (52)


