#@coffeescript2
import * as faceapi from 'face-api.js'
import PerfMonitor from "../perf_monitor"
import FaceDetectorBackend from "./face_detector_backend"

export default class TFFaceDetectorBackend extends FaceDetectorBackend
  @MODEL_URL = __webpack_public_path__ + 'js/models'
  defaults:
    score_threshold: 0.5
  constructor: ->
    super(arguments...)
    @_canvas = @_createCanvas(160, 160, 'canvas')
    @_landmarks = @_createCanvas(160, 160, "landmarks")

  _getCanvas: (image_data) ->
    if image_data.timer?
      PerfMonitor.time('transfer', image_data.timer)
    {width: width, height: height} = image_data.crop ? image_data
    if width > @_canvas.width or height > @_canvas.height
      @_canvas = @_createCanvas(width, height)
    else
      @_canvas.getContext('2d').clearRect(0, 0, @_canvas.width, @_canvas.height)
    crop = image_data.crop ? x: 0, y: 0
    @_canvas.getContext('2d').putImageData(image_data, 0, 0, crop.x, crop.y, width, height)
    if image_data.timer?
      PerfMonitor.timeEnd('transfer')
    return @_canvas

  load: ->
    loaders = [
#      faceapi.loadSsdMobilenetv1Model(MODEL_URL),
#      faceapi.loadMtcnnModel(MODEL_URL),
      faceapi.loadTinyFaceDetectorModel(TFFaceDetectorBackend.MODEL_URL),
      faceapi.loadFaceLandmarkModel(TFFaceDetectorBackend.MODEL_URL),
#      faceapi.loadFaceLandmarkTinyModel(MODEL_URL)
#      faceapi.loadFaceRecognitionModel(MODEL_URL)

    ]
    await Promise.all(loaders)

  prepareForSize: (width, height, image_data) =>
    @_max_size = Math.floor(Math.max(width, height) / 32) * 32
    @_min_size = Math.floor(Math.min(width, height) / 32) * 32
    input_size = @_max_size
    if image_data
      @_canvas = @_getCanvas(image_data)
    else
      @_canvas = @_createCanvas(@_max_size, @_max_size)
    input_sizes = [@_min_size, @_max_size]
    for input_size in input_sizes
      options = new faceapi.TinyFaceDetectorOptions(inputSize: input_size, scoreThreshold: 0.5)
      face = await faceapi.detectAllFaces(@_canvas, options).withFaceLandmarks()
    @_input_sizes = input_sizes

  _getOptions: (img_data) ->
    optimal_input_size = Math.floor(Math.max(img_data.width, img_data.height) / 32) * 32
    input_size = _.last(@_input_sizes)
    for i_s in @_input_sizes
      if i_s >= optimal_input_size
        input_size = i_s
    return new faceapi.TinyFaceDetectorOptions(inputSize: input_size, scoreThreshold: @_options.score_threshold)

  detectSingle: (image_data, options = {}) ->
    canvas = @_getCanvas(image_data)
    face = faceapi.detectSingleFace(canvas, @_getOptions(image_data, options))
    if options.landmarks ? true
      return face.withFaceLandmarks()
    return face

  detectLandmarks: (image_data) ->
    if @_landmarks.width != image_data.width or @_landmarks.height != image_data.height
      @_landmarks.width = image_data.width
      @_landmarks.height = image_data.height
    @_landmarks.getContext('2d').putImageData(image_data, 0, 0)
    return faceapi.detectFaceLandmarks(@_landmarks).then (landmarks) ->
      detection:
        score: 1.0
        relativeBox: landmarks.align()
      landmarks: landmarks

  detectAll: (image_data, options = {})->
    canvas = @_getCanvas(image_data)
    faces = faceapi.detectAllFaces(canvas, @_getOptions(image_data, options))
    if options.landmarks ? true
      return faces.withFaceLandmarks()
    return faces
