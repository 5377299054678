import Segment from "helpers/segment"
import Point from "utils/point"
import {RectArea as Area} from "exercises/config_generators/area"
import PositionGenerator from "exercises/config_generators/position_generator"

export class ElementPos
  constructor: (type, pos, start, end, area)->
    @type = type
    @pos = new Point(pos)
    @time = {start: start, end: end}
    @area = area

export default class BoxingPosGenerator extends PositionGenerator
  AREA_CLASS: Area
  constructor: (areas, min_distance)->
    super(areas, min_distance)

  getPosition: (type, start, end)->
    active_elements = _.filter @_elements, (e)-> (e.time.start < end and e.time.end > start)
    for i in [1..10]
      area = @getAreaForTarget()
      close_elements = _.filter active_elements, (e) => area.intersects(e.pos, @_min_distance)
      pos = @_getPositionInArea(area, type, close_elements)
      if pos
        return pos

  _getPositionInArea: (area, type, elements)->
    MAX_TRIES = 50
    positions_interfering_with_targets = []
    for i in [0..MAX_TRIES]
      angle = area.angle_range.min + Math.random() * (area.angle_range.max - area.angle_range.min)
      pos = @tryToFindEmptyPosition(area, type, angle, elements)
      if pos?
        if pos.interfering
          positions_interfering_with_targets.push(pos)
        else
          return pos
    if positions_interfering_with_targets.length
      return _.sample(positions_interfering_with_targets)


  tryToFindEmptyPosition: (area, type, angle, elements)->
    elements ?= this._elements
    ray = area.getRaySegment(angle)
    if type == 'nontarget'
      ray = ray.reverse()
    segment = area.intersectWithRay(ray)
    rays = @getAvailableRays(ray, elements)
    return @_getPosition(type, rays, segment)

  _getPosition: (type, rays, segment)->
    if type == 'target'
      nontarget_is_closer_then_target = rays.nontarget.length <= rays.target.length
      if nontarget_is_closer_then_target
        pos = rays.nontarget.randomPositionOnCommonSegment(segment)
        if pos
          return pos
      else
        pos = rays.target.randomPositionOnCommonSegment(segment)
        if pos
          return pos
        else
          pos = rays.nontarget.randomPositionOnCommonSegment(segment)
          if pos
            pos.interfering = true
            return pos
    else
      pos = rays.target.randomPositionOnCommonSegment(segment)
      if pos?
        return pos
    return null

  addPosition: (pos, type, start, end)->
    @_elements.push el = new ElementPos(type, pos, start, end)
    for name,area of @_areas
      if area.intersects(el.pos, 0)
        @areaChosen(area)

