define [], ->

  sgn = (x) -> if x > 0 then 1 else if x < 0 then -1 else 0

  heaviside = (x) -> if x < 0 then 0 else 1

  clamp = (x, min = 0, max = 1) -> if x < min then min else if x > max then max else x

  mod = (n, m) -> ((n % m) + m) % m

  # returns true if a <= x <= b or b <= x <= a
  between = (x, a, b) ->
    if x == a or x == b
      true
    else if a < b and x > a and x < b
      true
    else if a > b and x > b and x < a
      true
    else
      false

  isPointInsideRectangle = (point, p1, p2) -> between(point.x, p1.x, p2.x) and between(point.y, p1.y, p2.y)

  # Re-maps a number from one range to another
  # returns a new value relative to a new range
  #  value - The value to be converted
  #  min1 - Lower bound of the value's current range
  #  max1 - Upper bound of the value's current range
  #  min2 - Lower bound of the value's target range
  #  max2 - Upper bound of the value's target range
  mapValue = (value, min1, max1, min2, max2) ->
    unitratio = (value - min1) / (max1 - min1)
    return (unitratio * (max2 - min2)) + min2

  sum = (array) ->
    return array.reduce (t, s) -> t+s

  circleDistance =(start,end,lower_bound,upper_bound)->
    data=[start,end]
    for i in [0..1]
      d = data[i]
      if d>upper_bound
        d=lower_bound+(d-upper_bound)%(upper_bound-lower_bound)
      else if d<lower_bound
        d=upper_bound-(lower_bound-d)%(upper_bound-lower_bound)
      data[i]=d
    [start,end]=data
    if end>start
      diff = end-start
      other_diff = start-lower_bound+upper_bound-end
      if other_diff<diff
        return -other_diff
      else
        return diff
    else if end < start
      diff = start-end
      other_diff = upper_bound-start+end-lower_bound
      if other_diff < diff
        return other_diff
      else
        return -diff
    return 0

  class MovingAverager
    constructor:(@_max_length)->
      @_values=[]
      @_sum=0
      @_cur_end =0
    push:(val)->
      @_sum+=val
      @_values.push(val)
      cur_len=@_values.length
      while cur_len >@_max_length+@_cur_end
        @_sum-=@_values[@_cur_end++]
      if cur_len > @_max_length*3
        @_values.splice(0,cur_len-@_max_length)
        @_cur_end=0

    reset:()->
      @_values=[]
      @_sum=0
      @_cur_end =0

    avg:->
      @_sum / Math.min(@_values.length, @_max_length)

    transform:(value)->
      @push(val)
      return @avg()

  class LowPassFilter
    constructor:(smoothing)->
      @_smoothing = smoothing
      @_lastValue = 0
      @_lastTime = null
      @_timeStep = null

    getTransformedSignal:(value, timeStep)->
      alpha =timeStep/(@_smoothing+timeStep)
      return @_lastValue + alpha*(value-@_lastValue)

    transform: (value, timestamp) ->
      if !timestamp?
        timestamp = Date.now()
      if @_lastTime?
        if timestamp != @_lastTime
          @_lastValue = @getTransformedSignal(value, timestamp-@_lastTime)
      else
        @_lastValue = value
      @_lastTime = timestamp
      return @_lastValue

  class RangeMapper
    constructor: (@src_min, @src_max, @dest_min = 0, @dest_max = 1) -> @_updateRatio()
    map: (val) -> @ratio * (val - @src_min)  + @dest_min
    setDestRange: (@dest_min, @dest_max) -> @_updateRatio()
    setSrcRange: (@src_min, @src_max) -> @_updateRatio()
    _updateRatio: ->
      @ratio = (@dest_max - @dest_min) / (@src_max - @src_min)
      return
  `
  function lineIntersect(x1,y1,x2,y2, x3,y3,x4,y4) {
    var x=((x1*y2-y1*x2)*(x3-x4)-(x1-x2)*(x3*y4-y3*x4))/((x1-x2)*(y3-y4)-(y1-y2)*(x3-x4));
    var y=((x1*y2-y1*x2)*(y3-y4)-(y1-y2)*(x3*y4-y3*x4))/((x1-x2)*(y3-y4)-(y1-y2)*(x3-x4));
    if (isNaN(x)||isNaN(y)) {
        return false;
    } else {
        if (x1>=x2) {
            if (!(x2<=x&&x<=x1)) {return false;}
        } else {
            if (!(x1<=x&&x<=x2)) {return false;}
        }
        if (y1>=y2) {
            if (!(y2<=y&&y<=y1)) {return false;}
        } else {
            if (!(y1<=y&&y<=y2)) {return false;}
        }
        if (x3>=x4) {
            if (!(x4<=x&&x<=x3)) {return false;}
        } else {
            if (!(x3<=x&&x<=x4)) {return false;}
        }
        if (y3>=y4) {
            if (!(y4<=y&&y<=y3)) {return false;}
        } else {
            if (!(y3<=y&&y<=y4)) {return false;}
        }
    }
    return true;
  }
  `
  PI_BELOW_180 = 180 / Math.PI
  PI_OVER_180 = Math.PI / 180.0
  return math={
    sgn: sgn
    clamp: clamp
    between: between
    mod: mod
    mapValue: mapValue
    RangeMapper: RangeMapper
    MovingAverager: MovingAverager
    LowPassFilter:LowPassFilter
    isPointInsideRectangle: isPointInsideRectangle
    sum: sum
    circleDistance:circleDistance
    segmentsIntersect:(x1,x2,y1,y2)->lineIntersect(x1.x,x1.y,x2.x,x2.y,y1.x,y1.y,y2.x,y2.y)
    toRadians:(degrees)->degrees* PI_OVER_180
    toDegrees:(radians)->radians * PI_BELOW_180
    rangeAtDistance: (distance, fov, percent=1) ->
      # distance in mm
      # fov in degrees
      # @returns distance in mm
      # percent - how much of the screen 'range' at distance should take
      return Math.tan(math.toRadians(fov / 2)) * distance * percent * 2
    distanceForRange: (range, fov, percent = 1) ->
      # range in mm
      # fov in degrees
      # percent - 0-1 - how much of the screen 'range' should take
      # @returns distance in mm
      return `(range / 2) / Math.tan(math.toRadians(fov / 2 * percent))`
  }
