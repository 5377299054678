define ['underscore',"require"], (_,require) ->

  console = window?.console ? {}
  console._real = true
  show_log_line=true
  class NonCriticalError extends Error

    @report:(message,extra_data)->
      _.defer ->
        throw new NonCriticalError(message,extra_data)
    constructor: (message,extra_data)->
      super(arguments...)
      err= new Error(arguments...)
      err.non_critical=true
      if _.isArray(extra_data)
        extra_data=extra_args:extra_data
      err.type = extra_data.type ? 'js_error'
      err.extra_data = _.omit(extra_data,'type')
      return err
  window?.NonCriticalError = NonCriticalError
  class FakeConsole
    @prefix=
      warn:'WRN'
      info:'INF'
      debug:"DEB"
      error:"ERR"

    constructor: ->
      @_profiles = {}
      @_fake = true

    profile: (name) ->
      prof = (@_profiles[name] ?= { name: name, calls: 0, last: null, sum: 0 })
      prof.calls++
      prof.last = (new Date()).getTime()

    profileEnd: (name) ->
      prof = @_profiles[name]
      dur = (new Date()).getTime() - prof.last
      prof.sum += dur
      @log "Profile #{name}, last #{dur}ms, avg: #{prof.sum/prof.calls}"

    log:(args...) -> console.log @_prepareLogLine(args)...

    _getLogLine: ->
      if show_log_line
        try
          show_log_line = require('settings')('logger.show_log_line')
        catch
      else
        return ''
      error = new Error('')
      if error.stack?
        stack = error.stack
        stack = stack.split("\n")
        stack = stack.splice(3)
      else
        return "\t|(Unknown)"
      i = 0
      while stack[i].match(/(console|logger).(js|coffee)/)
        i++
      return "\t|(" + stack[i].match(/[^/]+[/][^/]+\)?$/)?[0]

    _prepareLogLine: (args) ->
      #returns list whenre first element is string with all arguments (it can be printed to logdfile)
      #remaining elements are arguments  other then strings and numbers (can be viewed in dev tools)
      str = ""
      args.push(@_getLogLine())
      if window?.app_data?
        for a in args
          if _.isElement(a)
            str += a.outerHtml
          else if _.isObject(a) and not _.isArray(a)
            str += "{"
            for k, v of a
              if not _.isFunction(v)
                str += k + ":" + v + ', '
            str += "} "
          else
            str += a + ' '
        non_string=[]
        for a in args
          if not _.isString(a) and not _.isNumber(a)
            non_string.push(a)
        if non_string.length > 0
            return [str+"\n"].concat(non_string)
        else
          return [str]
      else
        return args

    _log: (method, args) =>
      log_line =  @_prepareLogLine(args)
      if not (console[method]? and console[method] != FakeConsole::[method])
        method = 'log'
        log_line[0] = FakeConsole.prefix[method] + ' ' + log_line[0]
      console[method] log_line...
      if @_history?
        a={}
        a[method]=log_line
        @_history.push(a)
      return log_line[0]

    warning: (args...) -> @_log('warn',  args)

    warn: (args...) -> @_log('warn',  args)

    info: (args...) -> @_log('info',  args)

    debug: (args...) -> @_log('debug',  args)

    error: (args...) -> @_log('error', args)

    report_error: (message,extra_args...) ->
      #log error to the console
      @error(message,extra_args...)
      error = new NonCriticalError(message,extra_args)
      _.defer -> throw error

    @install: (replace) ->
      if replace
        console.log "INSTALLING FAKE CONSOLE"
        window.console = new FakeConsole()
      else
        console.log "INSTALLING ORIGINAL CONSOLE"
        window.console = console
        for own name, m of FakeConsole::
          if name != 'log' and (window.console[name] is undefined)
            window.console[name] = m
        console.warning = console.warn

  FakeConsole
