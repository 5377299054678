define ["underscore", 'bridge/console'], (_,  FakeConsole)->


  class Logger extends FakeConsole
    @current_loggers = null
    @all_loggers = {}
    @DEBUG = 0
    @INFO = 1
    @WARNING = 2
    @WARN = 2
    @ERROR = 3
    @ALL = 100
    @start_time = window?._start_time ? (new Date()).getTime()
    @changeLevels: (levels)->
      for name, level of levels
        for logger in Logger.all_loggers[name] ? []
          logger.level = level

    constructor: (@name, level = 0)->
      super()
      (Logger.all_loggers[@name] ?= []).push(this)
      @level = level

    log: (args...)-> @_log(100, args)

    _log: (level, args)->
      cur_loggers = Logger.current_loggers
      if cur_loggers and (@name not in cur_loggers)
        return
      if _.isString(level)
        method = level.toLowerCase()
        level = Logger[level.toUpperCase()] ? 100
      else
        method = 'log'
      if level < @level
        return
      msg = []
      time = ((new Date()).getTime() - Logger.start_time) / 1000
      msg.push time.toFixed(3) + "\t"
      if @name
        msg.push @name
      msg = msg.concat(args)
      super(method, msg)

    showLoggers: (loggers)->
      if _.isString(loggers)
        loggers = [loggers]
      Logger.current_loggers = loggers


    @log: ->@::log arguments...
    @debug: ->@::debug arguments...
    @info: ->@::info arguments...
    @warning: ->@::warning arguments...
    @warn: ->@::warning arguments...
    @error: ->@::error arguments...
