import PositionGenerator from "./position_generator"
import { PredefinedArea } from "./area"
import rand from "utils/rand"

export Y_OFFSET = 0.00
export X_OFFSET = 0.08
TOP_OFFSET = 0.02
BOTTOM_MAX = 52
VERTICAL_BORDER = 11
TOP_ANGLE_MAX = 100
TOP_MAX = -0.41
SIZE = 0.18
REACH = 0.7

export POSITIONS =
  top: [{ x: 0, y: TOP_MAX + SIZE / 2 },
    { x: (X_OFFSET + REACH) / 2, y: (TOP_MAX + SIZE / 2) * 0.8 },
    { x: X_OFFSET + REACH, y: Y_OFFSET }]
  bottom: [
    { x: X_OFFSET + REACH, y: Y_OFFSET }
    { x: (X_OFFSET + REACH) * 0.85, y: 0.24 }
    { x: (X_OFFSET + REACH) * 0.7, y: 0.5 - SIZE / 2 }
  ]
  top_double: [
    { x: 0.22, y: (TOP_MAX + SIZE / 2) },
    { x: (X_OFFSET + REACH) * 0.7, y: -0.2 }
  ]
  bottom_double: [
    { x: (X_OFFSET + REACH) * 0.9, y: SIZE / 2 }
    { x: (X_OFFSET + REACH) * 0.8, y: (0.5 - SIZE / 2) * 0.9 }
  ]
mirror = (arr)-> _.map(arr, (p)->x: -p.x, y: p.y)
AREAS =
  tr: POSITIONS.top
  tl: mirror(POSITIONS.top)
  br: POSITIONS.bottom
  bl: mirror(POSITIONS.bottom)
AREAS_DOUBLE =
  tr: POSITIONS.top_double
  tl: mirror(POSITIONS.top_double)
  br: POSITIONS.bottom_double
  bl: mirror(POSITIONS.bottom_double)

export default class PredefinedPositionGenerator extends PositionGenerator
  AREA_CLASS: PredefinedArea
  @START_POINT:
    left: { x: X_OFFSET, y: Y_OFFSET + TOP_OFFSET }
    right: { x: -X_OFFSET, y: Y_OFFSET + TOP_OFFSET }
  @getPositionsByArea: (areas, positions) ->
    result_areas = {}
    if positions <= areas.length
      result_areas[ar] = [AREAS[ar][1]] for ar in areas
    else if areas.length == 1
      result_areas[ar] = AREAS[ar] for ar in areas
    else if areas.length == 2
      if positions == 3
        result_areas[ar] = [AREAS[ar][0], AREAS[ar][2]] for ar in areas
      else if positions == 4
        result_areas[ar] = AREAS_DOUBLE[ar] for ar in areas
      else if positions == 5
        result_areas[ar] = AREAS[ar] for ar in areas
    else if areas.length == 3
      if positions == 4
        result_areas[ar] = [AREAS[ar][0], AREAS[ar][2]] for ar in areas
      else
        result_areas[ar] = AREAS_DOUBLE[ar] for ar in areas
    else if areas.length == 4
      result_areas[ar] = [AREAS[ar][0], AREAS[ar][2]] for ar in areas
    return result_areas

  @getAreas: (area_list, max_positions) ->
    positions = @getPositionsByArea(area_list, max_positions)
    return _.mapValues positions, (pos, name) =>
      start_point: if name[2] == 'r' then @START_POINT.right else @START_POINT.left
      positions: pos

  constructor: (areas, min_distance)->
    super(areas, min_distance)

  getPositions: (number)->
    target_area = @getAreaForTarget()
    @areaChosen(target_area)
    answer_pos = rand.element(target_area.positions)
    elements = [{ pos: answer_pos }]
    all_positions = _.flatten(_.map(_.values(@_areas), 'positions'))
    for i in [0...number - 1]
      all_positions = @filterPositions(all_positions, elements)
      pos = rand.element(all_positions)
      if pos
        elements.push { pos: pos }
    return _.map elements, 'pos'
