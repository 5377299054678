import i18next from 'i18next';
import moment from "moment"
import Backend from 'i18next-chained-backend';
import NamespaceLoader from "./NamespaceLoader.js"
import LanguageDetector from 'i18next-browser-languagedetector';
export {i18next}

loading = null
window._t = i18next.t.bind(i18next);
window._tnoop = (v)->v
i18next.onLanguageLoaded = (func)  ->
  i18next.on 'languageChanged', func
  if i18next.language
    func()
i18next.onLanguageLoaded ()=>
  if (i18next.language == 'en-US')
    i18next.changeLanguage('en')
  else
    moment.locale(i18next.language)
    $("html").attr("lang", i18next.language)

export t = window._t
export tnoop = window._tnoop
defaultNamespace = 'main'
reported_keys = []
export default i18n_init = (options = {}) ->
  if loading is null
    loading = new Promise (resolve, reject)->
      i18n = i18next
        .use(Backend)
        .use(LanguageDetector)
      for plugin in options.plugins ? []
        i18n = i18n.use(plugin)
      options = {
        fallbackLng: ['pl'],
        nonExplicitSupportedLngs: false,
        load: "languageOnly",
        ns: [defaultNamespace, 'hints', 'moment', 'date_fns','launch']
        defaultNS: defaultNamespace
        detection: {
          lookupCookie: 'nf_lang',
          lookupLocalStorage: "language",
        }
        backend: {
          backends: [
            NamespaceLoader,
            (options.backends ? []).map((b)=>b.backend)...
          ].filter(Boolean),
          backendOptions: [
            {
            },
            (options.backends ? []).map((b)=>b.options)...
          ].filter(Boolean)
        },
        react: {
          wait: true
        }
        saveMissing: true,
        missingKeyHandler: (lng, ns, key, fallbackValue,updateMissing,options)=>
          if options?.skipMissing
            return
          if ns
            key = ns + ":" + key
          cache_key = key + fallbackValue
          if not (cache_key in reported_keys)
            reported_keys.push(cache_key)
            if process.env.NODE_ENV == 'production'
              func = 'error'
            else
              func = 'debug'
            console[func]("%cMissing Translation for key: '#{key}' in lang: #{lng}, fallbackValue: '#{fallbackValue}'", "color:red")
        options...
      }
      i18n.init(options, resolve)
  return loading

export getDateFnsLocale = ->
  return i18next.getResourceBundle(i18next.language, "date_fns")
