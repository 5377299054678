import { getLevelConfigArray } from "exercises/config_generators/utils"

LEVELS =
  level_1:
    answers: 2, slices: "v", rotation: 0, similarity: false, complexity: "low", timeout: -1
  level_2:
    slices: "vh"
  level_3:
    slices: "vhd"
  level_4:
    slices: "vh", timeout: 15
  level_5:
    answers: 3
  level_6:
    slices: "vhd"
  level_7:
    rotation: 90
  level_8:
    similarity: true
  level_9:
    timeout: 12
  level_10:
    complexity: "medium", similarity: false
  level_11:
    expected_duration: 7, similarity: true, timeout: 10
  level_12:
    rotation: 45, similarity: false, slices: "vh", timeout: 12
  level_13:
    slices: "vhd", timeout: 10
  level_14:
    similarity: true, timeout: 9
  level_15:
    answers: 4, similarity: false, slices: "vh", timeout: 10
  level_16:
    similarity: true, timeout: 8
  level_17:
    slices: "vhd", similarity: false, timeout: 7
  level_18:
    similarity: true, timeout: 6
  level_19:
    slices: "vh", rotation: 90, similarity: false, complexity: "high", timeout: 8
  level_20:
    expected_duration: 5, similarity: true, timeout: 7
  level_21:
    rotation: 45, similarity: false
  level_22:
    similarity: true, timeout: 6
  level_23:
    slices: "vhd", similarity: false
  level_24:
    similarity: true
  level_25:
    timeout: 5.5
  level_26:
    timeout: 5
  level_27:
    timeout: 4.5
  level_28:
    timeout: 4
export DEFAULTS =
  answers: 3,
  slices: "v",
  rotation: 0,
  similarity: false,
  complexity: "low",
  timeout: -1
export default HALVES_LEVELS = getLevelConfigArray(LEVELS, DEFAULTS, 'halves2areas')
