import debug_faces from './test/debug_faces.json'
import {createCanvas} from 'helpers/canvas_helper'
import { isWebWorker } from "helpers/createBrowserEnv"

export default class FaceDetectorBackend
  @isWebWorker: isWebWorker

  _createCanvas: (width, height, name = "canvas")->
    if FaceDetectorBackend.isWebWorker()
      new OffscreenCanvas(width, height)
    else
      canvas = @["_#{name}"]
      if not canvas?
        canvas = createCanvas(width, height, "face_detector_" + name)
      canvas.width = width
      canvas.height = height
      return canvas
  defaults: {}
  constructor: (options)->
    @_options = _.defaults options, @defaults

  load: -> Promise.resolve(null)
  prepareForSize: (width, height, image_data)->

  detectSingle: -> Promise.resolve(debug_faces.single)
  detectLandmarks: -> return @detectSingle()
  detectAll: ->@detectSingle().then (single)-> [single]
