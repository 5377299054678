define [], ->
  _rad2deg_coeff = 180.0 / Math.PI
  _deg2rad_coeff = Math.PI / 180.0

  class Vector
    constructor: (x, y) ->
      if typeof x is 'number' and typeof y is 'number'
        @x = x
        @y = y
      else if x?
        if x.x? and x.y?
          @y = x.y
          @x = x.x
        else if typeof x[0] is 'number' and typeof x[1] is 'number'
          @y = x[1]
          @x = x[0]
        else
          throw new Error 'Vector: unknown constructor parameters'
      else
        @x = 0.0
        @y = 0.0

    copy: -> new Vector(@x, @y)

    toObject: -> {x: @x, y: @y}

    toString: -> "(x = #{@x}, y = #{@y})"

    formatStr: (precision = 2) -> "(x = #{@x.toFixed(precision)}, y = #{@y.toFixed(precision)})"

    equals: (v) -> @x == v.x and @y == v.y

    set: (@x, @y) -> this

    zero: ->
      @x = 0
      @y = 0
      return this

    isZero: -> @x == 0 and @y == 0

    isNaN: -> isNaN(@x) or isNaN(@y)

    add: (v) ->
      @x += v.x
      @y += v.y
      return this

    sub: (v) ->
      @x -= v.x
      @y -= v.y
      return this

    mul: (n) ->
      @x *= n
      @y *= n
      return this

    div: (n) ->
      @x /= n
      @y /= n
      return this

    negate: ->
      @x = -@x
      @y = -@y
      return this

    negated: -> new Vector(-@x, -@y)

    ### scale along X and Y axes ###
    scale: (scale_x, scale_y) ->
      if scale_x instanceof Vector
        scale_y = scale_x.y
        scale_x = scale_x.x
      else if not scale_y?
        scale_y = scale_x
      @x *= scale_x
      @y *= scale_y
      return this

    dot: (v) -> @x * v.x + @y * v.y
    ### angle to positive X axis ###
    angle: -> Math.atan2(@y, @x)

    ### set angle to positive X axis, preserve length ###
    setAngle: (angle) -> @fromPolar(@length(), angle)

    ### Inverse of a vector x is the vector which gives 1
    # when multiplied by x (using the geometric product).
    # It may be obtained by inversion relative to the
    # unit circle.
    ###
    invert: -> throw new Error('Vector.invert: not implemented')

# returns the square of length
    lengthSq: -> @x * @x + @y * @y

    length: -> Math.sqrt(@x * @x + @y * @y)

    setLength: (new_len) ->
      if new_len == 0 then return @zero() # just in case curr_len is 0
      curr_len = @length()
      @x *= new_len / curr_len
      @y *= new_len / curr_len
      return this

    clampLength: (len_max, len_min) ->
      len = @length()
      if len_min? and len < len_min
        @setLength(len_min)
      else if len_max? and len > len_max
        @setLength(len_max)
      return this

    normalize: ->
      ### normalize this vector ###
      len = @length()
      @x /= len
      @y /= len
      return this

    normalized: ->
      ### returns a new, normalized vector###
      len = @length()
      return new Vector(@x / len, @y / len)

    distanceTo: (v) ->
      dx = @x - v.x
      dy = @y - v.y
      return Math.sqrt(dx * dx + dy * dy)

    radiansTo: (v) ->
      dx = @x - v.x
      dy = @y - v.y
      return Math.atan2(dy, dx)

    degreesTo: (v) -> _rad2deg_coeff * @radiansTo(v)

    rotate: (angle, origin) ->
      c = Math.cos(angle)
      s = Math.sin(angle)
      [@x, @y] =
        if origin?
          [c * (@x - origin.x) - s * (@y - origin.y) + origin.x
            s * (@x - origin.x) + c * (@y - origin.y) + origin.y]
        else
          [c * @x - s * @y
            c * @y + s * @x]
      return this

    getPerpendiculars: -> [new Vector(-@y, @x), new Vector(@y, -@x)]

    round: ->
      @x = Math.round(@x)
      @y = Math.round(@y)
      return this

    floor: ->
      @x = Math.floor(@x)
      @y = Math.floor(@y)
      return this

    ### NOTE: Math.floor(-2.3) == -3 but ~~(-2.3) == -2
    # floorFast is approximately 4 times faster than floor
    ###
    floorFast: ->
      @x = ~~@x
      @y = ~~@y
      return this

    toPolar: -> {r: @length(), phi: @angle()}

    fromPolar: (r, phi) ->
      if typeof r isnt 'number'
        phi = r.phi
        r = r.r
      @x = r * Math.cos(phi)
      @y = r * Math.sin(phi)
      return this

    @add: (a, b) -> new Vector(a.x + b.x, a.y + b.y)

    @sub: (a, b) -> new Vector(a.x - b.x, a.y - b.y)

    @mul: (v, n) -> new Vector(v.x * n, v.y * n)

    @div: (v, n) -> new Vector(v.x / n, v.y / n)

    @interpolate: (a, b, f) ->
      inv_f = 1.0 - f
      return new Vector(inv_f * b.x + f * a.x, inv_f * b.y + f * a.y)

    @angleBetween: (a, b) ->
      cos = Vector.dot(a, b) / (a.length() * b.length())
      if cos > 1
        cos = 1
      else if cos < -1
        cos = -1
      return Math.acos(cos)

    @distance: (a, b) ->
      dx = a.x - b.x
      dy = a.y - b.y
      return Math.sqrt(dx * dx + dy * dy)

    @distanceSq: (a, b) ->
      dx = a.x - b.x
      dy = a.y - b.y
      return dx * dx + dy * dy

    @fromPolar: (r, phi) ->
      if typeof r isnt 'number'
        phi = r.phi
        r = r.r
      return new Vector(r * Math.cos(phi), r * Math.sin(phi))

    @round: (v) -> new Vector(Math.round(v.x), Math.round(v.y))

    @floor: (v) -> new Vector(Math.floor(v.x), Math.floor(v.y))

    @dot: (a, b) -> a.x * b.x + a.y * b.y

    ###
    # Z component (X and Y are always 0) of the cross product of two 2D vectors
    # (with Z components = 0)
    ###
    @crossZ: (a, b) -> a.x * b.y - a.y * b.x

    ### Updates a Point to reflect the position based on the passed parameters describing an arc.
    #  origin    - the point from which to calculate the new position of this
    #  arcWidth  - a number desribing the width of the arc definining the orbital path
    #  arcHeight - a number desribing the height of the arc definining the orbital path
    #  degrees   - the position (0 to 360) describing the position along the arc to be computed
    ###
    @orbit: (origin, arcWidth, arcHeight, degrees) ->
      radians = _deg2rad_coeff * degrees
      return new Vector(origin.x + arcWidth * Math.cos(radians),
                        origin.y + arcHeight * Math.sin(radians))

    @fromPoints: (p1, p2) ->
      new Vector(p2.x - p1.x, p2.y - p1.y)
