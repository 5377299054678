import "bootstrap"
import modal from "./simple_option_sceen.html"

PROPS = ['calibration', 'fullscreen', "recording", "show_instructions", "tutorial", "fast", 'level_config_override']
FIELDS = ['level', 'areas', 'exercise', 'total_rounds', 'round_duration', 'goal', 'last_level', 'last_score',
  'level_config', 'level_configs']
export default class OptionScreen
  constructor: ->
    @_modal = $(modal)
    @_modal.appendTo('body')
  _getOptions: ->
    options =
      level: parseInt(@_modal.find('#level').val())
      areas: @_modal.find('#areas').val()
      exercise: @_modal.find('#exercise').val()
      total_rounds: parseInt(@_modal.find("#total_rounds").val())
      round_duration: parseInt(@_modal.find("#round_duration").val())
      goal: parseInt(@_modal.find('#goal').val())
      last_level: parseInt(@_modal.find("#last_level").val())
      last_score: parseInt(@_modal.find("#last_score").val())
    for prop in PROPS
      options[prop] = @_modal.find("#" + prop).is(":checked")
    for f in FIELDS
      options[f] ?= @_modal.find("#" + f).val()
    return options
  prepareLevelConfigs: (defaults) ->
    if @_modal.find("#level_config_override").prop("checked")
      @_modal.find(".collapse").addClass('show')
    if defaults.level_config_override and not defaults.level_config
      @_modal.find("#level_config").val(sessionStorage.level_config)
    configs = {}
    Object.keys(sessionStorage).filter((k)=> /\.level_config/.test(k)).forEach((key)=>
      @_modal.find("#level_configs").append("<option value='#{key}'>#{key}</option>'")
      configs[key] = sessionStorage[key]
    )
    @_modal.find('#level_configs').on 'change', (e)=>
      @_modal.find("#level_config").val(configs[$(e.target).val()])
    @_modal.find("#level_config_override").on 'change', (e) =>
      @_modal.find(".collapse").toggleClass('show', $(e.target).prop('checked'))
    if defaults.level_config_override
      @_modal.find('.modal-dialog').addClass('modal-lg')
    defaults.level_config = configs[defaults.level_configs] ? ""


  getOptions: (descriptors, defaults = {}) ->
    @_modal.find('#level').each ->
      for i in [1..28]
        $(this).append("<option>#{i}</option>")
      $(this).append("<option value='-1'>Test1</option>")
      $(this).append("<option value='-2'>Test2</option>")
      $(this).append("<option value='-3'>Test3</option>")
    @_modal.find("#exercise").html("")
    for k in _.keys(descriptors)
      @_modal.find("#exercise").append("<option value='#{k}'>#{_t("db:exercise.#{k}.name")}</option>")
    defaults = _.defaults JSON.parse(sessionStorage.simple_options ? "{}"), defaults
    for prop in PROPS
      @_modal.find("#" + prop).prop("checked", defaults[prop] ? true)
    @prepareLevelConfigs(defaults)
    for f in FIELDS
      if defaults[f]?
        @_modal.find("#" + f).val(defaults[f])

    validate = ()=>
      options = @_getOptions()
      desc = descriptors[options.exercise]
      chosen = options.areas.sort().join("")
      return _.some(desc.available_areas, (a)=>a.sort().join("") == chosen)
    await new Promise (resolve, reject) =>
      @_modal.modal(backdrop: 'static', keyboard: false)
      @_modal.find('button').on('click', ()=>
        if validate()
          resolve()
        else
          @_modal.find('.modal-footer').prepend("Niepoprawne obszary")
      )
    @_modal.modal('hide')
    options = @_getOptions()
    if options.fast and options.calibration
      options.calibration = 'short'
    sessionStorage.simple_options = JSON.stringify(options)
    if options.level_config_override
      if not sessionStorage.level_config_override
        sessionStorage.level_config_override = true
        window.location.reload()
      if sessionStorage[options.level_configs] != options.level_config
        sessionStorage[options.level_configs] = options.level_config
        window.location.reload()
    else
      if sessionStorage.level_config_override
        delete sessionStorage.level_config_override
        window.location.reload()
    options.last_score /= 100.0
    return options
