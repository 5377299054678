import { getLevelConfigArray } from "exercises/config_generators/utils"

BOXING_LEVEL_DESC =
  level_1: { timeout: -1, next_target_after: -1, distractor_prob: 0, targets: 10, combined: 0, simultaneus_prob: 0, }
  level_2: { timeout: 6.5, next_target_after: 4.5, }
  level_3: { timeout: 6, next_target_after: 4, }
  level_4: { timeout: 5.5, next_target_after: 3.5, }
  level_5: { timeout: 5, next_target_after: 3, targets: 12, }
  level_6: { timeout: 4.5, next_target_after: 3.5, distractor_prob: 0.5, }
  level_7: { timeout: 4, next_target_after: 3, targets: 14, }
  level_8: { timeout: 3.5, next_target_after: 2.5, targets: 16, }
  level_9: { timeout: 3.25, next_target_after: 2.25, }
  level_10: { timeout: 3.12, }
  level_11: { timeout: 3, next_target_after: 2, distractor_prob: 0.3, targets: 20, }
  level_12: { timeout: 2.9, }
  level_13: { timeout: 2.8, targets: 10, combined: 10, simultaneus_prob: 0.5, }
  level_14: { timeout: 2.7, }
  level_15: { timeout: 2.6, distractor_prob: 0.2, simultaneus_prob: 0.65, }
  level_16: { timeout: 2.5, next_target_after: 1.9, }
  level_17: { timeout: 2.4, next_target_after: 1.8, targets: 16, combined: 11, simultaneus_prob: 0.75, }
  level_18: { timeout: 2.3, next_target_after: 1.7, }
  level_19: { timeout: 2.2, next_target_after: 1.6, combined: 14, simultaneus_prob: 0.85, }
  level_20: { timeout: 2.1, next_target_after: 1.45, }
  level_21: { timeout: 2, next_target_after: 1.3, }
  level_22: { timeout: 1.80, next_target_after: 1.25, distractor_prob: 0.1, targets: 18, }
  level_23: { timeout: 1.60, next_target_after: 1.20, }
  level_24: { timeout: 1.40, next_target_after: 1.10, combined: 16, }
  level_25: { timeout: 1.20, next_target_after: 1.00, distractor_prob: 0.05, }
  level_26: { timeout: 1.00, next_target_after: 0.90, targets: 20, }
  level_27: { timeout: 0.90, next_target_after: 0.70, combined: 18, }
  level_28: { timeout: 0.85, next_target_after: 0.65, targets: 22, }


export DEFAULT_CONFIG = {
  timeout: -1,
  next_target_after: -1,
  distractor_prob: 0,
  combined_prob: 0,
  simultaneus_prob: 0
  activation_duration: 50
  nontarget_timeout: 2
  expected_points: 0
}
export transformBoxingLevels = (levels) ->
  for l in levels
    if l.targets? and l.combined?
      l.combined_prob = l.combined / (l.combined + l.targets)
      l.nontarget_timeout = Math.min(l.next_target_after, DEFAULT_CONFIG.nontarget_timeout)
      delete l.targets
      delete l.combined
    for k in ['timeout', 'next_target_after', 'nontarget_timeout']
      if l[k] != -1
        l[k] = Math.round(l[k] * 1000)
  return levels

export default BOXING_LEVELS = transformBoxingLevels(getLevelConfigArray(BOXING_LEVEL_DESC, DEFAULT_CONFIG, 'boxing'))
