#@coffescript2
if window?
  window.global = window
konva = require("konva")
konva.Util.getTransform = getTransform = (source_node, target_node) ->
  transform = target_node.getAbsoluteTransform().copy()
  return transform.invert().multiply(source_node.getAbsoluteTransform())

konva.Util.transformPoint = (p, source_node, target_node) ->
  return getTransform(source_node, target_node).point(p)

konva.Util.transformRect = (rect, source_node_or_transform, target_node) ->
  if source_node_or_transform instanceof konva.Transform
    transform = source_node_or_transform
  else
    transform = getTransform(source_node_or_transform, target_node)
  p1 = x: rect.x, y: rect.y
  p2 = x: p1.x + rect.width, y: p1.y + rect.height
  p1p = transform.point(p1)
  p2p = transform.point(p2)
  return x: p1p.x, y: p1p.y, width: p2p.x - p1p.x, height: p2p.y - p1p.y

konva.HEIGHT_SCALE = HEIGHT_SCALE = 720
konva.pixels = (val) -> val / HEIGHT_SCALE
export default konva
