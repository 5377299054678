import { init } from 'sentry_integration'
import "runner/unity_demo.scss"
import i18n_init,{i18next} from "i18n_init"
import Launch from "exercises/launch"
import ERROR_HANDLER from "error_reporter"
import ExerciseRunnerWithGui from "runner/exercise_runner_with_gui"
import { loadDefaultDescriptors } from "../exercises/descriptors"

run = ->
  await i18n_init()
  loadDefaultDescriptors()
  await new Promise((resolve)=>i18next.loadNamespaces(['db'], resolve))
  init()
  runner = new ExerciseRunnerWithGui()
  await runner.load(sessionStorage.show_debug ? false)
  if window.unity_url && window.unity_url != '%UNITY_WEBGL_BUILD_URL%'
    overrides = { unity_config: "/" + window.unity_url }
  else
    overrides = {
#      calibration:
#        face_calibrator:
#          fps: 20
#        visualization:
#          unity_only: false
    }
  ERROR_HANDLER.on 'fill_report', (report) =>
    report.recording = await runner.getRecordingAndRestart()
    console.log("Recording")

  while true
    options = await ExerciseRunnerWithGui.getOptions(calibration: false, fullscreen: false,
      show_instructions: false, tutorial: false)
    options.parent = new Launch.Mock()
    options.end_screen = true
    if options.recording
      options.recording = {
        video_bits_per_second: 300 * 1000
      }
      try
        audio = await navigator.mediaDevices.getUserMedia({ audio: true })
        options.recording.audio = audio
      catch
    results = await runner.start(options, overrides)
    await runner.waitForScreenEnd()

$ ->
  setTimeout(run, 500)
