#@coffeescript2
#import * as cv from 'opencv.js/opencv'
import {createCanvas} from "helpers/canvas_helper"
import BackgroundSubtractor from "./background_subtractor"
import loadOpenCV from "../opencv"
MAX_THRESHOLD = 255
class CVBackgroundSubtractor extends BackgroundSubtractor
  defaults:
    history_length: 500
    threshold: 30 / MAX_THRESHOLD #Threshold on the squared distance between the pixel and the sample to decide whether a pixel
# is close to that sample. This parameter does not affect the background update.
    shadows: true

  constructor: (width, height, params)->
    super(width, height)
    @_params = _.defaults _.pick(params, _.keys(@defaults)), @defaults
    @_canvas = createCanvas(@width, @height, 'cv_bg_src_frame')
    debug_mode = @_canvas.parentElement
    if debug_mode
      @_result_canvas = createCanvas(@width, @height, 'cv_bg_result_frame')
    @_ctx = @_canvas.getContext('2d')


  clear:->
    @_fgmask?.delete()
    @_fgbg?.delete()
    @_fgmask = new cv.Mat(@height, @width, cv.CV_8UC1)
    @_fgbg = new cv.BackgroundSubtractorMOG2(@_params.history_length, @_params.threshold * MAX_THRESHOLD, @_params.shadows)

  _get_frame: (drawImageFunction) ->
    if drawImageFunction instanceof ImageData
      image_data = drawImageFunction
    else
      drawImageFunction(@_ctx, 0, 0, @width, @height)
      image_data = @_ctx.getImageData(0, 0, @width, @height)
    return cv.matFromImageData(image_data)

  findForeground: (drawImageFunction, learning_rate = -1) ->
    super()
    @_frame?.delete()
    @_frame = @_get_frame(drawImageFunction)
    @_fgbg.apply(@_frame, @_fgmask)
    if @_result_canvas?
      cv.imshow(@_result_canvas, @_fgmask)
    return Promise.resolve()

  _getResultData: ->
    return data: @_fgmask.data, width: @_fgmask.cols, height: @_fgmask.rows

  destroy: ->
    @_canvas.remove()
    @_result_canvas?.remove()
    @_frame.delete()
    @_fgmask.delete()
    @_fgbg.delete()
  _getThreshold: ->254
  load: ->
    await loadOpenCV()
    @_frame = new cv.Mat(@height, @width, cv.CV_8UC4)
    @clear()
    Promise.resolve()
export default CVBackgroundSubtractor
