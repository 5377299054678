import { getLevelConfigArray } from "exercises/config_generators/utils"
import { SHAPES } from "./paths_shapes"

LEVELS = {
  level_1: {
    duration: 20,
    speed: 1,
    rules: [{ when: ["linia_h_medium"], then: ["linia_v_medium"] }],
    remember: 0,
    shape_diff: 0,
    async: false,
    show_warning: false,
    startDelay: 5
  },
  level_2: { duration: 30, speed: 1.2 },
  level_3: {
    duration: 35,
    speed: 1.35,
    rules: [{ when: ["linia_h"], then: ["linia_v"] }],
  },
  level_4: { duration: 40, speed: 1.5 },
  level_5: {
    duration: 45,
    speed: 1.6,
    rules: [{ when: ["linia_h", "linia_v"], then: ["skosna"] }],
  },
  level_6: { duration: 50, speed: 1.7 },
  level_7: { duration: 50, speed: 1.75 },
  level_8: {
    duration: 55,
    speed: 1.8,
    rules: [{ when: ["krzywa"], then: ["okrag"] }],
  },
  level_9: { duration: 60, speed: 1.9 },
  level_10: { duration: 60, speed: 1.95 },
  level_11: {
    speed: 2,
    rules: [
      { when: ["linia_h", "linia_v", "skosna"], then: ["krzywa", "okrag"] },
    ],
    show_warning: true,
  },
  level_12: {
    rules: [{ when: ["krzywa", "okrag", "skosna"], then: ["krzywa", "okrag"] }],
    remember: 2,
    shape_diff: 0.05,
  },
  level_13: {
    speed: 2.2,
    rules: [{ when: ["krzywa", "okrag", "flores"], then: ["klepsydra"] }],
    shape_diff: 0.1,
  },
  level_14: {
    speed: 2.25,
    rules: [{ when: ["krzywa", "okrag", "flores"], then: ["klepsydra"] }],
    shape_diff: 0.12,
  },
  level_15: {
    speed: 2.3,
    rules: [
      { when: ["krzywa"], then: ["flores"] },
      { when: ["okrag"], then: ["klepsydra"] },
    ],
    shape_diff: 0.15,
    async: 2,
  },
  level_16: { speed: 2.4, shape_diff: 0.2 },
  level_17: { speed: 2.5, shape_diff: 0.25 },
  level_18: { shape_diff: 0.3 },
  level_19: { duration: 65, speed: 2.6, shape_diff: 0.35 },
  level_20: { speed: 2.7 },
  level_21: { duration: 70, speed: 2.8, shape_diff: 0.38 },
  level_22: { duration: 75, speed: 3, shape_diff: 0.4 },
  level_23: { duration: 80, speed: 3.2, shape_diff: 0.45 },
  level_24: { duration: 85, speed: 3.4, shape_diff: 0.5 },
  level_25: { duration: 90, speed: 3.6, shape_diff: 0.55 },
  level_26: { duration: 95, speed: 3.8, shape_diff: 0.6 },
  level_27: { duration: 100, speed: 4, shape_diff: 0.65 },
  level_28: { speed: 4.2, shape_diff: 0.7 },
};
all_shapes_rules = []
prev = null
for name,config of SHAPES
  if /small/.test(name)
    continue
  if prev?
    all_shapes_rules.push({ when: [prev], then: [name] })
  prev = name
export TEST_LEVELS = getLevelConfigArray({
  level_1: {
    speed: 3,
    rules: all_shapes_rules,
    remember: 1,
    shape_diff: 0,
    async: false,
    show_warning: false,
    test: true
    trials: all_shapes_rules.length + 1
  }
  level_2: {
    shape_diff: 0.5,
  }
  level_3: {
    async: true
  }
}, null, 'paths_test')
export default getLevelConfigArray(LEVELS, null, 'paths')
