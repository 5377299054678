import { getLevelConfigArray } from "exercises/config_generators/utils"

SYLLABLES_LEVELS =
  level_1: { max_score: 6, timeout: 0, syllables_type: 2, colored_questions: false, bad_answer_penalty: 0, }
  level_2: { max_score: 8, }
  level_3: { max_score: 10, }
  level_4: { max_score: 12, }
  level_5: { max_score: 15, timeout: 15, }
  level_6: { max_score: 10, timeout: 13, }
  level_7: { timeout: 12, }
  level_8: { max_score: 12, timeout: 11, syllables_type: 3, }
  level_9: { timeout: 9, }
  level_10: { max_score: 14, }
  level_11: { max_score: 16, }
  level_12: { timeout: 8, }
  level_13: { colored_questions: true, bad_answer_penalty: -1, }
  level_14: { timeout: 7.5, }
  level_15: { timeout: 7, syllables_type: 4, }
  level_16: { timeout: 6.5, }
  level_17: { timeout: 6, }
  level_18: { timeout: 5.5, }
  level_19: { timeout: 5, }
  level_20: { timeout: 4.5, }
  level_21: { timeout: 4, }
  level_22: { max_score: 18, timeout: 3, bad_answer_penalty: -2, }
  level_23: { max_score: 20, timeout: 2, }
  level_24: { max_score: 23, timeout: 1, }
  level_25: { max_score: 25, timeout: 0.8, }
  level_26: { max_score: 28, timeout: 0.6, bad_answer_penalty: -3, }
  level_27: { max_score: 30, timeout: 0.45, }
  level_28: { timeout: 0.4, }

export DEFAULT_CONFIG = {
  timeout: 5,
  syllables_type: 2,
  colored_questions: true,
  bad_answer_penalty: 0,
}
transformSyllablesLevels = (levels)=>
  levels.forEach (conf) =>
    conf.expected_points = conf.max_score * 2
  return levels

export default STICKS_LEVELS = transformSyllablesLevels(getLevelConfigArray(SYLLABLES_LEVELS, DEFAULT_CONFIG, 'syllables'))

