import * as Comlink from 'comlink'
import * as _ from 'lodash'
Comlink.transferHandlers.set "ImageData", {
  canHandle: (obj) -> obj instanceof ImageData
  serialize: (img_data) -> [
    {
      width: img_data.width
      height: img_data.height
      buffer: img_data.data.buffer
      channels: img_data.channels
      crop: img_data.crop
      timer: img_data.timer
    }, [img_data.data.buffer]]
  deserialize: (obj) ->
      data = new ImageData(new Uint8ClampedArray(obj.buffer), obj.width, obj.height)
      if obj.crop?
        data.crop = obj.crop
      if obj.timer?
        data.timer = obj.timer
      return data
}
Comlink.transferHandlers.set "OffscreenCanvas", {
  canHandle: (obj) -> typeof OffscreenCanvas != "undefined" and obj instanceof OffscreenCanvas
  serialize: (canvas) -> [canvas, [canvas]]
  deserialize: (canvas) -> canvas
}
Comlink.transferHandlers.set "Detections", {
  canHandle: (obj) -> obj?.detection? or obj?._score or obj?[0]?.detection
  _serializeDetection: (detection) ->
    box = detection.relativeBox
    return{
      score:detection.score,
      relativeBox: _.pick(box,['x','y','width','height'])
    }
  _serializeLandmarks: (landmarks) ->
    shift: _.pick(landmarks.shift,['x','y'])
    positions: landmarks.positions.map (p)->{x:p.x,y:p.y}

  _serializeOne:(obj)->
    if obj.box?
      return @_serializeDetection(detection)
    else
      result = {}
      result.detection = @_serializeDetection(obj.detection) if obj.detection?
      result.alignedRect = @_serializeDetection(obj.alignedRect) if obj.alignedRect?
      result.landmarks = @_serializeLandmarks(obj.landmarks) if obj.landmarks?
      result.unshiftedLandmarks = @_serializeLandmarks(obj.unshiftedLandmarks) if obj.unshiftedLandmarks?
      return result
  serialize: (obj) ->
    if _.isArray(obj)
      result =  obj.map (o)=> @_serializeOne(o)
    else
      result = @_serializeOne(obj)
    return [result,[]]
  deserialize: (obj) -> obj
}
export default Comlink
