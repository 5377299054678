import ExerciseDescriptor from "../exercise_descriptor"
import MarblesConfigGenerator from "./marbles_config_generator"
import MARBLES_LEVELS from "./marbles_levels"

export default class MarblesDescriptor extends ExerciseDescriptor
  scene_name: "Marbles"
  exercise_id: "marbles"
  GeneratorClass: MarblesConfigGenerator
  LEVELS_CONFIG: MARBLES_LEVELS
  instructions: [
    { video: { slides: "marbles" } }
    { hint: _tnoop("hints:marbles.instruction") }
  ]

  _calculateResults: (run_config, results)->
    results.correctness_score = results.score / results.max_score
    results.avg_trial_duration = results.duration / results.trials
    results.speed_score = results.max_score / results.expected_points
    return results

  getExpectedPoints: (run_config)->
    l_conf = @_getLevelConfig(run_config)
    trial_duration = (l_conf.expected_trial_duration + @GeneratorClass::ANIMATION_DURATION) / 1000
    trials = run_config.round_duration / trial_duration
    return trials * (l_conf.marbles + 1)
