import ExerciseDescriptor, { areaName } from "../exercise_descriptor"
import OperationsConfigGenerator from "./operations_config_generator"
import OPERATIONS_LEVELS from "./operations_levels"


available_areas = _.filter ExerciseDescriptor::available_areas, (e)->e.length > 1
export default class OperationsDescriptor extends ExerciseDescriptor
  scene_name: "Operations"
  exercise_id: "operations"
  GeneratorClass: OperationsConfigGenerator
  LEVELS_CONFIG: OPERATIONS_LEVELS
  available_areas: available_areas
  instructions: [
    { video: { slides: "operations" } },
    { hint: _tnoop('hints:general.concentrate') }
  ]

  _calculateResults: (run_config, results)->
    results.correctness_score = results.score / results.max_score
    results.avg_trial_duration = results.duration / results.trials
    results.speed_score = results.trials / @getExpectedTrials(run_config)
    return results

  getExpectedTrials: (run_config)->
    { level, round_duration } =  run_config
    l_config = @_getLevelConfig(run_config)
    trial_duration = if l_config.timeout > 0 then l_config.timeout * 0.9 else 15
    trial_duration += (@GeneratorClass::TRIAL_PREPARE_TIME + @GeneratorClass::TRIAL_SUCCEEDED_TIME)
    return  round_duration / trial_duration

  getExpectedPoints: (run_config)->
    l_config = @_getLevelConfig(run_config)
    return @getExpectedTrials(run_config) * (l_config.choices_nr + 2) * 0.9
