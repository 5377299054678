export default class ConfigGenerator
  MIN_REACTION_TIME: 500
  ANIMATION_DURATION: 500
  FINISHED_DELAY: 1000
  ELEMENT_SIZE: 0.12
  AVG_REACTION_TIME: 1000
  AREAS:
    tl: { x: -0.8, y: -0.5, with: 0.8, height: 0.5, start_point: { x: -0.2, y: 0.0 } }
    tr: { x: 0, y: -0.5, with: 0.8, height: 0.5, start_point: { x: 0.2, y: 0.0 } }
    bl: { x: -0.8, y: 0, with: 0.5, height: 0.5, start_point: { x: -0.2, y: 0.0 } }
    br: { x: 0.3, y: 0, with: 0.5, height: 0.5, start_point: { x: -0.2, y: 0.0 } }
  DEFAULTS: {}
  constructor: (level_config, areas) ->
    @_conf = _.defaults {}, level_config, @DEFAULTS
    @_areas = areas
    @_id = 0
    @scenario = []
    @_areas = _.pick(@AREAS, areas)

  isOneSideSelected: ()->
    left = @_areas.tl != null or @_areas.bl != null
    right = @_areas.tr != null or @_areas.br != null
    return if left ^ right then if left then "left" else "right" else false

  generateScenario: (round_duration) ->
    return @scenario

  generateRoundConfig: (round_duration) ->
    return {
      aspect_ratio: 1.7777777777777777,
      round_duration: round_duration,
      scenario: @generateScenario(round_duration)
    }
