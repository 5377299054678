import { getLevelConfigArray } from "exercises/config_generators/utils"

export const LEVELS = {
  level_1: {
    randomizer_type: "mirror",
    global_timeout: -1.0,
    second_target_timeout: -1.0,
    nontarget_probability: 0.0,
    distractors_number: [0, 0],
    combined_number: 0,
  },
  level_2: { global_timeout: 22.0 },
  level_3: { global_timeout: 15.0 },
  level_4: { global_timeout: 6.0, second_target_timeout: 4.0 },
  level_5: { global_timeout: 5.5, second_target_timeout: 3.5, nontarget_probability: 0.5 },
  level_6: {
    randomizer_type: "row",
    global_timeout: 5.0,
    second_target_timeout: 2.5,
  },
  level_7: { global_timeout: 4.7, second_target_timeout: 2.3 },
  level_8: {
    global_timeout: 4.4,
    second_target_timeout: 2.15,
    distractors_number: [1, 1],
    combined_number: 3 / 20,
  },
  level_9: { global_timeout: 4.0, second_target_timeout: 2.0, combined_number: 5 / 20 },
  level_10: {
    targets: 22,
    global_timeout: 3.75,
    second_target_timeout: 1.8,
    combined_number: 7 / 20,
  },
  level_11: {
    global_timeout: 3.5,
    second_target_timeout: 1.7,
    nontarget_probability: 0.4,
    combined_number: 10 / 20,
  },
  level_12: { global_timeout: 3.25, second_target_timeout: 1.6 },
  level_13: { randomizer_type: "random", global_timeout: 3.0, second_target_timeout: 1.5 },
  level_14: { global_timeout: 2.75, combined_number: 13 / 25 },
  level_15: { global_timeout: 2.5, nontarget_probability: 0.3, distractors_number: [1, 2] },
  level_16: { global_timeout: 2.25, second_target_timeout: 1.2 },
  level_17: { global_timeout: 2.0, second_target_timeout: 1.0, combined_number: 14 / 28 },
  level_18: { global_timeout: 1.8, nontarget_probability: 0.2 },
  level_19: { global_timeout: 1.65, second_target_timeout: 0.6 },
  level_20: { global_timeout: 1.5, combined_number: 16 / 30 },
  level_21: { global_timeout: 1.35, second_target_timeout: 0.5, nontarget_probability: 0.1 },
  level_22: { global_timeout: 1.2, combined_number: 16 / 32 },
  level_23: { global_timeout: 1.1, second_target_timeout: 0.45, combined_number: 18 / 32 },
  level_24: { global_timeout: 1.0, combined_number: 18 / 35 },
  level_25: { global_timeout: 0.9, second_target_timeout: 0.35, combined_number: 10 / 38 },
  level_26: { global_timeout: 0.75, combined_number: 10 / 40 },
  level_27: { global_timeout: 0.6, second_target_timeout: 0.25, combined_number: 10 / 44 },
  level_28: { global_timeout: 0.5, combined_number: 10 / 48 },
}
const DEFAULTS = {
  randomizer_type: "mirror",
  global_timeout: -1.0,
  second_target_timeout: -1.0,
  nontarget_probability: 0.0,
  distractors_number: [0, 0],
  combined_number: 0,
  target_score: 2,
  nontarget_score: 0,
  timeout_score: 0,
}
const MACHINE_LEVELS = getLevelConfigArray(LEVELS, DEFAULTS, "machine")
export default MACHINE_LEVELS
