import ExerciseDescriptor from "../exercise_descriptor"


export default class LoaderDescriptor extends ExerciseDescriptor
  scene_name: "LoadingScene"
  exercise_id: "loader"
  calibration: null
  levels: 1
  LEVELS_CONFIG: [{}]
  generateRoundConfig: null
  getExerciseClass: -> `import(
    /* webpackChunkName: "loading_exercise" */
    "./loading_exercise")`
