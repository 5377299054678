#@coffeescript2
export default class AnimationFrameRequester
  constructor: (callback, fps = 15)->
    @_delay = 1000 / fps
    @_callback = callback
    @_req = null

  _pause: =>
    @_paused = true
    $(window).one 'focus', @_unpause
  _unpause: =>
    @_paused = false
    if @_req
      $(window).one 'blur', @_pause

  start: (fps = null) ->
    if fps?
      @_delay = 1000 / fps
    if @_req == null
      @_last_time = 0
      @_req = requestAnimationFrame(@_runCallback)
      if not  sessionStorage.no_blur?
        if document.hasFocus()
          $(window).one 'blur', @_pause
        else
          @_pause()

  isRunning:->
    return @_req?

  stop: ->
    cancelAnimationFrame(@_req)
    @_req = null

  _runCallback: =>
    @_req = requestAnimationFrame(@_runCallback)
    if @_paused or @_working
      return
    now = Date.now()
    if now > @_last_time + @_delay
      @_execute(now)

  _execute: (now) =>
    if @_working
      return
    now ?= Date.now()
    @_working = true
    result = @_callback(now - @_last_time)
    finished = @_doneCallback(now)
    if result.then?
      result.then finished
    else
      finished(result)

  forceRun:->
    if @isRunning()
      requestAnimationFrame(@_execute)

  _doneCallback: (now)=>
    return (res) =>
      if res!= false
        @_last_time = now
      @_working = false

  setFps: (fps) ->
    @_delay = 1000 / fps
