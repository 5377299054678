import ExerciseRunner from "./exercise_runner"
import OptionScreen from "./screens/simple_option_screen"
import ExerciseScreens from "./exercise_screens"
import DESCRIPTORS from "exercises/descriptors/index"
import {EVENTS} from "exercises/base_exercise"

export default class ExerciseRunnerWithGui extends ExerciseRunner
  connectEvents: (exercise, options) =>
    @_screens = new ExerciseScreens(@_container, options)
    @_screens.connectEvents(exercise)
    if options.end_screen
      @_screens.connectExerciseFinishScreen(exercise)
    if options.browser_check ? true and not @isProperChromium()
      await @_screens.showChromiumInfo(=> @showFullScreen())

  waitForScreenEnd: ->
    await @_screens.showing_screen

  @getOptions: (defaults)->
    option_screen = new OptionScreen()
    return option_screen.getOptions(_.mapValues(DESCRIPTORS, (Klass)=>new Klass()), defaults)
