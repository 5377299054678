import ExerciseDescriptor from "../exercise_descriptor"
import BoxingConfigGenerator from "./boxing_config_generator"
import BOXING_LEVELS from "./boxing_levels"

export default class BoxingDescriptor extends ExerciseDescriptor
  scene_name: "Boxing"
  exercise_id: "boxing"
  levels: BOXING_LEVELS.length
  instructions: [
    { video: { slides: "boxing" } },
    { hint: _tnoop("hints:boxing.instruction") }
  ]
  LEVELS_CONFIG: BOXING_LEVELS
  GeneratorClass: BoxingConfigGenerator

  generateRoundConfig: (run_config) ->
    config = super(run_config)
    config.punching_bags = @GeneratorClass.getPunchingBags()
    return config

  _calculateResults: (run_config, results) ->
    results.correctness_score = results.score / results.max_score
    results.avg_trial_duration = results.duration / results.max_score
    results.speed_score = results.max_score / results.expected_points
    return results

  OLD_LEVEL_UP_THRESHOLD: 0.9

  getExpectedPoints: (run_config) ->
    level_conf = @_getLevelConfig(run_config)
    expected_reaction_time = if level_conf.next_target_after < 0 then 10 else (level_conf.next_target_after / 1000)
    duration = run_config.round_duration - (@GeneratorClass::ANIMATION_DURATION + @GeneratorClass::START_DELAY) / 1000
    nontargets_time = duration * (1 - level_conf.combined_prob) * level_conf.distractor_prob
    expected_targets = (duration - nontargets_time) / expected_reaction_time
    return expected_targets * 2 * @OLD_LEVEL_UP_THRESHOLD
