define ['jquery'], ($) ->

  # Inspiration from http://qt-project.org/doc/qt-4.8/qeasingcurve.html

  # each function must accept parameter t from 0 to 1
  # each function must fulfill the following conditions:
  # f(0) = 0 and f(1) = 1

  easeFloor = (t) -> if t >= 1.0 then 1.0 else 0.0

  easeStep = (t) -> if t < 0.5 then 0.0 else 1.0

  easeLinear = (t) -> t

  easeInQuad = (t) -> t*t

  easeOutQuad = (t) -> -t*(t-2)

  easeInOutQuad = (t) ->
    t *= 2.0
    if t < 1
      return t*t/2
    else
      t -= 1
      return -0.5 * (t*(t-2) - 1)

  easeOutInQuad = (t) ->
    if t < 0.5
      return easeOutQuad(t*2) / 2
    else
      return easeInQuad((2*t)-1) / 2 + 0.5

  easeInCubic = (t) -> t*t*t

  easeOutCubic = (t) ->
    t -= 1.0
    return t*t*t + 1

  easeInOutCubic = (t) ->
    t *= 2.0
    if t < 1
      return 0.5*t*t*t
    else
      t -= 2.0
      return 0.5*(t*t*t + 2)

  easeOutInCubic = (t) ->
    if t < 0.5
      return easeOutCubic(2*t) / 2
    else
      return easeInCubic(2*t - 1) / 2 + 0.5

  easeInQuart = (t) -> t*t*t*t

  easeOutQuart = (t) ->
    t -= 1.0
    return -(t*t*t*t - 1)

  easeInOutQuart = (t) ->
    t *= 2
    if t < 1
      return 0.5*t*t*t*t
    else
      t -= 2.0
      return -0.5*(t*t*t*t - 2)

  easeOutInQuart = (t) ->
    if t < 0.5
      return easeOutQuart(2*t) / 2
    else
      return easeInQuart(2*t-1) / 2 + 0.5

  easeInQuint = (t) -> t*t*t*t*t

  easeOutQuint = (t) ->
    t -= 1.0
    return t*t*t*t*t + 1

  easeInOutQuint = (t) ->
    t *= 2.0
    if t < 1
      return 0.5*t*t*t*t*t
    else
      t -= 2.0
      return 0.5*(t*t*t*t*t + 2)

  easeOutInQuint = (t) ->
    if t < 0.5
      return easeOutQuint(2*t) / 2
    else
      return easeInQuint(2*t-1) / 2 + 0.5

  easeInSine = (t) -> if t == 1.0 then 1.0 else -Math.cos(t*0.5*Math.PI) + 1.0

  easeOutSine = (t) -> Math.sin(t*0.5*Math.PI)

  easeInOutSine = (t) -> -0.5 * (Math.cos(Math.PI*t) - 1)

  easeOutInSine = (t) ->
    if t < 0.5
      return easeOutSine(2*t) / 2
    else
      return easeInSine(2*t - 1) / 2 + 0.5

  easeInExpo = (t) -> if (t == 0 or t == 1.0) then t else Math.pow(2.0, 10*(t-1))-0.001

  easeOutExpo = (t) -> if t == 1.0 then 1.0 else 1.001*(-Math.pow(2.0, -10*t)+1)

  easeInOutExpo = (t) ->
    if t == 0.0 then return 0.0
    if t == 1.0 then return 1.0
    t *= 2.0
    if t < 1
      return 0.5 * Math.pow(2.0, 10 * (t - 1)) - 0.0005
    else
      return 0.5 * 1.0005 * (-Math.pow(2.0, -10 * (t - 1)) + 2)

  easeOutInExpo = (t) ->
    if t < 0.5
      return easeOutExpo(2*t) / 2
    else
      return easeInExpo(2*t - 1) / 2 + 0.5

  easeInCirc = (t) -> -(Math.sqrt(1-t*t)-1)

  easeOutCirc = (t) ->
    t -= 1.0
    return Math.sqrt(1-t*t)

  easeInOutCirc = (t) ->
    t *= 2.0
    if t < 1
      return -0.5 * (Math.sqrt(1 - t*t) - 1)
    else
      t -= 2.0
      return 0.5 * (Math.sqrt(1 - t*t) + 1)

  easeOutInCirc = (t) ->
    if t < 0.5
      return easeOutCirc(2*t) / 2
    else
      return easeInCirc(2*t - 1) / 2 + 0.5

  # quadric bezier curve with weighted central point
  # first point is in (0,0)
  # central point is in (1,0)
  # third point is in (1,1)
  easeInQuadBezier = (t, w=1) -> (-t*t) / (2*t*(t-1)*(w-1) - 1)

  # quadric bezier curve with weighted central point
  # first point is in (0,0)
  # central point is in (0,1)
  # third point is in (1,1)
  easeOutQuadBezier = (t, w=1) -> (t*(2*w*(t-1)-t)) / (2*t*(t-1)*(w-1)-1)

  # we start with:
  #   f(t) = a + 1/(b + t^w)
  #   f(0) = 1
  #   f(1) = 0
  # now we have:
  #   1 = a + 1/b
  #   0 = a + 1/(b+1)
  # solutions are:
  #   1) a = 0.5 * (1 - sqrt(5)), b = 0.5 * (sqrt(5)-1)
  #   2) a = 0.5 * (1 + sqrt(5)), b = 0.5 * (-1-sqrt(5))
  # solution (1) is In, solution (2) is Out
  easeInNonRationalHyperbolical = (t, w=1) ->
    if t == 0.0 then return 0.0
    if t == 1.0 then return 1.0
    a = 0.5*(1-Math.sqrt(5))
    b = 0.5*(-1+Math.sqrt(5))
    t = 1-t
    return a + 1/(b+Math.pow(t,w))

  easeOutNonRationalHyperbolical = (t, w=1) ->
    if t == 0.0 then return 0.0
    if t == 1.0 then return 1.0
    a = 0.5*(1+Math.sqrt(5))
    b = 0.5*(-1-Math.sqrt(5))
    t = 1-t
    return a + 1/(b+Math.pow(t,w))

  class TimeLine
    constructor: (stops) ->
      # Possible inputs:
      #  {start:1,stop:5,easing:"easeLinear"} - linear easing between 1 and 5
      #  [{at:1,val:1},{at:5,val:5,easing:"easeLinear"}] - linear easing between 1 and 5
      if stops.start? and stops.stop?
        stops = [{at:stops.start,val:stops.start,easing:stops.easing},{at:stops.stop,val:stops.stop}]
      else if stops.at?
        stops = [stops]
      if stops.length == 0
        throw new Error "No Stops defined"
      @_stops = []
      for s in stops
        easing = easing_curves[s.easing ? 'easeFloor']
        stop = {at:s.at,val:s.val}
        if s.args?
          do (easing,s) ->
            stop.easing = (t) -> easing(t,s.args...)
        else
          stop.easing = easing
        @_stops.push(stop)
      @value.timeline=@
      return @value
    value: (val) =>
      i = 0
      last = null
      while i < @_stops.length and @_stops[i].at < val
        last = @_stops[i]
        i++
      if i >= @_stops.length
        return last.val
      if i == 0
        return @_stops[i].val
      cur = @_stops[i]
      diff = cur.at - last.at
      return last.val + cur.easing((val-last.at)/diff)*(cur.val-last.val)

  createCustom = (stops) ->
    return new TimeLine(stops)

  easing_curves =
    easeFloor:easeFloor
    easeStep:easeStep
    easeLinear:easeLinear

    easeInQuad:easeInQuad
    easeOutQuad:easeOutQuad
    easeInOutQuad:easeInOutQuad
    easeOutInQuad:easeOutInQuad

    easeInCubic:easeInCubic
    easeOutCubic:easeOutCubic
    easeInOutCubic:easeInOutCubic
    easeOutInCubic:easeOutInCubic

    easeInQuart:easeInQuart
    easeOutQuart:easeOutQuart
    easeInOutQuart:easeInOutQuart
    easeOutInQuart:easeOutInQuart

    easeInQuint:easeInQuint
    easeOutQuint:easeOutQuint
    easeInOutQuint:easeInOutQuint
    easeOutInQuint:easeOutInQuint

    easeInSine:easeInSine
    easeOutSine:easeOutSine
    easeInOutSine:easeInOutSine
    easeOutInSine:easeOutInSine

    easeInExpo:easeInExpo
    easeOutExpo:easeOutExpo
    easeInOutExpo:easeInOutExpo
    easeOutInExpo:easeOutInExpo

    easeInCirc:easeInCirc
    easeOutCirc:easeOutCirc
    easeInOutCirc:easeInOutCirc
    easeOutInCirc:easeOutInCirc

    # all functions below this line accept some parameter w

    # default w is 1.0
    # 0.01 < w < 20
    easeInQuadBezier:easeInQuadBezier
    easeOutQuadBezier:easeOutQuadBezier

    # default w is 1.0
    # 1.0 < w < 10
    easeInNonRationalHyperbolical:easeInNonRationalHyperbolical
    easeOutNonRationalHyperbolical:easeOutNonRationalHyperbolical

    pulseSine:(t)-> (Math.sin(2*t*Math.PI-Math.PI/2)+1)/2
    pulseLinear:(t)->
      t*=2
      return if t>1 then 2-t else t

  getFunc = (f) ->
    if typeof f is 'string'
      return easing_curves['ease' + f]
    else if $.isFunction(f)
      return f
    else
      return null

  getEasingFunc = (f, default_f) ->
    ff = getFunc(f)
    return if ff? then ff else getFunc(default_f)

  exports =
    createCustom:createCustom
    getEasingFunc:getEasingFunc

  # add easing_curves to exports
  for own f_name, func of easing_curves
    exports[f_name] = func

  return exports
