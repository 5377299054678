import 'fullscreen-polyfill'
import Camera from 'img_processing/camera'
import FrontendBridge from '../unity/frontend_bridge'
import ImageProcessor from 'img_processing/image_processor'
import { FACE_DETECTOR_TYPE } from "img_processing/face/face_detector"
import VideoCropper from "img_processing/video_cropper"
import GameStage from "img_processing/konva/game_stage"
import CustomEventEmitter from "helpers/event_helper"
import "./_exercise_ui.scss"
import Logger from "utils/logger"
import CombinedRecorder from "combined_recorder"
import "bootstrap"
import fullscreen_modal from "./screens/fullscreen_dialog.html"

LOGGER = new Logger('exercise_ui', Logger.INFO)

export default class BareExerciseUI extends CustomEventEmitter
  defaults:
    frontend:
      mock: false
    aspect_ratio: 16 / 9
    max_resolution:
      frontend:
        width: 1920
        height: 1080
      camera:
        width: 1280
        height: 720
      stage:
        width: 1920
        height: 1080
    load_camera: true
    show_debug: sessionStorage.show_debug

  TEMPLATE = _.template """
<div class='aspect-ratio'>
  <div class='exercise-area'>
    <div class='game_stage cover'></div>
    <div id='${container_id}_unity' class='unity_container'></div>
  </div>
</div>
"""
  constructor: (container, params = {}) ->
    super()
    @_params = _.defaults params, @defaults
    @_prepareDOM(container)
    @_processing = @_initProcessing()
    @_loaded = false

  loadUI: (url, params)->
    if @_current_frontend != url
      @_current_frontend = url
      @_frontend_loading = @_loadUI(url, params)
    else
      if @_loaded
        await @_loadCamera(true)
        @_loadProgress(1.0)
    return @_frontend_loading

  _loadUI: (url) ->
    if @_frontend?
      @_frontend.unload?()
    @_loaded = false
    Frontend = if url == 'mock' then FrontendBridge.Mock else FrontendBridge
    if _.isObject(url)
      @_frontend = url
    else
      @_frontend = new Frontend(@_unity_container.attr('id'), url, @_loadProgress)
    @_frontend.on 'finished', ->LOGGER.info 'GameFinished'
    @_frontend.on 'load_progress', @_loadProgress
    LOGGER.info("Loading frontend")
    await @_frontend.load()
    LOGGER.info("Frontend loaded. Loading processing")
    await @_processing
    LOGGER.info("Processing Ready")
    @_frontend.on 'paused', ->LOGGER.info "GamePaused"
    @_frontend.on 'game_event', @_gameEvent
    @_frontend.on 'watch_rect', (element_id, rect) =>
      rect.x -= rect.width / 2
      rect.y -= rect.height / 2
      @_watcher.watchRect(element_id, rect, {})
      LOGGER.debug 'watchRect', element_id, rect
    @_frontend.on 'stop_watching_rect', (element_id) =>
      @_watcher.stopWatching(element_id)
    @_frontend.on 'scene_loaded', =>
      @_watcher.stop()
    @emit('loaded')
    @_frontend.backendReady()
    @_container.removeClass('loading')
    if not sessionStorage.no_blur
      $(window).on 'blur', =>@_frontend?.suspend()
      $(window).on 'focus', =>@_frontend?.resume()
    @_frontend.suspend()
    if @_sizes?.frontend
      @_frontend.setSize(@_sizes.frontend.width, @_sizes.frontend.height)
    @_loaded = true
    return @_frontend

  _prepareDOM: (container) =>
    @_container = $(container)
    container_id = @_container.attr('id') ? 'game'
    @_container.addClass('exercise_ui')
    @_container.append TEMPLATE(container_id: container_id)
    @_inner = @_container.find('.aspect-ratio')
    @_exercise_area = @_container.find('.exercise-area')
    @_unity_container = @_container.find('.unity_container')
    @_container.on 'dblclick', @_toggleFullscreen
    $(window).on 'resize', _.debounce(@_updateScale, 100)
    @_updateScale()
    @_container.on 'fullscreenchange', _.debounce(@_fullScreenChange, 100)

  _fullScreenChange: =>
    @_container.toggleClass('fullscreen', document.fullscreenElement?)
    @_updateScale()

  _toggleFullscreen: =>
    if not document.fullscreenElement?
      await @enableFullScreen()
    else
      await @disableFullScreen()

  _loadProgress: (percent) =>
    @emit 'load_progress', percent
    if percent >= 1.0
      @_updateScale()
      @_container.addClass('unity_ready')

  _updateHeight: =>
    @_inner.css 'max-width': Math.ceil(@_params.aspect_ratio * @_container.height())

  MARGIN = 1.02

  _getSizeAndScale: (max_resolution, viewport_size)->
    aspect_ratio = max_resolution.width / max_resolution.height
    height = Math.min(viewport_size.height, viewport_size.width / aspect_ratio, max_resolution.height)
    width = Math.min(max_resolution.width, Math.floor(height * aspect_ratio))
    scaleX = Math.min(viewport_size.width / width, viewport_size.height / height * MARGIN)
    scaleY = Math.min(viewport_size.height / height, scaleX * MARGIN)
    return {
      width: width
      height: height
      scale:
        x: scaleX
        y: scaleY
    }
  _updateScale: =>
#    @_updateHeight()
    viewport_size =
      width: @_container.width()
      height: @_container.height()
    max_size = @_getSizeAndScale(width: @_params.aspect_ratio * 4000, height: 4000, viewport_size)
    @_sizes =
      frontend: @_getSizeAndScale(@_params.max_resolution.frontend, max_size)
      camera: @_getSizeAndScale(@_params.max_resolution.camera, max_size)
      stage: @_getSizeAndScale(@_params.max_resolution.stage, max_size)
    @_ex_area_size ?= { max_size... } #save size for smooth scaling
    inner_size =
      width: Math.ceil(max_size.width * max_size.scale.x)
      height: Math.ceil(max_size.height * max_size.scale.y)
    @_inner.css inner_size
    @_exercise_area.css
      width: @_ex_area_size.width
      height: @_ex_area_size.height
      transform: "scale(#{(inner_size.width + 1) / @_ex_area_size.width},#{(inner_size.height + 1) / @_ex_area_size.height})"


    @_camera?.setResolution(@_sizes.camera.width, @_sizes.camera.height)
    @stage?.setSize(@_sizes.stage.width, @_sizes.stage.height, @_ex_area_size)
    @_cropper?.resize(parent_size: @_ex_area_size)
    if @_frontend?
      @_frontend_loading.then =>
        @_frontend.setSize(@_sizes.frontend.width, @_sizes.frontend.height, @_ex_area_size)

  _loadCamera: (resume = false, shouldAlert = true)->
    while true
      try
        if (resume)
          await @_camera.resume()
        else
          await @_camera.load()
        break
      catch error
        if shouldAlert
          console.log(await Camera.getAvailableCameras())
          LOGGER.error error
          alert(_t("camera.no_access_error"))
          await new Promise((resolve)=>setTimeout(resolve, 5000))
        else
          throw error


  _initProcessing: ->
    @_camera = @_params.camera ?  new Camera()
    @_exercise_area.append(@_camera.video)
    if @_params.load_camera
      await @_loadCamera()
    await new Promise (resolve) =>
      _.defer => #Wait for css load in development
        @stage = new GameStage(@_container.find('.game_stage'))
        @_cropper = new VideoCropper(@_camera, @stage.game_node, @_params.aspect_ratio)
        await @_loadImageProcessor()
        @_watcher = @_img_processor.rect_watcher
        @_watcher.on 'rect_changed', (args...)=> @_frontend.rectChanged(args...)
        if @_params.show_debug
          @stage.game_node.add(@_watcher.node)
        resolve()

  _loadImageProcessor: ->
    params =
      face_detector:
        type: FACE_DETECTOR_TYPE.tf
      rect_watcher:
        subtractor: 'cv'
        autostart: true
        autostop: false
        history: 5 * 15
    @_img_processor = new ImageProcessor(@_cropper, params)
    await @_img_processor.load()
    LOGGER.info("Img processor loaded")

  _gameEvent: (event) =>
    LOGGER.info "GameEvent", event
    name = event.name ? event.event_name

  setWatcherConfig: (config)->
    await @_img_processor.setWatcherConfig(config)


  enableFullScreen: (force) ->
    try
      if document.fullscreenElement != @_container[0] and document.fullscreenEnabled
        await @_container[0].requestFullscreen()
    catch error
      if force
        modal = $(fullscreen_modal)
        modal.appendTo(@_container)
        await new Promise (resolve) =>
          modal.on('hidden.bs.modal', resolve)
          modal.find('#yes_button').on 'click', =>
            await @enableFullScreen(false)
          modal.modal('show')
        modal.remove()
    try
      await screen.orientation.lock("landscape")
      @_locked = true
    catch error
    return document.fullscreenElement == @_container[0]

  disableFullScreen: ->
    if document.fullscreenElement?
      try
        if @_locked
          screen.orientation.unlock()
      await document.exitFullscreen()

  addClass: ->@_container.addClass(arguments...)
  removeClass: ->@_container.removeClass(arguments...)
  toggleClass: ->@_container.toggleClass(arguments...)
  find: ->@_container.find(arguments...)

  createCalibrationComponents: (params, CalibrationComponents)->
    components = new CalibrationComponents(@_cropper, @stage, @_inner, params)
    await components.load()
    return components

  _transition: (start_func)->
    return new Promise (resolve) =>
      @_container.one "transitionend", resolve
      start_func()

  showScene: ->
    @_frontend.resume()
    if not @_container.hasClass('in_game')
      await @_transition => @addClass('in_game')
      @hideMouse()
      if @_params.show_debug
        @stage.startRedraw()

  hideScene: ->
    if @_loaded
      @_frontend.suspend()
      @_camera.pause()
      @stage.stopRedraw()
    @_container.off '.hideMouse'
    @removeClass('in_game')

  toggleCalibration: (calibration)->
    if calibration
      @stage.startRedraw()
      @addClass('calibrating')
    else
      if !@_params.show_debug
        @stage.stopRedraw()
      @removeClass('calibrating')

  hideMouse: ->
    hide_cursor = _.debounce (=>@_container.addClass('hide_cursor')), 2000
    show_cursor = (event) =>
      mousePos = x: event.screenX, y: event.screenY
      if _.isEqual(mouseMove, @_prevPos)
        return
      @_prevPos = mousePos
      @_container.removeClass('hide_cursor')
      hide_cursor()
    mouseMove = _.throttle show_cursor, 300
    $(@_container).on 'mousemove.hideMouse', mouseMove

  loadCamera: ->
    return @_camera.load()
  createRecorder: (options) ->
    return new CombinedRecorder(@_cropper, @_frontend, [], options)

  destroy: ->
    @disableFullScreen()
    @hideScene()
    @_container.remove()
