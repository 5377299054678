#@coffeescript2
import BareExerciseUI from "runner/bare_exercise_ui"
import * as Comlink from "comlink"
import TMachine from "utils/tmachine"
import {EVENTS} from "exercises/base_exercise"
import {getDescriptor} from "exercises/descriptors/index"
import settings from "helpers/settings"
import {i18next} from "i18n_init"

settings.create('show_debug', false)
export default class ExerciseRunner
  constructor: (container, params = {}) ->
    @_params = params
    if not container
      container = $('<div class="runner"></div>')
      container.prependTo('body')
    else
      container = $(container)
    @_container = container
    TMachine.installEvents(window)

  load: ()->
    if settings.show_debug
      @_container.append('<div id="debug_container">')
      @_container.addClass('debug')
    if @exercise_ui
      return
    @exercise_ui = new BareExerciseUI(@_container, @_params.exercise_ui)
    return null

  start: (options, overrides = {})->
    if not @exercise_ui?
      @load()
    await new Promise((resolve)=>i18next.loadNamespaces(['db'], resolve))
    overrides = _.extend overrides, _.pick(options, 'instructions'), options.overrides
    exercise = await @createExercise(options.exercise, overrides)
    if (options.exerciseSetupHook)
      options.exerciseSetupHook(exercise)
    await @startExercise(exercise, options)


  createExercise: (name, overrides) ->
    desc = getDescriptor(name, overrides)
    return desc.createExercise(@exercise_ui)

  showFullScreen: ->
    @exercise_ui.enableFullScreen()

  loadCamera: ->
    return @exercise_ui.loadCamera()

  MINIMUM_CHROME_VERSION = 71
  isProperChromium: ->
    raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    if raw
      version = parseInt(raw[2], 10)
      if version > MINIMUM_CHROME_VERSION
        return true
    return false

  startExercise: (exercise, options) ->
    @_current_exercise = exercise
    return new Promise (resolve, reject) =>
      @_abort = reject
      if options.recording
        exercise.on EVENTS.ready, =>
          if !@recorder?
            @recorder = @exercise_ui.createRecorder(options.recording)
            await @recorder.start()
          if not (options.recording.recordCalibration ? true)
            @recorder.pause()
        exercise.on EVENTS.exercise_started, ({launch})=>
          @recorder.setExerciseLaunch(launch)
        exercise.on EVENTS.exercise_finished, =>
          @recorder.setExerciseLaunch(null)
        exercise.on EVENTS.round_finished, =>
          @recorder.setRoundLaunch(null)
          @recorder.pause()
        exercise.on EVENTS.round_started, ({run_config, launch}) =>
          @recorder.setRoundLaunch(launch)
          if options.recording.fragments?
            fragments = _.filter(options.recording.fragments, (frag)-> frag.round == run_config.round)
            if fragments?.length
              @recorder.schedule(fragments)
          else
            @recorder.resume()
        exercise.on EVENTS.round_paused, => @recorder.pause()
        exercise.on EVENTS.round_resumed, => @recorder.resume()
      else
        @recorder = null
      if options.fullscreen && (`options.first_exercise_in_session == null` || options.first_exercise_in_session)
        await @exercise_ui.enableFullScreen(true)
      exercise.one EVENTS.exercise_finished, (results) =>
        recording = null
        if @recorder?
          if options.recording?.finish ? true
            @recorder.stop()
            recording = await @recorder.getRecording()
            @recorder = null
          else
            @recorder.pause()
        exercise.destroy()
        result = {
          roundResults: results,
          recording: recording,
          calibrated: results.calibrated
        }
        @_current_exercise = null
        @_abort = null
        if options.fullscreen != "keep"
          @exercise_ui.disableFullScreen()
        resolve(result)
      @connectEvents(exercise, options)
      exercise.start(options)

  connectEvents: (exercise, options) =>
    exercise.one EVENTS.ready, -> exercise.continue()
    exercise.one EVENTS.round_ready, -> exercise.continue()

  @expose: ->
    runner = new this()
    Comlink.expose(runner, Comlink.windowEndpoint(self.parent))
    runner.waitForReadyReceived(self.parent)

  waitForReadyReceived: (window) ->
    @_received = false
    while not @_received
      window.postMessage('ready', "*")
      await new Promise (resolve)->setTimeout(resolve, 500)

  readyReceived: ->
    @_received = true

  getRecordingAndRestart: ->
    if not @recorder
      return null
    recording = await @recorder.getRecording()
    @recorder.start()
    return recording

  stop: ->
    @recorder?.stop()
    @recorder = null
    @_abort?(new Error("aborted"))
    @_current_exercise?.destroy()
    @_current_exercise = null

  destroy: ->
    @exercise_ui?.destroy()
