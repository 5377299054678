import opencv_url from "opencv.js"

export default loadOpenCV = ()->
  if cv?
    return Promise.resolve()
  return new Promise (resolve) ->
    if importScripts?
      importScripts(opencv_url)
      return resolve()
    script = document.createElement('script');
    script.onload = resolve
    script.src = opencv_url;
    document.body.appendChild(script)

