import { getLevelConfigArray } from "exercises/config_generators/utils"

LEVELS =
  level_1: {
    repetitions: 6,
    marbles: 2,
    consistent: true,
    capitalize: false,
    white_background: true,
    swap_background_and_text_colors_probability: 0.0,
    color_instead_of_meaning_probability: 0,
    timeout: -1,
    colors: ['blue', 'black'],
    expected_trial_duration: 15000
  }
  level_2: { repetitions: 8, expected_trial_duration: 13000 }
  level_3: { repetitions: 10, white_background: false, expected_trial_duration: 11000 }
  level_4: { marbles: 3, capitalize: true, colors: ['blue', 'black', 'violet'], }
  level_5: { consistent: false, }
  level_6: { repetitions: 12, timeout: 12000, }
  level_7: { repetitions: 14, timeout: 10000, }
  level_8: { repetitions: 16, timeout: 8000, }
  level_9: { repetitions: 18, timeout: 6000, }
  level_10: { repetitions: 12, color_instead_of_meaning_probability: 0.5, timeout: -1, }
  level_11: { timeout: 12000, }
  level_12: { repetitions: 14, timeout: 10000, }
  level_13: { timeout: 8500, }
  level_14: { repetitions: 15, timeout: 8000, }
  level_15: { repetitions: 16, timeout: 7500, }
  level_16: { timeout: 7000, }
  level_17: {
    marbles: 4,
    swap_background_and_text_colors_probability: 0.25,
    color_instead_of_meaning_probability: 0.55,
    timeout: 6000,
    colors: ['blue', 'black', 'violet', 'green'],
  }
  level_18: {
    repetitions: 18,
    swap_background_and_text_colors_probability: 0.5,
    color_instead_of_meaning_probability: 0.66,
    timeout: 5000,
  }
  level_19: { timeout: 4500, }
  level_20: { repetitions: 20, timeout: 4000, }
  level_21: { timeout: 3000, }
  level_22: { repetitions: 22, timeout: 2500, }
  level_23: { repetitions: 24, timeout: 2000, }
  level_24: { repetitions: 26, timeout: 1500, }
  level_25: { repetitions: 28, timeout: 1200, }
  level_26: { repetitions: 30, timeout: 1000, }
  level_27: { repetitions: 32, timeout: 900, }
  level_28: { repetitions: 35, timeout: 800, }


export default MARBLES_LEVELS = getLevelConfigArray(LEVELS, null, 'marbles')
for l_conf in MARBLES_LEVELS
  expected_trial_duration = (if l_conf.timeout < 0 then l_conf.expected_trial_duration else l_conf.timeout * 0.8)
  l_conf.expected_trial_duration = expected_trial_duration
