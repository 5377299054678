import axios from "axios"


export getApi = (prefix) =>
  detail: (id)=>getApi(prefix + "/" + id)
  create: (data)=>axios.post(prefix, data)
  update: (data)=>axios.patch(prefix, data)
  createChild: (data)=>axios.post(prefix + '/', data)
  recording: (body)=>axios.post("#{prefix}/recording", body)
  statistics: (data)=>axios.post("#{prefix}/statistics", data)
  get: ()=>axios.get(prefix)

export api = getApi('launch')
export benchmarks = getApi("benchmarks")
