export SHAPES = {
  arch:
    and_return: true
    side: { x: 0, y: 0, rotation: 0 }
    large: { x: 0, y: 0, rotation: 0 }
    top: { x: 0, y: 0, rotation: 0 }
    small: { x: 0, y: 0, rotation: 0 }
    small_bottom: { x: 0, y: 0, rotation: 0 }
  loop:
    side: { x: 0, y: 0, rotation: 0 }
    large: { x: 0, y: 0, rotation: 0 }
    top: { x: 0, y: 0, rotation: 0 }
    small: { x: 0, y: 0, rotation: 0 }
    small_bottom: { x: 0, y: 0, rotation: 0 }
  sine:
    and_return: true
    side: { x: 0, y: 0, rotation: 0 }
    large: { x: 0, y: 0, rotation: 0 }
    top: { x: 0, y: 0, rotation: 0 }
    small: { x: 0, y: 0, rotation: 0 }
    small_bottom: { x: 0, y: 0, rotation: 0 }
  loop_sine:
    side: { x: 0, y: 0, rotation: 0 }
    large: { x: 0, y: 0, rotation: 0 }
    top: { x: 0, y: 0, rotation: 0 }
    small: { x: 0, y: 0, rotation: 0 }
    small_bottom: { x: 0, y: 0, rotation: 0 }
  moebius:
    side: { x: 0, y: 0, rotation: 0 }
    large: { x: 0, y: 0, rotation: 0 }
    top: { x: 0, y: 0, rotation: 0 }
    small: { x: 0, y: 0, rotation: 0 }
    small_bottom: { x: 0, y: 0, rotation: 0 }
  helix:
    side: { x: 0, y: 0, rotation: 0 }
    large: { x: 0, y: 0, rotation: 0 }
    top: { x: 0, y: 0, rotation: 0 }
    small: { x: 0, y: 0, rotation: 0 }
    small_bottom: { x: 0, y: 0, rotation: 0 }
  zigzag:
    side: { x: 0, y: 0, rotation: 0 }
    large: { x: 0, y: 0, rotation: 0 }
    top: { x: 0, y: 0, rotation: 0 }
    small: { x: 0, y: 0, rotation: 0 }
    small_bottom: { x: 0, y: 0, rotation: 0 }
  pizza:
    and_return: true
    side: { x: 0, y: 0, rotation: 0 }
    large: { x: 0, y: 0, rotation: 0 }
    top: { x: 0, y: 0, rotation: 0 }
    small: { x: 0, y: 0, rotation: 0 }
    small_bottom: { x: 0, y: 0, rotation: 0 }
  amoeba:
    side: { x: 0, y: 0, rotation: 0 }
    large: { x: 0, y: 0, rotation: 0 }
    top: { x: 0, y: 0, rotation: 0 }
    small: { x: 0, y: 0, rotation: 0 }
    small_bottom: { x: 0, y: 0, rotation: 0 }
}
