import { getLevelConfigArray } from "exercises/config_generators/utils"

STICKS_LEVEL = {
  level_1: { timeout: 100, elements: 3, colors: 3, piles: 2, active: 6, activation_duration: 0.7 },
  level_2: { elements: 4, colors: 4 },
  level_3: { elements: 5, colors: 5 },
  level_4: { timeout: 120, elements: 6 },
  level_5: { timeout: 100 },
  level_6: { timeout: 90 },
  level_7: { timeout: 80 },
  level_8: { timeout: 60 },
  level_9: { elements: 7 },
  level_10: { colors: 4 },
  level_11: { elements: 8 },
  level_12: { colors: 3 },
  level_13: { timeout: 56 },
  level_14: { timeout: 53 },
  level_15: { timeout: 75, elements: 8, piles: 3, activation_duration: 0.6 },
  level_16: { timeout: 68 },
  level_17: { timeout: 60 },
  level_18: { elements: 9 },
  level_19: { colors: 2 },
  level_20: { colors: 1 },
  level_21: { elements: 11, activation_duration: 0.4 },
  level_22: { elements: 10, piles: 4 },
  level_23: { elements: 11 },
  level_24: { elements: 12, activation_duration: 0.3 },
  level_25: { elements: 10, piles: 5 },
  level_26: { elements: 11 },
  level_27: { elements: 12, activation_duration: 0.2 },
  level_28: { elements: 12, piles: 6 }
}

export DEFAULT_CONFIG = {
  timeout: 60,
  elements: 3
  colors: 3,
  piles: 2,
  active: 6,
  activation_duration: 0.7
}

export default STICKS_LEVELS = getLevelConfigArray(STICKS_LEVEL, DEFAULT_CONFIG, 'sticks')
