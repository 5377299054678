import ExerciseDescriptor, { AREAS } from "../exercise_descriptor"
import STICKS_LEVELS from "./sticks_levels"
import { SticksConfigGenerator } from "./sticks_generator"

export default class SticksDescriptor extends ExerciseDescriptor
  scene_name: "Sticks"
  exercise_id: "sticks"
  levels: STICKS_LEVELS.length
  instructions: [
    { video: { slides: "sticks" } },
    { hint: _tnoop("hints:sticks.instruction") }
  ]
  LEVELS_CONFIG: STICKS_LEVELS
  GeneratorClass: SticksConfigGenerator
  available_areas: [_.keys(AREAS)]

  generateRoundConfig: (run_config) ->
    config = super(run_config)
    return config

  _calculateResults: (run_config, results)->
    results.correctness_score = results.score / results.max_score
    results.avg_trial_duration = results.duration / results.max_score
    results.speed_score = results.max_score / results.expected_points
    return results

  SHAKE_DURATION: 2
  getExpectedPoints: (run_config)->
    conf = @_getLevelConfig(run_config)
    old_total_points = (conf.piles * conf.elements) * 0.9 * 2
    if conf.colors < 3
      old_total_points = old_total_points * (0.7 + conf.colors * 0.1)
    duration = run_config.round_duration - conf.piles * @SHAKE_DURATION
    return (old_total_points / conf.timeout) * duration

