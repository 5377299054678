# @coffeescript2
export class SkipFrame extends Error
export default class BackgroundSubtractor
  constructor: (@width, @height)->
    @_result_data = null

  findForeground: (drawImageFunction) ->
    @_result_data = null
    await Promise.resolve()
  expectSkipped: (e)->
    if not (e instanceof SkipFrame)
      throw e
  getResultData: ->
    if @_result_data is null
      @_result_data = @_getResultData()
    return @_result_data
  _getThreshold: (threshold)->
    return (threshold ? @_threshold) * 255
  checkRect: (rect, threshold)->
    data = @getResultData()
    bytes = data.data.length / (data.width * data.height)
    line_start = (y)-> ((rect.y + y) * data.width + rect.x) * bytes
    count = 0
    com = x: 0, y: 0
    threshold = @_getThreshold(threshold)
    for y in [0...rect.height]
      index = line_start(y)
      for x in [0...rect.width]
        if data.data[index] > threshold
          count += 1
          if com?
            com.x += x
            com.y += y
        index += bytes
    return {
      x: com.x / count
      y: com.y / count
      count: count
      percentage: count / (rect.width * rect.height)
    }
  destroy: ->
  load: ->
