#@coffeescript2
import FaceDetector from './face/face_detector'
import RectWatcher from './fgbg/rect_watcher'
import comlink from "./comlink"
import Logger from "utils/logger"

LOGGER = new Logger("img_processor")
export default class ImageProcessor
  defaults:
    base_size:
      width: 1280
      height: 720
    face_detector:
      type: 'debug'
      worker: true
    rect_watcher:
      height: 100
      subtractor: 'cv'

  constructor: (video, params = {}) ->
    @_params = _.defaultsDeep params, @defaults
    if @_params.rect_watcher instanceof RectWatcher
      @rect_watcher = @_params.rect_watcher
    else
      @rect_watcher = new RectWatcher(video, @_params.rect_watcher)

  load: ->
    LOGGER.debug("Loading ImageProcessor")
#    @face_detector = await ImageProcessor.createFaceDetector(@_params.face_detector)
#    LOGGER.debug("Face detector created")
    await Promise.all [
      @rect_watcher.load().then(->LOGGER.debug("Rect Watcher Ready"))
#      @face_detector.load().then(->LOGGER.debug("Face detector Ready"))
    ]

#  @createFaceDetector: (detector_options) ->
#    if detector_options.worker and window.OffscreenCanvas
#      {
#        default: FaceDetectorWorker
#      } = await `import(
#        /* webpackChunkName: "fd_worker" */
#        /* webpackPreload: true */
#        './face/face_detector.worker') `
#      WrappedFaceDetector = comlink.wrap(new FaceDetectorWorker())
#      return new WrappedFaceDetector(detector_options)
#    else
#      return new FaceDetector(detector_options)

  destroy: ->
    @rect_watcher.destroy?()
    @face_detector?.destroy?()

  setWatcherConfig: (params)->
    new_params = _.defaultsDeep params, @_params.rect_watcher
    @_params.rect_watcher = new_params
    @rect_watcher.setConfig(@_params.rect_watcher)
    return @rect_watcher.load()


  @createFrom: (old_processor, { keep_face_detector }) ->
    params = old_processor._params
    if keep_face_detector?
      params.face_detector = old_processor.face_detector
      old_processor.face_detector = null
    old_processor.destroy()
    new_processor = new ImageProcessor(old_processor._camera,
      old_processor._container,
      params)
