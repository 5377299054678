import Konva from "konva"
import "../_common.scss"
import AnimationFrameRequester from "../animation_frame_requester"

export default class GameStage extends Konva.Stage
  constructor: (container, base_size)->
    konva_container = $(container)
    konva_container.addClass('cover')
    stage = _.extend container: konva_container[0], base_size
    stage.width = Math.min(stage.width ? Infinity, konva_container.width())
    stage.height = Math.min(stage.height ? Infinity, konva_container.height())
    super(stage)
    @_container = konva_container
    @setSize(stage.width, stage.height)
    @_layer = @createGameLayer()
    @game_node = @_layer
    @_requester = new AnimationFrameRequester((=>@batchDraw()), 30)

  createGameLayer: ->
    @add layer = new Konva.Layer {
                                   listening: false,
                                   scale: {x: @attrs.height, y: @attrs.height},
                                   x: @attrs.width / 2,
                                   y: @attrs.height / 2,
                                 }
    return layer

  setSize: (width, height, parent_size) ->
    parent_size ?=
      width: @_container.width()
      height: @_container.height()
    @setAttrs
      width: width
      height: height
    $(@content).css
      transform: "scale(#{parent_size.width / width},#{parent_size.height / height})"
    for l in @getChildren()
      l.setAttrs
        scale: {x: @attrs.height, y: @attrs.height}
        x: @attrs.width / 2,
        y: @attrs.height / 2,

  startRedraw: =>
    @_requester.start()

  stopRedraw: ->
    @_requester.stop()
    @batchDraw()

  setVideoCoords: (video_coords)->
    @video_coords = video_coords
