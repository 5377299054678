export SHAPES = {
  linia_h_medium:
    and_return: true #1
    left: { x: 0, y: 0, rotation: 0 }
  linia_v_medium:
    and_return: true #2
    left: { x: 0, y: 0, rotation: 0 }
  linia_h:
    and_return: true #3
    left: { x: 0, y: 0, rotation: 0 }
  linia_v:
    and_return: true #4
    left: { x: 0, y: 0, rotation: 0 }
  krzywa:
    and_return: true #5
    left: { x: 0, y: 0, rotation: 0 }
#    scale: { x: 0.9, y: 0.9 }
  okrag:
    speed_to_duration: 0.12 #6
    left: { x: 0, y: 0, rotation: 0 }
  skosna:
    and_return: true #7
    left: { x: 0, y: 0, rotation: 0 }
  flores:
    and_return: true #8
    left: { x: 0, y: 0, rotation: 0 }
  klepsydra:
    left: { x: 0, y: 0, rotation: 0 }
  linia_h_small:
    and_return: true, duration: 4000
    top: { x: 0, y: 0, rotation: 0 }
    bottom: { x: 0, y: 0, rotation: 90 }
  linia_v_small:
    and_return: true, duration: 4000
    top: { x: 0, y: 0, rotation: 0 }
    bottom: { x: 0, y: 0, rotation: 90 }
  krzywa_small:
    and_return: true, duration: 8000
    top: { x: 0, y: 0, rotation: 0 }
    bottom: { x: 0, y: 0, rotation: 90 }
  okrag_small:
    duration: 8000
    top: { x: 0, y: 0, rotation: 0 }
    bottom: { x: 0, y: 0, rotation: 90 }
  skosna_small:
    duration: 8000
    top: { x: 0, y: 0, rotation: 0 }
    bottom: { x: 0, y: 0, rotation: 90 }
  flores_small:
    and_return: true, duration: 8000
    top: { x: 0, y: 0, rotation: 0 }
    bottom: { x: 0, y: 0, rotation: 90 }
  klepsydra_small:
    duration: 8000
    top: { x: 0, y: 0, rotation: 0 }
    bottom: { x: 0, y: 0, rotation: 90 }
}
