import { getLevelConfigArray } from "exercises/config_generators/utils"
import { SHAPES } from "./track_shapes"

LEVELS = {
  level_1: {
    duration: 20,
    speed: 1,
    rules: [{ when: ["arch"], then: ["arch"] }],
    remember: 0,
    shape_diff: 0,
    async: false
    variable_speed: 0
    trials: 1,
  },
  level_2: { duration: 20, speed: 1, rules: [{ when: ["loop"], then: ["loop"] }] },
  level_3: { duration: 30, speed: 1.15, rules: [{ when: ["sine"], then: ["sine"] }] },
  level_4: { duration: 35, speed: 1.3, rules: [{ when: ["loop", "sine"], then: ["loop_sine"] }], trials: 2 },
  level_5: { duration: 40, speed: 1.5 },
  level_6: { duration: 50, speed: 1.8, rules: [{ when: ["sine", "loop_sine"], then: ["moebius"] }] },
  level_7: {
    duration: 60,
    speed: 2,
    rules: [{ when: ["loop_sine", "moebius"], then: ["helix"] }],
    remember: 2,
    shape_diff: 0.05
  },
  level_8: {
    speed: 2.1,
    rules: [{ when: ["loop_sine", "helix"], then: ["pizza"] }],
    shape_diff: 0.1
  },
  level_9: { speed: 2.2, rules: [{ when: ["helix", "pizza"], then: ["zigzag"] }], shape_diff: 0.15 },
  level_10: {
    speed: 2.3,
    rules: [{ when: ["helix", "pizza", "zigzag"], then: ["helix", "pizza", "zigzag"] }],
    shape_diff: 0.2
  },
  level_11: {
    speed: 2.4,
    rules: [{ when: ["zigzag", "pizza"], then: ["amoeba"] }],
    shape_diff: 0.25
  },
  level_12: {
    duration: 70,
    speed: 2.5,
    rules: [{ when: ["zigzag", "pizza", "amoeba"], then: ["zigzag", "pizza", "amoeba"] }],
    shape_diff: 0.3,
    show_warning: true
  },
  level_13: {
    speed: 2.7,
    rules: [{
      when: ["sine", "loop_sine", "zigzag", "pizza", "amoeba"],
      then: ["sine", "loop_sine", "zigzag", "pizza", "amoeba"]
    }],
    shape_diff: 0.4
  },
  level_14: { duration: 80, speed: 2.8, shape_diff: 0.5 },
  level_15: { speed: 2.9 },
  level_16: { duration: 90, speed: 3, shape_diff: 0.6 },
  level_17: { speed: 3.1 },
  level_18: { speed: 3.2 },
  level_19: { speed: 3.3 },
  level_20: { speed: 3.4 },
  level_21: { speed: 3.5 },
  level_22: { speed: 4, shape_diff: 0.5 },
  level_23: { duration: 95, speed: 4.5 },
  level_24: { speed: 5 },
  level_25: { duration: 100, speed: 5.5 },
  level_26: { speed: 6, shape_diff: 0.25 },
  level_27: { speed: 6.5 },
  level_28: { speed: 7 }
}

for name,conf of LEVELS
  if conf.speed?
    conf.speed *= 1.2
  if conf.shape_diff > 0
    conf.variable_speed = conf.shape_diff
all_shapes_rules = []
prev = null
for name,conf of SHAPES
  if prev?
    all_shapes_rules.push({ when: [prev], then: [name] })
  prev = name

export TEST_LEVELS = getLevelConfigArray({
  level_1: {
    speed: 3,
    rules: all_shapes_rules,
    remember: 1,
    variable_speed: 0,
    show_warning: false,
    test: true
    trials: all_shapes_rules.length + 1
  }
  level_2: {
    variable_speed: 0.5,
  }
}, null, 'track_test')

#  if conf.rules?
#    conf.rules = _.map(conf.rules, (r)=> _.mapValues(r, (arr)=>_.map(arr, (i)=>shapes[i - 1])))
#console.log(LEVELS)
export default getLevelConfigArray(LEVELS, null, 'track')
