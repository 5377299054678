import PredefinedPositionGenerator, { POSITIONS, Y_OFFSET } from "./predefined"
import SVGReader from "helpers/svg_helper"
import Point from "utils/point"
import { areaName } from "../descriptors/exercise_descriptor"

split_for_areas = (points)=>
  res = {}
  for p in points
    add = (name)-> (res[name] ?= []).push(p)
    if p.x <= 0 and p.y <= Y_OFFSET
      add('tl')
    if p.x <= 0 and p.y >= Y_OFFSET
      add('bl')
    if p.x >= 0 and p.y <= Y_OFFSET
      add('tr')
    if p.x >= 0 and p.y >= Y_OFFSET
      add('br')
  return res

svg = null
AREA_TO_SVG = {
  blbrtltr: "Full",
  blbrtl: "BothDownLeftUp",
  blbrtr: "BothDownLeftUp mirror",
  bltltr: "BothUpLeftDown",
  bltl: "BothLeft",
  bltr: "LeftUpRightDown mirror",
  blbr: "BothDown",
  brtltr: "BothUpLeftDown mirror",
  brtr: "BothLeft mirror",
  brtl: "LeftUpRightDown",
  tltr: "BothUp",
  tl: "LeftUp",
  tr: "LeftUp mirror",
  bl: "LeftDown",
  br: "LeftDown mirror"
}

getPositions = (svg, areas, number, max_distance = null) =>
  a_name = areaName(areas)
  [label, m]=AREA_TO_SVG[a_name].split(' ')
  points = svg.getPositions("##{label}_#{number} path")
  if max_distance
    for p in points
      p.x = Math.sign(p.x) * Math.min(max_distance, Math.abs(p.x))
  if m
    points = (new Point(x: -p.x, y: p.y) for p in points)
  if points.length
    return split_for_areas(points)
  else
    console.error("No config for #{a_name}")

export generatorAreas = (areas, positions, svg_path, max_distance) ->
  svg = new SVGReader(svg_path)
  area_positions = getPositions(svg, _.keys(areas), positions, max_distance)
  result = {}
  for name, positions of area_positions
    if areas[name]?
      result[name] =
        start_point: areas[name].start_point, positions: positions
  return result

export default class SvgPositionGenerator extends PredefinedPositionGenerator
  constructor: (svg_path, areas, number, size)->
    super(generatorAreas(areas, number, svg_path, (16 / 9 - size) / 2), size)
