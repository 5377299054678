let instance = {}
const getSetting = (name, default_value) => {
  if (sessionStorage[name] !== undefined) return JSON.parse(sessionStorage[name])
  return default_value
}

const createSetting = (name, default_value) => {
  Object.defineProperty(instance, name, {
    get: () => getSetting(name, default_value),
  })
  return instance[name]
}
if (window) window._settings = instance
instance.create = createSetting
instance.list = () => {
  const result = {}
  Object.getOwnPropertyNames(instance).forEach((p) => {
    const val = instance[p]
    if (typeof val != "function") result[p] = val
  })
  return result
}
instance.set = (name, value) => {
  sessionStorage[name] = JSON.stringify(value)
}
export default instance
