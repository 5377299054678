import RandomMovement from "button/movement/flyout_mover"

export class PathGenerator {
  constructor(config) {
    this.config = config
    this.reset()
  }

  reset() {}

  getNextPos(frame, path) {
    return { x: 0, y: 0 }
  }

  _stretch(position) {
    return {
      x: ((position.x - 0.5) * 16) / 9,
      y: position.y - 0.5,
    }
  }

  generatePath(duration, stepLength) {
    const path = []
    path.push({
      ts: 0,
      ...this.getNextPos({ delta: 0, time: 0 }, path),
    })
    let curTime = 0
    while (true) {
      let delta = Math.min(stepLength, duration - curTime)
      let position
      while (true) {
        const stepDelta = Math.min(delta, 0.02)
        curTime += stepDelta
        const frame = {
          delta: stepDelta * 1000,
          time: curTime * 1000,
        }
        position = this.getNextPos(frame, path)
        delta -= stepDelta
        if (delta <= 0) break
      }
      path.push({
        ts: curTime,
        ...position,
      })
      if (curTime >= duration) break
    }
    return path
  }
}

export class RandomPathGenerator extends PathGenerator {
  reset() {
    this.randomMovement = new RandomMovement(this.config)
  }

  getNextPos(frame) {
    this.randomMovement.advance(frame)
    return this._stretch(this.randomMovement.position)
  }
}
