import ExerciseDescriptor from "../exercise_descriptor"

export  YOUR_TURN = _tnoop("hints:custom_ex.your_turn")

class CustomGenerator extends ExerciseDescriptor::GeneratorClass
  generateScenario: (round_duration) -> [
    {
      "id": "your_turn_",
      "timeout": round_duration,
      "text": _t(YOUR_TURN)
    }
  ]

export default class CustomDescriptor extends ExerciseDescriptor
  scene_name: "Custom"
  exercise_id: "custom"
  levels: 1
  has_levels: false
  LEVELS_CONFIG: [{}]
  instructions: []
  YOUR_TURN: YOUR_TURN
  GeneratorClass: CustomGenerator

  getExerciseClass: ->`import(
    /* webpackChunkName:"custom_exercise" */
    "./custom_exercise")`

  exercise_in_center: false

  constructor: (overrides) ->
    super(overrides)
    if not @exercise_in_center
      @calibration = false

  getRunConfig: (run_config) ->
    run_config = super(run_config)
    run_config.level = 1
    return run_config

  _getLevelChange: -> 0
  getExpectedPoints: -> 0
