#@coffeescript2
import 'webrtc-adapter'
import { getVideoPlaybackQuality } from 'videoplaybackquality'
import "./_common.scss"
import CustomEventEmitter from "helpers/event_helper"
import store from "store2"

export class ImageSource extends CustomEventEmitter
  @EVENTS = {
    "new_frame",
    "size_changed",
  }
  constructor: ->
    super()
    @aspect_ratio = 1
    @width = 100
    @height = 100
  draw: (context)->
  start: ->
  stop: ->

export default class Camera extends ImageSource
  RESOLUTIONS = [
    { width: 1280, height: 720 },
    { width: 800, height: 600 },
    { width: 640, height: 480 }
    { width: 640, height: 360 }
    { width: 320, height: 240 }
  ]
  CAMERA_FREEZE_THRESHOLD_S = 15
  constructor: (video) ->
    super()
    if video?
      @$video = $(video)
    else
      @$video = $("<video id='camera_preview' autoplay muted playsinline></video>")
    @$video.addClass 'cover'
    @video = @$video[0]
    @$video.on 'playing', @_check_frame
    @$video.on 'pause', =>
      @_resetCameraFreeze.cancel()
      cancelAnimationFrame(@_raf)
    @$video.on 'timeupdate', @_videoIsAlive
    @_loading = new Promise (resolve) =>
      @_loading_done = resolve
    @_settings = store.namespace('camera')
    @_resetCameraFreeze = _.debounce(@_cameraFrozen, CAMERA_FREEZE_THRESHOLD_S * 1000)
    @flip_horizontally = @_settings.get("flip", true)

  _videoIsAlive: =>
    @_resetCameraFreeze()
    current_res = { width: @width, height: @height }
    @_settings.transact 'bad_resolutions', (res)=>
      _.remove(res, current_res)
      return res

  _cameraFrozen: =>
    if @video.paused or @video.ended
      return
    current_res = { width: @width, height: @height }
    console.error("CAMERA FROZEN at resolution ", current_res, @$video.is(":visible"))
    @_settings.transact('bad_resolutions', (res)=>
      if _.find(res, current_res) then res else ([current_res, (res ? [])...]))
    @restartCamera()

  restartCamera: =>
    @width = null
    @height = null
    @_loadStream(@_target_size)

  _check_frame: =>
    @_raf = requestAnimationFrame(@_check_frame)
    frame_id = @currentFrameId()
    time = @video.currentTime
    if frame_id != @_last_frame_id
      if time == @_last_time
        @_wait_until_current_time_changes = true
      else
        @_trigger_new(frame_id)
      @_last_frame_id = frame_id
      @_last_time = time
    else if @_wait_until_current_time_changes
      if time != @_last_time
        @_wait_until_current_time_changes = false
        @_trigger_new(@_last_frame_id)

  _trigger_new: (frame_id)->
    @emit(ImageSource.EVENTS.new_frame, { frame_id: frame_id })

  setStream: (stream) ->
    @_stream = stream
    if @video.srcObject != undefined
      @video.srcObject = @_stream
    else
      @video.src = URL.createObjectURL(@_stream)
    @_updateStream()
    @_loading_done()

  _updateStream: =>
    @_resetCameraFreeze()
    @_video_settings = @_stream.getVideoTracks()[0].getSettings()
    console.log("Camera resolution set", _.pick(@_video_settings, 'width', 'height'))
    if @_video_settings.width == @width and @_video_settings.height == @height
      return
    @width = @video.width = @_video_settings.width
    @height = @video.height = @_video_settings.height
    @aspect_ratio = @_video_settings.aspectRatio
    @emit(ImageSource.EVENTS.size_changed, { width: @width, height: @height })

  getAvailableResolutions: (constraints)=>
    resolutions = [ RESOLUTIONS...]
    if !_.find(resolutions, constraints)
      resolutions.unshift(constraints)
    force_resolution = @_settings("force_resolution")
    if force_resolution
      return [force_resolution]
    max_res = @_settings("max_resolution")
    bad_resolutions = @_settings.get('bad_resolutions')
    if !max_res or max_res.width > constraints.width or max_res.height > constraints.height
      max_res = constraints
    res = resolutions.filter((r)=>r.width <= max_res.width and r.height <= max_res.height and !_.find(bad_resolutions, r))
    if res.length == 0
      console.error("No available resolutions max:#{JSON.stringify(max_res)} bad: #{JSON.stringify(bad_resolutions)}")
      return [max_res]
    return res

  setResolution: (width, height)  ->
    if width == @width and height == @height
      return
    await @_loading
    @_target_size = { width: width, height: height }
    if !@_stream
      @width = width
      @height = height
      return
    else
      current = { width: @width, height: @height }
    for resolution in @getAvailableResolutions(@_target_size)
      if resolution.width == current.width and resolution.height == current.height
        continue
      try
        await @_stream.getVideoTracks()[0].applyConstraints(resolution)
        @_updateStream()
        return
      catch error
        console.log "Camera resolution not available", resolution, error.message

  load: ->
    if not @_stream?
      await @_loadStream()
      @_installFocusToggle()

  _getDeviceId: ->
    devices = await navigator.mediaDevices.enumerateDevices()
    cameras = {}
    devices.filter((d)=> d.kind == 'videoinput').forEach((d)=>cameras[d.deviceId] = d)
    deviceId = @_settings('deviceId')
    if (deviceId && cameras[deviceId])
      return deviceId: { exact: deviceId }
    cams = Object.values(cameras)
    validCameras = cams.filter((d)=>
      if d.label.startsWith("AvStream Media Device")
        id = d.label.split(' (')[1]
        if id and cams.find((cc)=>cc.label.endsWith(id))
          return false
      return true
    )
    if validCameras.length
      return {
        deviceId: [validCameras.map((d)=>d.deviceId)]
        facingMode: "user"
      }
    return {
      facingMode: "user"
    }

  _loadStream: (size = {}) ->
    resolution =
      width: size.width ? @$video.width()
      height: size.height ? @$video.height()
    console.debug("Initializing camera for res " + JSON.stringify(resolution))
    v_constraints = @getAvailableResolutions(resolution)[0]
    deviceId = await @_getDeviceId()
    v_constraints = { deviceId..., v_constraints... }
    constraints =
      video: v_constraints
    console.log("Constraints", constraints)
    stream = await navigator.mediaDevices.getUserMedia(constraints)
    console.debug("Camera initialized")
    loaded_metadata = new Promise (resolve, reject) =>
      @$video.one 'loadedmetadata', resolve
    @setStream(stream)
    await loaded_metadata

  _installFocusToggle: ->
    if not sessionStorage.no_blur?
      $(window).on "blur.camera focus.camera", @focusToggle
      if not document.hasFocus()
        @_pause()
  _uninstallFocusToggle: ->
    $(window).off ".camera"

  focusToggle: (evt)=>
    if evt.type == 'blur'
      @_pause()
    else
      @_resume()

  _resume: =>
    await @_loadStream(@_target_size)

  stop: ->
    @_uninstallFocusToggle()
    @_stop()

  _stop: ()=>
    console.debug("Camera stop")
    await @video.pause()
    @_resetCameraFreeze.cancel()
    if @_stream?
      @_stream.getVideoTracks()[0].stop()
      @_loading = new Promise (resolve) =>
        @_loading_done = resolve
      @_stream = null

  _pause: ()=>@_stop()

  pause: ->@stop()

  resume: =>
    @load()

  @getAvailableCameras: ->
    devices = await navigator.mediaDevices.enumerateDevices()
    return devices

  currentFrameId: ->
    if @video.mozPaintedFrames?
      cur_frame = @video.mozPaintedFrames
    else
      quality = getVideoPlaybackQuality(@video)
      cur_frame = quality.totalVideoFrames - quality.droppedVideoFrames
    return cur_frame

  draw: (context, destination_rect, source_rect)->
    if not destination_rect?
      destination_rect = { x: 0, y: 0, width: context.canvas.width, height: context.canvas.height }
    args = [@video, destination_rect.x, destination_rect.y, destination_rect.width, destination_rect.height]
    if source_rect
      args = args.concat([source_rect.x, source_rect.y, source_rect.width, source_rect.height])
    context.drawImage(args...)
  onLoaded: (callback)=>
    @_loading.then(callback)
