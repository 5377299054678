#@coffeescript2
import GLBackgroundSubtractor from './gl_background_subtractor'
import CVBackgroundSubtractor from "./cv_background_subtractor"
import konva from '../konva_compat'
import AnimationFrameRequester from '../animation_frame_requester'
import PerfMonitor from '../perf_monitor'
import EventEmitter from "helpers/event_helper"
import Rect from "helpers/rect"

class WatchedRect
  constructor: (@id, rect, @_options)->
    @is_movement_detected = false
    @debug = new konva.Rect _.extend
      stroke: 'white',
      strokeWidth: 1,
      strokeScaleEnabled: false,
      name: 'Debug2Rect' + @id
    @setRect(rect)

  _setMovementDetected: (is_movement_detected)->
    @is_movement_detected = is_movement_detected
    @debug.setFill if is_movement_detected then "#FFFFFF88" else null

  _normalizeRect: (rect) ->
    return {
      x: Math.min(Math.round(rect.x), Math.round(rect.x + rect.width))
      y: Math.min(Math.round(rect.y), Math.round(rect.y + rect.height))
      width: Math.abs(Math.round(rect.width))
      height: Math.abs(Math.round(rect.height))
    }
  check: (subtractor)->
    rect_stats = subtractor.checkRect(@_rect, @_options.threshold)
    is_movement_detected = rect_stats.percentage > @_options.percentage
    @_stats = rect_stats
    if @is_movement_detected != is_movement_detected
      @_setMovementDetected(is_movement_detected)
      return true
    return false

  setRect: (rect)->
    @_rect = new Rect(@_normalizeRect(rect))
    @debug.setAttrs(@_rect)

export EVENTS = { 'detection_changed', 'rect_changed', 'foreground_data' }
perf = PerfMonitor.instance
export default class RectWatcher extends EventEmitter
  defaults:
    window_height: 100,
    fps: 15,
    percentage: 0.01
    subtractor: 'gl'
    autostart: true
    autostop: false
    threshold: 0.2

  constructor: (@_video, params = {}) ->
    super()
    @_params = _.defaultsDeep {}, params, @defaults
    @_params.height = Math.round(Math.min(@_video.size.height, @_params.window_height))
    @_params.width = Math.round(@_video.aspect_ratio * @_params.height)
    @_rects = {}
    @_started = false
    @_requester = new AnimationFrameRequester(@_analyze, params.fps)
    @node = new konva.Group()
    @_debug = new konva.Group()
    @node.add @_debug
    @_inner = new konva.Group
      offset:
        x: @_params.width / 2
        y: @_params.height / 2
      scale:
        y: 1 / @_params.height
        x: 1 / @_params.height
    @_transform = @_inner.getAbsoluteTransform(@node).invert()
    @node.add @_inner
    @max_rect = new Rect
      x: -@_video.aspect_ratio / 2,
      y: -0.5,
      width: @_video.aspect_ratio,
      height: 1
    @_canvas_rect = new Rect(0, 0, @_params.width, @_params.height)
    if @_params.onDetectionChanged
      @on(EVENTS.detection_changed, @_params.onDetectionChanged)

  _createSubtractor: ->
    if @_params.subtractor == 'gl'
      return Promise.resolve(new GLBackgroundSubtractor(@_params.width, @_params.height, @_params.threshold))
    else if @_params.subtractor == 'cv'
      return Promise.resolve(new CVBackgroundSubtractor(@_params.width, @_params.height, @_params))

  setConfig: (config)->
    @_subtractor?.destroy?()
    delete @_subtractor
    new_params = _.defaultsDeep({}, config, @_params)
    @_params = new_params

  load: () ->
    if not @_subtractor?
      @_subtractor = await @_createSubtractor(@_params.width, @_params.height)
      await @_subtractor.load()
    console.log "Subtractor Loaded", @_subtractor
    return @_subtractor

  watchRect: (watch_id, org_rect, options = {})->
    options = _.defaults options, percentage: @_params.percentage
    rect = konva.Util.transformRect(org_rect, @_transform)
    rect = @_canvas_rect.intersectionWith(new Rect(rect))
    if @_rects[watch_id]
      @_rects[watch_id].setRect(rect)
    else
      @_rects[watch_id] = rect = new WatchedRect(watch_id, rect, options)
      @_inner.add rect.debug
      if @_params.autostart and _.size(@_rects) == 1
        @start()

  start: ->
    if not @_requester.isRunning()
      @_subtractor.clear()
      @_requester.start()
      @_video.on 'new_frame', @_newFrame

  _newFrame: =>
    @_current_frame_processed = false
    return null

  stopWatching: (watch_id)->
    @_rects[watch_id]?.debug?.remove()
    delete @_rects[watch_id]
    if @_params.autostop and _.size(@_rects) == 0
      @stop()

  stop: ->
    for id of @_rects
      this.stopWatching(id)
    @_rects = {}
    @_requester.stop()
    @_video.off 'new_frame', @_newFrame

  setMask: (mask)->
    @_mask = mask

  drawImageFunc: (context, x, y, width, height)=>
    target_roi = x: x, y: y, width: width, height: height
    @_video.draw(context, target_roi)
    if @_mask
      context.drawImage(@_mask, x, y, width, height)
    return Promise.resolve()

  _analyze: =>
    if @_current_frame_processed
      return
    @_current_frame_processed = true
    perf.time('find_foreground')
    try
      await @_subtractor.findForeground(@drawImageFunc)
    catch e
      @_subtractor.expectSkipped(e)
      return true
    finally
      perf.timeEnd('find_foreground')
    changed = false
    result_data = null
    try
      for id, rect of @_rects
        if rect.check(@_subtractor)
          changed = true
          @emit(EVENTS.rect_changed, id, rect.is_movement_detected, rect)
      perf.timeEnd('check_rect', 'find_foreground')
      result_data = @_subtractor.getResultData()
    catch e
      @_subtractor.expectSkipped(e)
      return
    if changed
      @emit(EVENTS.detection_changed, @_rects)
    @emit(EVENTS.foreground_data, result_data)
    return true

  destroy: ->
    @events = {}
    @stop()
    @_subtractor?.destroy?()
    delete @_subtractor
