import Point from "utils/point"

export default class Rect
  constructor: (x, y, width, height)->
    if x instanceof Object
      conf = x
      width = conf.size?.width ? conf.width ? width
      height = conf.size?.height ? conf.height ? height
      if conf.center?
        x = conf.center.x - width / 2
        y = conf.center.y - width / 2
      else
        x = conf.x ? conf.top_left?.x ? x
        y = conf.y ? conf.top_left?.y ? y
      if conf.bottom_right?
        width = conf.bottom_right.x - x
        height = conf.bottom_right.y - y
    if width < 0
      x = x + width
      width = -width
    if height < 0
      y = y + height
      height = -height
    @x = x
    @y = y
    @width = width
    @height = height

  containsPoint: (point, margin) ->
    return (@min_x - margin <= point.x <= @max_x + margin) and (@min_y - margin <= point.y <= @max_y + margin)

  intersectionWith: (rect)->
    br = @bottom_right
    rbr = rect.bottom_right
    return new Rect
      x: Math.min(Math.max(@x, rect.x), br.x)
      y: Math.min(Math.max(@y, rect.y), br.y)
      bottom_right:
        x: Math.max(Math.min(br.x, rbr.x), @x)
        y: Math.max(Math.min(br.y, rbr.y), @y)

  pointsInside: (resolution)->
    numX = Math.floor(@width / resolution)
    numY = Math.floor(@height / resolution)
    x = @x + (@width - numX * resolution) / 2
    startY = @y + (@height - numY * resolution) / 2
    while (x<=@max_x)
      y = startY
      while (y<=@max_y)
        yield {x,y}
        y+=resolution
      x+=resolution

Object.defineProperties(Rect::, {
  min_x:
    get: ->@x
  max_x:
    get: ->@x + @width
  min_y:
    get: ->@y
  max_y:
    get: ->@y + @height
  top_left:
    get: -> new Point(x: @x, y: @y)
  top_right:
    get: -> new Point(x: @x + @width, y: @y)
  bottom_left:
    get: -> new Point(x: @x, y: @y + @height)
  bottom_right:
    get: -> new Point(x: @x + @width, y: @y + @height)
  center:
    get: -> new Point(x: @x + @width / 2, y: @y + @height / 2)
})
