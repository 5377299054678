import {CaptureConsole, Dedupe} from '@sentry/integrations';
import * as Sentry from "@sentry/browser"
import {simplifyObject} from "helpers/error_helper"
import {showReportDialog} from "@sentry/browser"
import store from "store2"
import {isWebWorker} from "./helpers/createBrowserEnv"

DEFAULT_USER = null
export getSentryDialogOptions = (user, t) ->
  t ?= window._t
  user ?= DEFAULT_USER
  return {
    lang: "pl",
    title: t("main:sentry.title")
    subtitle: t("main:sentry.subtitle")
    subtitle2: t("main:sentry.subtitle2")
    labelName: t("main:sentry.labelName")
    labelEmail: t("main:sentry.labelEmail")
    labelComments: t("main:sentry.labelComments")
    labelClose: t("main:sentry.labelClose")
    labelSubmit: t("main:sentry.labelSubmit")
    errorGeneric: t("main:sentry.errorGeneric")
    errorFormEntry: t('main:sentry.errorFormEntry')
    successMessage: t('main:sentry.successMessage')
    user: {
      email: user?.email
      name: user?.first_name
    }
    onLoad: (args...) ->
      if document.fullscreenElement
        $('.sentry-error-embed-wrapper').appendTo(document.fullscreenElement)
      $('.sentry-error-embed [name=comments]').attr('placeholder', t("main:sentry.commentsPlaceholder"))
      $('.sentry-error-embed [name=name]').attr('placeholder', t("main:sentry.namePlaceholder"))
      $('.sentry-error-embed [name=email]').attr('placeholder', t("main:sentry.emailPlaceholder"))
  }
export setDefaultUser = (user)=>
  DEFAULT_USER = user
  if user
    Sentry.setUser({email: user.email, name: user.first_name})
  else
    Sentry.configureScope((scope) -> scope.setUser(null))

ENVS = {
  "production": [/app.neuroforma.pl/, /neuroforma.appspot.com.storage.googleapis.com/]
  "staging": [/test.neuroforma.pl/, /lively-nimbus-243611.appspot.com.storage.googleapis.com/,]
  "storybook": [/chromatic/, /storybook/]
}
export shouldShowDialog = (event)=>
  if isWebWorker()
    return false
  if  /\[Report\]/.test(event.message)
    return true
  if (event.exception && event.exception.values[0].type != "UnhandledRejection")
    if /inappropriate in current/.test(event.exception.values[0].value)
      return false
    return true
  return false


export shouldSkipEvent = (event)=>
  if event.exception and /inappropriate in current/.test(event.exception.values[0].value)
    return true
  return false

export shouldUseSentry = ()=> (process.env.NODE_ENV == 'production' or store.session("force_sentry")) and SENTRY_DSN

export default getSentryInitParams = ->
  environment = "development"
  for env,hostnames of ENVS
    if _.some(hostnames, (h)=>h.test(global.location.hostname))
      environment = env
  return {
    dsn: SENTRY_DSN,
    release: __VERSION__,
    environment: environment
    normalizeDepth: 5,
    integrations: [new CaptureConsole(levels: ['error']), new Dedupe()]
    beforeSend: (event, hint) =>
      if shouldSkipEvent(event, hint)
        return null
      if shouldShowDialog(event, hint)
        options = getSentryDialogOptions()
        options.eventId = event.event_id
        showReportDialog(options)
      return event;
    beforeBreadcrumb: (event, hint)=>
      if event.category == 'console'
        event.data = simplifyObject(event.data, 3)
      return event
    ignoreErrors: [
# Random plugins/extensions
      'top.GLOBALS',
# See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      'Can\'t find variable: ZiteReader',
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
# Facebook borked
      'fb_xd_fragment',
# ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
# See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
# See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      "AbortError: The play() request was interrupted",
      "NotAllowedError: play() failed because the user didn't interact",
# https://sentry.io/share/issue/15035e5dd0fa4b34966b1fd29c3c1f5a/
      'ResizeObserver loop limit exceeded',
    ],
    ignoreUrls: [
# Facebook flakiness
      /graph\.facebook\.com/i,
# Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
# Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
# Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
# Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, # Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
    ]
  }

export init = ()=>
  if shouldUseSentry()
    Sentry.init(getSentryInitParams())
