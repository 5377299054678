import { getLevelConfigArray } from "exercises/config_generators/utils"
import { DEFAULT_CONFIG, transformBoxingLevels } from "../boxing/boxing_levels"

CHESTNUTS_LEVEL_DESC =
  level_1: { timeout: -1, targets: 10, combined: 0, next_target_after: -1, distractor_prob: 0, simultaneus_prob: 0, }
  level_2: { targets: 16, }
  level_3: { timeout: 15, targets: 20, }
  level_4: { timeout: 10, distractor_prob: 0.1, }
  level_5: { timeout: 8, distractor_prob: 0.2, }
  level_6: { timeout: 6, next_target_after: 4, distractor_prob: 0.3, }
  level_7: { timeout: 5.2, next_target_after: 3.75, distractor_prob: 0.5, }
  level_8: { timeout: 4.8, next_target_after: 3.5, }
  level_9: { timeout: 4.2, next_target_after: 3.2, }
  level_10: { timeout: 4.0, targets: 16, combined: 4, next_target_after: 2.9, }
  level_11: { timeout: 3.8, targets: 13, combined: 7, next_target_after: 2.6, }
  level_12: { timeout: 3.5, targets: 10, combined: 10, next_target_after: 2.4, distractor_prob: 0.3, }
  level_13: { timeout: 3.2, next_target_after: 2.25, }
  level_14: { timeout: 2.8, next_target_after: 2.1, simultaneus_prob: 0.5, }
  level_15: { timeout: 2.6, targets: 14, next_target_after: 2.0, }
  level_16: { timeout: 2.4, next_target_after: 1.9, distractor_prob: 0.2, simultaneus_prob: 0.65, }
  level_17: { timeout: 2.2, next_target_after: 1.75, }
  level_18: { timeout: 2, targets: 16, combined: 11, next_target_after: 1.6, simultaneus_prob: 0.75, }
  level_19: { timeout: 1.9, next_target_after: 1.55, }
  level_20: { timeout: 1.8, combined: 14, next_target_after: 1.5, simultaneus_prob: 0.85, }
  level_21: { timeout: 1.7, next_target_after: 1.45, }
  level_22: { timeout: 1.6, next_target_after: 1.4, simultaneus_prob: 0.9, }
  level_23: { timeout: 1.4, targets: 19, next_target_after: 1.20, distractor_prob: 0.1, }
  level_24: { timeout: 1.3, next_target_after: 1.10, simultaneus_prob: 0.7, }
  level_25: { timeout: 1.2, targets: 20, combined: 16, next_target_after: 1.05, distractor_prob: 0.05, }
  level_26: { timeout: 1.05, next_target_after: 0.95, simultaneus_prob: 0.5, }
  level_27: { timeout: 0.90, targets: 21, combined: 18, next_target_after: 0.70, }
  level_28: { timeout: 0.85, targets: 21, combined: 19, next_target_after: 0.65, }

export default CHESTNUTS_LEVELS = transformBoxingLevels(getLevelConfigArray(CHESTNUTS_LEVEL_DESC, DEFAULT_CONFIG, 'chestnuts'))
